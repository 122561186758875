import React from 'react';
import {
  LegacyCard,
  LegacyStack,
  TextField,
} from '@shopify/polaris';
import { assertString } from '../../../../helper_functions/utils';

export const ProductOrganization = ({
  horseVariant
}: {
  horseVariant: {
    product_type: string,
    vendor: string,
  }
}): JSX.Element => <LegacyCard sectioned>
  <h2 className='Polaris-Heading mb20-important'>Product organization</h2>
  <LegacyStack distribution="fillEvenly">
    <TextField
      disabled
      autoComplete='off'
      label="Type"
      type="text"
      value={assertString(horseVariant.product_type)}
    />
    <TextField
      disabled
      autoComplete='off'
      label="Vendor"
      type="text"
      value={assertString(horseVariant.vendor)}
    />
  </LegacyStack>
</LegacyCard>;
