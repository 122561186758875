/* eslint-disable react/jsx-props-no-spreading */
import React, { useCallback } from "react";
import { useNavigate } from "react-router-dom";

export default function AppBridgeLink({ url, children, external, ...rest }: {
  readonly url: string;
  readonly children: React.ReactNode;
  readonly external?: boolean
}): React.ReactElement {
  const navigate = useNavigate();
  const handleClick = useCallback(() => {
    navigate(url);
  }, [navigate, url]);

  const IS_EXTERNAL_LINK_REGEX = /^(?:[a-z][a-z\d+.-]*:|\/\/)/;

  if (external || IS_EXTERNAL_LINK_REGEX.test(url)) {
    return (
      <a {...rest} href={url} rel="noopener noreferrer" target="_blank">
        {children}
      </a>
    );
  }

  return (
    <a {...rest} onClick={handleClick}>
      {children}
    </a>
  );
};
