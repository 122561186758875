import React, { useEffect, useMemo, useState } from 'react';
import {
  Page, TextField, FormLayout, LegacyCard,
  Badge,
} from '@shopify/polaris';
import { LinkedLocations } from './common/LinkedLocations';
import { Footer } from '../common/Footer';
import { HorseLocationShowPageSkeleton } from '../common/skeletons';
import { getHorseLocation } from '../../api_utils/requests';
import type { HorseLocation, LinkedLocation } from '../../api_utils/types';
import { HORSE_LOCATION_HELP_PAGE_URL } from '../../constants';
import { getIdFromPath } from '../../helper_functions/utils';

function HorseLocationShow(): React.JSX.Element {
  const horseLocationId = useMemo(() => getIdFromPath(), []);
  const [pageLoading, setPageLoading] = useState(true);
  const [imageUrl, setImageUrl] = useState('');
  const [horseLocation, setHorseLocation] = useState<HorseLocation>();
  const [linkedLocations, setLinkedLocations] = useState<LinkedLocation[]>([]);
  const subtitle = [horseLocation?.city, horseLocation?.province].filter((value) => value).join(', ');

  useEffect(() => {
    getHorseLocation(horseLocationId).then(({ imageUrl, horseLocation, linkedLocations }) => {
      setImageUrl(imageUrl);
      setHorseLocation(horseLocation);
      setLinkedLocations(linkedLocations);

      setPageLoading(false);
    });
  }, [horseLocationId]);

  const pageMarkup = horseLocation && (
      <Page
        backAction={{
          url: '/horse_locations'
        }}
        subtitle={subtitle}
        title={horseLocation.name}
        titleMetadata={horseLocation.discarded_at ? <Badge status="warning">Archived</Badge> : undefined}
      >
        <LegacyCard>
          <LegacyCard.Section>
            <img
              alt={horseLocation.name}
              id="horseLocationImage"
              src={imageUrl}
              style={{ objectFit: 'contain', margin: '0 auto', display: 'flex' }}
            />
          </LegacyCard.Section>
        </LegacyCard>
        <LegacyCard sectioned>
          <FormLayout>
            <TextField
              disabled
              label='Name'
              type="text"
              value={horseLocation.name}
            />
          </FormLayout>
        </LegacyCard>
        <LinkedLocations linkedLocations={linkedLocations} />
        <Footer pageTitle="locations" url={HORSE_LOCATION_HELP_PAGE_URL} />
      </Page>
  );

  return pageLoading ? <HorseLocationShowPageSkeleton /> : pageMarkup;
}

export default HorseLocationShow;
