import React from 'react';
import placeholderImg from 'images/placeholder.png';
import {
  LegacyCard,
} from '@shopify/polaris';

export const ProfilePicture = function({ horseVariant }: {
  horseVariant: {
    variant_title: string,
    image_src: string,
  },
}): JSX.Element {
  let source = placeholderImg;
  if (horseVariant.image_src) {
    source = `${horseVariant.image_src}&height=300`;
  }
  return <LegacyCard>
      <div style={{
        display: 'flex',
        justifyContent: 'center',
        backgroundColor: 'white',
        borderRadius: 'var(--p-border-radius-wide, 3px)',
        boxShadow: 'var(--p-card-shadow, 0 0 0 1px rgba(63, 63, 68, 0.05), 0 1px 3px 0 rgba(63, 63, 68, 0.15))',
        outline: '0.1rem solid transparent',
        margin: '0 auto',
        maxHeight: '33rem',
        overflow: 'hidden',
      }}>
        <img
          alt={horseVariant.variant_title}
          style={{ objectFit: 'contain' }}
          src={source}
          height={ 330 }
        />
      </div>
    </LegacyCard>;
};
