import React from "react";
import { Text, InlineStack, Box, Button, Badge, BlockStack, useBreakpoints } from "@shopify/polaris";
import { CircleInformationMajor } from "@shopify/polaris-icons";

function SettingToggle({
  enabled,
  handleToggle,
  title,
  helpLink,
  description,
}: {
  readonly enabled: boolean;
  readonly handleToggle: () => void;
  readonly title: string;
  readonly helpLink?: string;
  readonly description: string;
}): React.ReactElement {
  const contentStatus = enabled ? "Turn off" : "Turn on";

  const toggleId = "setting-toggle-uuid";
  const descriptionId = "setting-toggle-description-uuid";

  const { mdDown } = useBreakpoints();

  const badgeStatus = enabled ? "success" : undefined;

  const badgeContent = enabled ? "On" : "Off";

  const settingStatusMarkup = (
    <Badge tone={badgeStatus} toneAndProgressLabelOverride={`Setting is ${badgeContent}`}>
      {badgeContent}
    </Badge>
  );

  const helpLinkMarkup = helpLink ? (
    <Button accessibilityLabel="Learn more" external icon={CircleInformationMajor} url={helpLink} variant="plain" />
  ) : null;

  const settingTitle = title ? (
    <InlineStack gap="200" wrap={false}>
      <InlineStack align="start" blockAlign="baseline" gap="200">
        <label htmlFor={toggleId}>
          <Text as="h6" variant="headingMd">
            {title}
          </Text>
        </label>
        <InlineStack align="center" blockAlign="center" gap="200">
          {settingStatusMarkup}
          {helpLinkMarkup}
        </InlineStack>
      </InlineStack>
    </InlineStack>
  ) : null;

  const actionMarkup = (
    <Button ariaChecked={enabled ? "true" : "false"} id={toggleId} onClick={handleToggle} role="switch" size="slim">
      {contentStatus}
    </Button>
  );

  const headerMarkup = (
    <Box width="100%">
      <InlineStack align="space-between" blockAlign="start" gap="1200" wrap={false}>
        {settingTitle}
        {!mdDown ? (
          <Box minWidth="fit-content">
            <InlineStack align="end">{actionMarkup}</InlineStack>
          </Box>
        ) : null}
      </InlineStack>
    </Box>
  );

  const descriptionMarkup = (
    <BlockStack gap="400">
      <Text as="p" id={descriptionId} tone="subdued" variant="bodyMd">
        {description}
      </Text>
      {mdDown ? (
        <Box width="100%">
          <InlineStack align="start">{actionMarkup}</InlineStack>
        </Box>
      ) : null}
    </BlockStack>
  );

  return (
    <Box width="100%">
      <BlockStack gap={{ xs: "200", sm: "400" }}>
        {headerMarkup}
        {descriptionMarkup}
      </BlockStack>
    </Box>
  );
}

export default SettingToggle;
