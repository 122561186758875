import React from 'react';
import {
  LegacyCard,
  LegacyStack,
  RadioButton,
  List,
} from '@shopify/polaris';

export const InventorySyncOptions = ({ userSyncOption, handleSyncOptionChange, shopifyShops }: {
  userSyncOption: string,
  handleSyncOptionChange: (newValue: boolean, id: string) => void,
  shopifyShops: { shopify_domain: string }[],
}): React.JSX.Element => {
  return (<LegacyCard>
    <LegacyCard.Header title="Select sync mode"/>
    <LegacyCard.Section>
      Variants are always linked together based on their SKU and barcode.
      To unlink variants, simply change their SKU or barcode in your Shopify store.
    </LegacyCard.Section>
    <LegacyCard.Section>
      <LegacyStack vertical>
        <RadioButton
          label="Basic Sync"
          helpText={
            <List type="bullet">
              <List.Item>
                Shopify is the master of all inventory quantities. Horse will match Shopify's numbers.
              </List.Item>
              <List.Item>
                Only your purchase orders, transfer orders and Stocktakes will be synced to Shopify.
              </List.Item>
            </List>
          }
          checked={userSyncOption === 'hybrid_sync'}
          id="hybrid_sync"
          disabled={shopifyShops.length > 1}
          onChange={handleSyncOptionChange}
        />
        <RadioButton
          label="Full Sync"
          helpText={
            <List type="bullet">
              <List.Item>
                Horse is the master of all inventory quantities. Shopify will match Horse's numbers.
              </List.Item>
              <List.Item>Locations from your stores will be linked together based on their name.</List.Item>
              <List.Item>Inventory changes from any store will be synced to all other stores.</List.Item>
            </List>
          }
          id="full_sync"
          checked={userSyncOption === 'full_sync'}
          onChange={handleSyncOptionChange}
        />
      </LegacyStack>
    </LegacyCard.Section>
  </LegacyCard>
  );
};
