// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import './horse/styles';
import './initializers/init';

import React from 'react'
import ApplicationLayout from './components/layouts/application_layout'
import './horse/maintain_token';
import { createRoot } from "react-dom/client";

document.addEventListener("DOMContentLoaded", () => {
  const root = createRoot(document.getElementById("container"));
  root.render(<ApplicationLayout/>);
});
