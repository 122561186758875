import React from 'react';
import { Link } from 'react-router-dom';

export function LinkText({
  link,
  children,
}: {
  readonly link: string,
  readonly children: React.ReactNode,
}) {
  if (link) {
    return <Link
      className="Polaris-Link Polaris-Link--removeUnderline"
      tabIndex={-1}
      to={link}
    >
      {children}
    </Link>
  } else {
    return children;
  }
}
