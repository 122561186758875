import React from "react";
import { LegacyCard, LegacyStack, RadioButton, List } from "@shopify/polaris";

export function SyncbackOptions({
  syncbackSetting,
  handleSyncOptionChange,
}: {
  readonly syncbackSetting: string;
  readonly handleSyncOptionChange: (newValue: boolean, id: string) => void;
}): React.JSX.Element {
  return (
    <LegacyCard>
      <LegacyCard.Header title="Select sync-back mode" />
      <LegacyCard.Section>What should Horse sync back to Shopify?</LegacyCard.Section>
      <LegacyCard.Section>
        <LegacyStack vertical>
          <RadioButton
            checked={syncbackSetting === "disable_syncback"}
            helpText={
              <List type="bullet">
                <List.Item>
                  Horse will sync all values from Shopify, include variant price, cost, weight, etc.
                </List.Item>
                <List.Item>Horse will always prompt before syncing anything back to Shopify.</List.Item>
              </List>
            }
            id="disable_syncback"
            label="Always ask"
            onChange={handleSyncOptionChange}
          />
          <RadioButton
            checked={syncbackSetting === "only_cost"}
            helpText={
              <List type="bullet">
                <List.Item>
                  Horse will sync all values from Shopify, include variant price, cost, weight, etc.
                </List.Item>
                <List.Item>
                  When you edit the cost of a variant in Horse, it will automatically be updated in Shopify.
                </List.Item>
              </List>
            }
            id="only_cost"
            label="Sync cost automatically"
            onChange={handleSyncOptionChange}
          />
        </LegacyStack>
      </LegacyCard.Section>
    </LegacyCard>
  );
}
