import React, { useState, useEffect, useCallback } from "react";
import { Banner, ProgressBar } from "@shopify/polaris";
import { getReadBanner, createReadBanner, useBanners } from "../../../api_utils/requests";

export default function ImportProgressBanner(): React.ReactElement {
  const [alreadySeen, setAlreadySeen] = useState<boolean>(true);
  const { data: banner } = useBanners();

  const fetchBanner = async (): Promise<void> => {
    await getReadBanner("store_importing").catch((e: unknown) => {
      if (e instanceof Error && e.message.includes("404")) {
        setAlreadySeen(false);
      }
    });
  };

  useEffect(() => {
    void fetchBanner();
  });

  const dismissBanner = useCallback(async (): Promise<void> => {
    setAlreadySeen(true);
    await createReadBanner("store_importing");
  }, [setAlreadySeen]);

  return !alreadySeen && banner?.info ? (
    <>
      <Banner onDismiss={dismissBanner} title={banner.info} tone="info">
        <p>Full functionality will be ready when it's done.</p>
        <p>This can take some time if your store has a lot of products or sales.</p>
        {banner.progress ? <ProgressBar progress={banner.progress} /> : null}
      </Banner>
      <br />
    </>
  ) : null;
}
