import React, { useState, useCallback } from "react";
import { Button, ChoiceList, Popover, LegacyStack, Tag, Text } from "@shopify/polaris";
import type { Choice } from "../../../api_utils/types";

function PopoverWithCheckList({
  defaultTitle,
  onChange,
  onValueRemoved,
  choices,
  selected,
  allowMultiple = true,
}: {
  readonly defaultTitle: string;
  readonly onChange: (newSelectedValues: string[]) => void;
  readonly onValueRemoved: (value: string) => void;
  readonly choices: Choice[];
  readonly selected: string[];
  readonly allowMultiple?: boolean;
}): React.ReactElement {
  const [popoverActive, setPopoverActive] = useState(false);

  const togglePopoverActive = useCallback(() => {
    setPopoverActive((active) => !active);
  }, []);

  const activator = (
    <Button disclosure onClick={togglePopoverActive} size="large" variant="secondary">
      {defaultTitle}
    </Button>
  );

  return (
    <LegacyStack distribution="center" vertical>
      <LegacyStack.Item>
        <Popover
          activator={activator}
          active={popoverActive}
          ariaHaspopup={false}
          onClose={togglePopoverActive}
          preferredAlignment="left"
          sectioned
        >
          <ChoiceList
            allowMultiple={allowMultiple}
            choices={choices}
            onChange={onChange}
            selected={selected}
            title=""
          />
        </Popover>
      </LegacyStack.Item>
      {selected.length > 0 && (
        <LegacyStack.Item>
          <LegacyStack spacing="extraTight">
            {selected.map((selectedValue) => {
              return (
                <LegacyStack.Item key={selectedValue}>
                  <Tag
                    key={selectedValue}
                    onRemove={() => {
                      onValueRemoved(selectedValue);
                    }}
                  >
                    <Text as="span" variant="bodyMd">
                      {choices.find((option) => option.value === selectedValue)?.label}
                    </Text>
                  </Tag>
                </LegacyStack.Item>
              );
            })}
          </LegacyStack>
        </LegacyStack.Item>
      )}
    </LegacyStack>
  );
}

export default PopoverWithCheckList;
