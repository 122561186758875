import React from "react";
import { LegacyCard, LegacyStack, Text } from "@shopify/polaris";

export function ProductAvailability({
  channels,
}: {
  readonly channels: {
    friendly_name: string;
    shopify_domain: string;
    available: boolean;
  }[];
}): JSX.Element {
  return (
    <LegacyCard sectioned>
      <LegacyStack distribution="fillEvenly">
        <LegacyStack.Item>
          <h2 className="Polaris-Heading">Availability</h2>
        </LegacyStack.Item>
      </LegacyStack>
      {channels.map((item) => {
        const { friendly_name, available, shopify_domain } = item;
        return (
          <LegacyCard.Section key={`${friendly_name}:${available}`}>
            <div>
              <LegacyStack alignment="center" distribution="fillEvenly">
                <LegacyStack.Item>
                  <h4>
                    <Text as="strong">{friendly_name}</Text>
                  </h4>
                  <Text as="span" tone="subdued">
                    {shopify_domain}
                  </Text>
                </LegacyStack.Item>
                <LegacyStack.Item>
                  <span className={`dot ${available ? "availableDot" : "unavailableDot"}`}>&nbsp;</span>
                </LegacyStack.Item>
              </LegacyStack>
            </div>
          </LegacyCard.Section>
        );
      })}
    </LegacyCard>
  );
}
