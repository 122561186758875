import React from 'react';
import {
  Card,
  Layout,
  SkeletonBodyText,
  SkeletonPage,
} from '@shopify/polaris';

export const SettingsPageSkeleton = (): React.JSX.Element => (
  <SkeletonPage>
    <Layout>
      <Layout.Section variant="oneThird">
        <SkeletonBodyText lines={2} />
      </Layout.Section>
      <Layout.Section>
        <Card>
          <SkeletonBodyText lines={5} />
        </Card>
      </Layout.Section>
    </Layout>
  </SkeletonPage>
);