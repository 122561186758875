import React, { useEffect } from 'react';
import { Routes as ReactRouterRoutes, Route, useNavigate } from "react-router-dom";
import QueryString from 'qs';
import HomeIndex from '../../home/Index';
import HorseVariantShow from '../../horse_variants/show/Show';
import HorseVariantsIndex from '../../horse_variants/index/Index';
import HorseLocationShow from '../../horse_locations/Show';
import HorseLocationsIndex from '../../horse_locations/Index';
import PurchaseOrderNew from '../../purchase_orders/New';
import PurchaseOrderShow from '../../purchase_orders/Show';
import PurchaseOrdersIndex from '../../purchase_orders/index/Index';
import TransferOrderNew from '../../transfer_orders/New';
import TransferOrderShow from '../../transfer_orders/Show';
import TransferOrdersIndex from '../../transfer_orders/index/Index';
import InventoryHistoriesIndex from '../../inventory_level_histories/Index';
import SalesTrendsIndex from '../../sales_trends/Index';
import SupplierNew from '../../suppliers/New';
import SupplierShow from '../../suppliers/Show';
import SuppliersIndex from '../../suppliers/Index';
import BundleNew from '../../bundles/New';
import BundleShow from '../../bundles/Show';
import BundlesIndex from '../../bundles/Index';
import Settings from '../../settings/Show';

function Routes(): React.ReactElement {
  const navigate = useNavigate();

  // ?return_to=/foobar functionality
  const queryParams = QueryString.parse(window.location.search, { ignoreQueryPrefix: true });
  const return_to = queryParams.return_to as string;
  useEffect(() => {
    if (return_to) {
      navigate(return_to);
    }
  }, [navigate, return_to]);

  return (
    <ReactRouterRoutes>
      <Route element={<HomeIndex />} path="/" />
      <Route element={<HomeIndex />} path="/home" />
      <Route element={<HorseVariantShow />} path="/horse_variants/:id" />
      <Route element={<HorseVariantsIndex />} path="/horse_variants" />
      <Route element={<HorseLocationShow />} path="/horse_locations/:id" />
      <Route element={<HorseLocationsIndex />} path="/horse_locations" />
      <Route element={<PurchaseOrderNew />} path="/purchase_orders/new" />
      <Route element={<PurchaseOrderShow />} path="/purchase_orders/:id" />
      <Route element={<PurchaseOrdersIndex />} path="/purchase_orders" />
      <Route element={<TransferOrderNew />} path="/transfer_orders/new" />
      <Route element={<TransferOrderShow />} path="/transfer_orders/:id" />
      <Route element={<TransferOrdersIndex />} path="/transfer_orders" />
      <Route element={<InventoryHistoriesIndex />} path="/inventory_level_histories" />
      <Route element={<SalesTrendsIndex />} path="/sales_trends" />
      <Route element={<SupplierNew />} path="/suppliers/new" />
      <Route element={<SupplierShow />} path="/suppliers/:id" />
      <Route element={<SuppliersIndex />} path="/suppliers" />
      <Route element={<BundleNew />} path="/bundles/new" />
      <Route element={<BundleShow />} path="/bundles/:id" />
      <Route element={<BundlesIndex />} path="/bundles" />
      <Route element={<Settings />} path="/settings" />
    </ReactRouterRoutes>
  );
}

export default Routes;
