import React from 'react';
import weightedAverageFormula from 'images/weighted-average-formula.svg';
import {
  LegacyCard,
  LegacyStack,
  RadioButton,
  List,
} from '@shopify/polaris';

export const CostSyncOptions = ({ costsyncSetting, handleSyncOptionChange }: {
  costsyncSetting: string,
  handleSyncOptionChange: (newValue: boolean, id: string) => void,
}): React.JSX.Element => {
  return (<LegacyCard>
    <LegacyCard.Header title={"Select cost sync mode"}/>
    <LegacyCard.Section>
      How should variant cost be synced to Shopify?
    </LegacyCard.Section>
    <LegacyCard.Section>
      <LegacyStack vertical>
        <RadioButton
          label="Latest"
          helpText={
            <List type="bullet">
              <List.Item>
                Horse will sync the latest variant cost from a purchase order to Shopify.
              </List.Item>
            </List>
          }
          checked={costsyncSetting === 'latest'}
          id="latest"
          onChange={handleSyncOptionChange}
        />
        <RadioButton
          label="Weighted average"
          helpText={
            <>
              <List type="bullet">
                <List.Item>
                  Horse will calculate a weighted average cost of your current inventory and new inventory from a purchase order.
                </List.Item>
              </List>
              <img style={{maxWidth: '100%', marginTop: '8px', shapeRendering: 'crispEdges'}} src= { weightedAverageFormula }/>
            </>
          }
          id="weighted_average"
          checked={costsyncSetting === 'weighted_average'}
          onChange={handleSyncOptionChange}
        />
      </LegacyStack>
    </LegacyCard.Section>
  </LegacyCard>
  );
};
