import React from 'react';
import { Badge, LegacyStack, Text } from '@shopify/polaris';
import { TruncatedText } from './TruncatedText';
import { LinkText } from './LinkText';

const getProductTypeAndVendor = (product_type, vendor) => {
  if (product_type && vendor) return `${product_type} by ${vendor}`;
  if (!product_type) return `by ${vendor}`;
  if (!vendor) return product_type;
  return '';
};

function ProductCaption({
  variant,
  showAvailable,
  disableClick,
}: {
  readonly disableClick: boolean,
  readonly variant: {
    id: number,
    currently_available?: number,
    product_title: string,
    product_type: string,
    sku: string,
    tracked?: boolean,
    variant_title: string,
    vendor: string,
  },
  readonly showAvailable: boolean,
}) {
  const productTitle = <TruncatedText maxLength={25} text={variant.product_title}>
    <LinkText link={variant.id && !disableClick ? `/horse_variants/${variant.id}` : undefined}>
      <Text as="strong" truncate>{variant.product_title}</Text>
    </LinkText>
  </TruncatedText>;

  const variantTitle = <TruncatedText maxLength={25} text={variant.variant_title}>
    <LinkText link={variant.id && !disableClick ? `/horse_variants/${variant.id}` : undefined}>
      <Text as="p" truncate>{variant.variant_title}</Text>
    </LinkText>
  </TruncatedText>;

  const skuRow = variant.sku ? <TruncatedText maxLength={25} text={variant.sku}>
    <Badge>
      <Text as="p" truncate>
        {variant.sku}
      </Text>
    </Badge>
  </TruncatedText> : null;

const productTypeAndVendor = getProductTypeAndVendor(variant.product_type, variant.vendor);
  const productTypeAndVendorRow = <TruncatedText maxLength={25} text={productTypeAndVendor}>
    <Text as="p" tone="subdued" truncate>{productTypeAndVendor}</Text>
  </TruncatedText>;

  const available = variant.tracked ? (variant.currently_available || "0") : '∞';

  return (
    <LegacyStack alignment="center" wrap={false}>
      <LegacyStack.Item fill>
        <div className='product-caption'>
          <div>{productTitle}</div>
          <div>{variantTitle}</div>
          <div>{skuRow}</div>
          <div>{productTypeAndVendorRow}</div>
        </div>
      </LegacyStack.Item>
      {showAvailable ? <LegacyStack.Item>{available} available</LegacyStack.Item> : null}
    </LegacyStack>
  );
}

export default ProductCaption;
