import React from "react";
import StyledDivElementWithTooltip from "./StyledDivElementWithTooltip";
import type { IHorseVariant } from "../../../api_utils/types";
import { InlineStack } from "@shopify/polaris";

const stock_days = {
  month: 30,
  two_months: 60,
  half_year: 180,
};

export default function StockInfo({sales_trends_horse_variant}: {readonly sales_trends_horse_variant: IHorseVariant}): React.JSX.Element | null {
  const { tracked, currently_available, sales_quantity, available_days } = sales_trends_horse_variant;
  const children: React.JSX.Element[] = [];

  if (available_days === 0) {
    children.push(<StyledDivElementWithTooltip key={children.length} text="Never available" tone="info" />);
  }

  if (sales_quantity === 0 && available_days > 0) {
    children.push(<StyledDivElementWithTooltip key={children.length} text="No sales" tone="attention" />);
    if (currently_available > 0 && available_days > 0) {
      children.push(<StyledDivElementWithTooltip key={children.length} text="Overstock" tone="warning" />);
    }
  }

  if (tracked && currently_available <= 0) {
    children.push(<StyledDivElementWithTooltip key={children.length} text="Out of stock" tone="critical" />);
  }

  let days_until_out_of_stock;

  if (available_days > 0 && currently_available > 0 && sales_quantity > 0) {
    days_until_out_of_stock = currently_available / (sales_quantity / available_days);
    if (days_until_out_of_stock < stock_days.month) {
      children.push(
        <StyledDivElementWithTooltip key={children.length} text="Out of stock quite soon" tone="warning" />,
      );
    } else if (days_until_out_of_stock < stock_days.two_months) {
      children.push(<StyledDivElementWithTooltip key={children.length} text="Out of stock soon" tone="warning" />);
    } else if (days_until_out_of_stock > stock_days.half_year) {
      children.push(<StyledDivElementWithTooltip key={children.length} text="Overstock" tone="warning" />);
    }
  }

  return children ? <InlineStack gap="200">{children}</InlineStack> : null;
};
