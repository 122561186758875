import React from 'react';
import {
  BlockStack,
  Card,
  Layout,
  SkeletonBodyText,
  SkeletonPage,
} from '@shopify/polaris';

export const TransferOrderShowPageSkeleton = (): React.JSX.Element => (
  <SkeletonPage primaryAction>
    <Layout>
      <Layout.Section>
        <BlockStack gap="400">
          <Card>
            <SkeletonBodyText lines={5} />
          </Card>
          <Card>
            <SkeletonBodyText lines={10} />
          </Card>
        </BlockStack>
      </Layout.Section>
    </Layout>
  </SkeletonPage>
);