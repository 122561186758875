import React, { useCallback } from "react";
import { Toast as AppBridgeToast } from "@shopify/app-bridge-react";

export default function Toast({toastMessage, setToastMessage, toastErrorMessage, setToastErrorMessage}: {
  readonly toastMessage: string,
  readonly setToastMessage: (toastMessage: string) => void,
  readonly toastErrorMessage?: string,
  readonly setToastErrorMessage?: (toastErrorMessage: string) => void,
}): React.ReactElement {
  const toastVisible = toastMessage !== null && toastMessage !== "" && toastMessage !== undefined;

  const handleDismissToast = useCallback((): void => {
    setToastMessage(null);
    setToastErrorMessage?.(null);
  }, [setToastMessage, setToastErrorMessage]);

  return toastVisible ? <AppBridgeToast content={toastMessage || toastErrorMessage || ""} error={!!toastErrorMessage} onDismiss={handleDismissToast} /> : null;
}