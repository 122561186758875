import React from 'react';
import { NavigationMenu } from '@shopify/app-bridge-react';

export default function HorseNavigationMenu(): React.ReactElement {
  return (<NavigationMenu
    navigationLinks={[
      {
        label: 'Variants',
        destination: '/horse_variants',
      }, {
        label: 'Locations',
        destination: '/horse_locations',
      }, {
        label: 'Purchase orders',
        destination: '/purchase_orders',
      }, {
        label: 'Transfer orders',
        destination: '/transfer_orders',
      }, {
        label: 'Inventory history',
        destination: '/inventory_level_histories',
      }, {
        label: 'Sales trends',
        destination: '/sales_trends',
      }, {
        label: 'Suppliers',
        destination: '/suppliers',
      }, {
        label: 'Bundles',
        destination: '/bundles',
      }, {
        label: 'Settings',
        destination: '/settings',
      },
    ]}
  />);
}
