import React from "react";
import { Modal } from "@shopify/polaris";
import { useNavigate } from "react-router-dom";
import type { InvalidateQueryFilters } from "@tanstack/react-query";
import { useQueryClient } from "@tanstack/react-query";

function DeleteConfirmationDialog({
  itemId,
  deleteItem,
  gotoPath,
  title,
  content,
  active,
  setActive,
  invalidateQueries,
}: {
  readonly itemId: number;
  readonly deleteItem: (itemId: number) => Promise<void>;
  readonly gotoPath: string;
  readonly title: string;
  readonly content: string;
  readonly active: boolean;
  readonly setActive: (newState: boolean) => void;
  readonly invalidateQueries?: InvalidateQueryFilters;
}): React.JSX.Element {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const handleClose = (): void => {
    setActive(false);
  };

  const handleDelete = async (): Promise<void> => {
    setActive(false);
    await deleteItem(itemId).then(async () => {
      if (invalidateQueries) {
        await queryClient.invalidateQueries(invalidateQueries);
      }
      navigate(gotoPath);
    });
  };

  return (
    <Modal
      onClose={handleClose}
      open={active}
      primaryAction={{
        content: "Delete",
        destructive: true,
        onAction: handleDelete,
      }}
      secondaryActions={[
        {
          content: "Cancel",
          onAction: handleClose,
        },
      ]}
      title={title}
    >
      <Modal.Section>{content}</Modal.Section>
    </Modal>
  );
}

export default DeleteConfirmationDialog;
