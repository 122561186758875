import React, { useState, useCallback } from "react";
import {
  ActionList,
  BlockStack,
  Box,
  Button,
  Card,
  InlineStack,
  Layout,
  Page,
  Popover,
  Text,
  TextField,
} from "@shopify/polaris";
import { HorizontalDotsMinor, EditMinor } from "@shopify/polaris-icons";
import { ContextualSaveBar, Loading } from "@shopify/app-bridge-react";
import { extractMessageFromError, isEmpty } from "../../helper_functions/utils";
import { createBundle } from "../../api_utils/requests";
import { ErrorBanner } from "../common/ErrorBanner";
import HorseVariant from "../common/HorseVariant/HorseVariant";
import { SelectVariantDialog } from "../common/SelectVariantDialog";
import { Footer } from "../common/Footer";
import type { Bundle, IHorseVariant } from "../../api_utils/types";
import Toast from "../common/Toast";
import { useNavigate } from "react-router-dom";

const bundlesHelpPageUrl = "https://horse-inventory.notion.site/Bundles-88ac584381a445de890d4f68870d5920";

function CreateBundle() {
  const navigate = useNavigate();

  const [newBundle, setNewBundle] = useState<Partial<Bundle>>({});
  const [horseVariant, setHorseVariant] = useState<IHorseVariant>();
  const [loading, setLoading] = useState(false);
  const [isDirty, setIsDirty] = useState(false);

  const [popoverActive, setPopoverActive] = useState(false);

  const [selectVariantModalActive, setSelectVariantModalActive] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const togglePopoverActive = useCallback(() => {
    setPopoverActive((popoverActive) => !popoverActive);
  }, []);

  const [toastMessage, setToastMessage] = useState<string>(null);

  const handleChange = useCallback(
    (value, id) => {
      setNewBundle({ ...newBundle, [id]: value });
      const dirty = !isEmpty(newBundle.name) && !isEmpty(horseVariant);
      setIsDirty(dirty);
    },
    [newBundle],
  );

  const handleHorseVariantUpdate = (horseVariant: IHorseVariant): void => {
    setHorseVariant(horseVariant);
    setNewBundle({ ...newBundle, horse_variant_id: horseVariant.id });
    const dirty = !isEmpty(newBundle.name) && !isEmpty(horseVariant);
    setIsDirty(dirty);
  };

  const handleSaveBundle = () => {
    setLoading(true);
    createBundle({ bundle: newBundle })
      .then(({ id }) => {
        setToastMessage("Bundle created");
        setIsDirty(false);
        navigate(`/bundles/${id}`);
      })
      .catch((err: unknown) => {
        Rollbar.error(err, newBundle);
        const newErrorMessage = extractMessageFromError(err);
        setErrorMessage(newErrorMessage);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleDiscardAction = () => {
    setNewBundle({ name: "" });
    setHorseVariant(undefined);
    setIsDirty(false);
  };

  const activator = (
    <Button accessibilityLabel="Menu" disclosure icon={HorizontalDotsMinor} onClick={togglePopoverActive} />
  );

  return (
    <>
      {loading ? <Loading /> : null}
      <ContextualSaveBar
        discardAction={{ onAction: handleDiscardAction }}
        saveAction={{ onAction: handleSaveBundle }}
        visible={isDirty}
      />
      <Toast setToastMessage={setToastMessage} toastMessage={toastMessage} />

      <Page
        backAction={{
          content: "Bundles",
          url: "/bundles",
        }}
        narrowWidth
        title="Create bundle"
      >
        <SelectVariantDialog
          active={selectVariantModalActive}
          handleDiscard={() => {}}
          selected={horseVariant}
          setActive={setSelectVariantModalActive}
          updateSelection={handleHorseVariantUpdate}
        />
        <Layout>
          <Layout.Section>
            <ErrorBanner errorMessage={errorMessage} setErrorMessage={setErrorMessage} />
          </Layout.Section>
          <Layout.Section>
            <BlockStack gap="400">
              <Card>
                <TextField
                  autoComplete="off"
                  helpText="Choose a name for your bundle."
                  id="name"
                  label="Bundle name"
                  onChange={handleChange}
                  value={newBundle.name}
                />
              </Card>
              <Card>
                <Text as="h6" variant="headingSm">
                  Select parent variant
                </Text>
                <Box paddingBlock="200">
                  <Text as="h6" variant="bodyXs">
                    The parent is the variant that the customer buys
                  </Text>
                </Box>
                <BlockStack gap="200">
                  {isEmpty(horseVariant) ? (
                    <InlineStack align="center">
                      <Button
                        onClick={() => {
                          setSelectVariantModalActive(true);
                        }}
                        variant="primary"
                      >
                        Select variant
                      </Button>
                    </InlineStack>
                  ) : (
                    <InlineStack align="space-between" blockAlign="center">
                      <HorseVariant variant={horseVariant} />
                      <Popover
                        activator={activator}
                        active={popoverActive}
                        autofocusTarget="first-node"
                        onClose={togglePopoverActive}
                      >
                        <ActionList
                          actionRole="menuitem"
                          items={[
                            {
                              content: "Edit variant",
                              icon: EditMinor,
                              onAction: () => {
                                setSelectVariantModalActive(true);
                              },
                            },
                          ]}
                        />
                      </Popover>
                    </InlineStack>
                  )}
                </BlockStack>
              </Card>
            </BlockStack>
          </Layout.Section>
        </Layout>
        <Footer pageTitle="bundles" url={bundlesHelpPageUrl} />
      </Page>
    </>
  );
}

export default CreateBundle;
