import React from 'react';
import { Redirect } from "@shopify/app-bridge/actions";
import { Link } from "@shopify/polaris";

export function AdjustedByLink({redirect, url, title}: {readonly redirect: Redirect.Redirect, readonly url?: string, readonly title: string}): React.ReactElement | string {
  if (url?.startsWith('https://')) {
    return <Link
      onClick={(): void => {
        const parsedUrl = new URL(url);
        redirect.dispatch(Redirect.Action.ADMIN_PATH, {
          path: parsedUrl.pathname,
          newContext: true,
        });
      }}
      target="_blank"
      url={url}>
        {title}
    </Link>;
  } else if(url) {
    return (<Link url={url}>
      {title}
    </Link>);
  } else {
    return title;
  }
}
