import React, { useState, useEffect, useCallback } from "react";
import {
  Page,
  Button,
  LegacyCard,
  Tabs,
  Autocomplete,
  Icon,
  Pagination,
  LegacyStack,
  Link,
  Select,
  IndexTable,
  FormLayout,
} from "@shopify/polaris";
import { SearchMinor } from "@shopify/polaris-icons";
import qs from "query-string";
import { formatMoney, debounce } from "../../helper_functions/utils";
import { getHorseLocations, getHorseLocationOptions, downloadFile, useUser } from "../../api_utils/requests";
import { Footer } from "../common/Footer";
import type { HorseLocation } from "../../api_utils/types";
import type { NonEmptyArray } from "@shopify/polaris/build/ts/src/types";
import type { IndexTableHeading } from "@shopify/polaris/build/ts/src/components/IndexTable";

const UNDISCARDED = "undiscarded";
const ALL = "all";
const tabs = [
  {
    // Sends archived=false param to backend
    id: "horse-locations-unarchived-tab",
    content: "Unarchived",
    accessibilityLabel: "Unarchived locations",
    panelID: "horse-locations-unarchived-tab-content",
  },
  {
    // Doesn't send archived param to backend
    id: "horse-locations-all-tab",
    content: "All",
    accessibilityLabel: "All locations (includes unarchived and archived locations)",
    panelID: "horse-locations-all-tab-content",
  },
];
const columnNames = [
  { title: "Name" },
  { title: "Location" },
  { title: "Cost value" },
  { title: "Retail value" },
] satisfies NonEmptyArray<IndexTableHeading>;
const sortOptions = [
  { label: "Name A-Z", value: "name-asc" },
  { label: "Name Z-A", value: "name-desc" },
  { label: "Location A-Z", value: "friendly_location-asc" },
  { label: "Location Z-A", value: "friendly_location-desc" },
  { label: "Cost value small to big", value: "total_cost_value-asc" },
  { label: "Cost value big to small", value: "total_cost_value-desc" },
  { label: "Retail value small to big", value: "total_retail_value-asc" },
  { label: "Retail value big to small", value: "total_retail_value-desc" },
];
const horseLocationHelpPageUrl =
  "https://horse-inventory.notion.site/Locations-directory-56c45bb19d774d2783f558017691dfcf";

function HorseLocations() {
  const urlSearch = qs.parse(window.location.search);
  const initSortedCol = urlSearch["by_sort[column]"] || "name";
  const initSortedColDir = urlSearch["by_sort[direction]"] || "asc";
  const [sortValue, setSortValue] = useState(`${initSortedCol}-${initSortedColDir}`);
  const [horseLocations, setHorseLocations] = useState<HorseLocation[]>([]);
  const [hasNext, setHasNext] = useState(false);
  const [hasPrev, setHasPrev] = useState(false);
  const [totals, setTotals] = useState({
    cost_value: 0,
    retail_value: 0,
  });
  const [selectedTabIdx, setSelectedTabIdx] = useState(urlSearch.collection === ALL ? 1 : 0); // tab 0 is default
  const [search, setSearch] = useState(urlSearch.search || "");
  const [pageNum, setPageNum] = useState(Number(urlSearch.page) || 1);
  const [filteredDropdownOptions, setFilteredDropdownOptions] = useState([]);

  const [isLoading, setIsLoading] = useState(true);

  const {
    data: {
      user: { currency: userCurrency },
    },
  } = useUser();

  useEffect(() => {
    const collection = selectedTabIdx === 0 ? UNDISCARDED : ALL;
    const [column_name, direction] = sortValue.split("-");
    setIsLoading(true);
    getHorseLocations({
      search,
      collection,
      "by_sort[column]": column_name,
      // There seems to be a bug in Polaris that sends a number for direction on initial sort
      "by_sort[direction]": typeof direction === "number" ? "asc" : direction,
      page: pageNum,
      totals: true,
    }).then(({ rows, totals, hasNext, hasPrev }) => {
      setIsLoading(false);
      setHorseLocations(rows);
      setTotals(totals);
      setHasNext(hasNext);
      setHasPrev(hasPrev);
    });
  }, [search, selectedTabIdx, pageNum, sortValue]);

  useEffect(() => {
    const collection = selectedTabIdx === 0 ? UNDISCARDED : ALL;
    getHorseLocationOptions({ collection }).then(setFilteredDropdownOptions);
  }, [selectedTabIdx]);

  const handleDropdownOptionSelect = useCallback(
    debounce((selected) => {
      setSearch(selected[0]);
    }, 250),
    [],
  );

  const setPageNumAndScroll = useCallback((newPageNum: number) => {
    setPageNum(newPageNum);
    document.getElementsByClassName("Polaris-IndexTable__Table")[0]?.scrollIntoView();
  }, []);
  const nextPage = useCallback(() => {
    if (hasNext) {
      setPageNumAndScroll(pageNum + 1);
    }
  }, [hasNext, pageNum, setPageNumAndScroll]);
  const prevPage = useCallback(() => {
    if (hasPrev) {
      setPageNumAndScroll(pageNum - 1);
    }
  }, [hasPrev, pageNum, setPageNumAndScroll]);

  const autocompleteTxtFieldMarkup = (
    <Autocomplete.TextField
      id="horse-locations-search-autocomplete-textfield"
      onChange={setSearch}
      placeholder="Search"
      prefix={<Icon color="base" source={SearchMinor} />}
      value={search}
    />
  );

  const column_color = (discarded) => {
    if (discarded) {
      return "rgba(142,147,152)";
    } else {
      return "";
    }
  };

  const indexTableRow = (horseLocation) => {
    return (
      <>
        <IndexTable.Cell>
          <Link plain url={`/horse_locations/${horseLocation.id}`}>
            {horseLocation.name}
          </Link>
        </IndexTable.Cell>
        <IndexTable.Cell>
          <span style={{ color: column_color(horseLocation.discarded) }}>{horseLocation.friendly_location}</span>
        </IndexTable.Cell>
        <IndexTable.Cell>
          <span style={{ color: column_color(horseLocation.discarded) }}>
            {formatMoney(horseLocation.total_cost_value, userCurrency, 2)}
          </span>
        </IndexTable.Cell>
        <IndexTable.Cell>
          <span style={{ color: column_color(horseLocation.discarded) }}>
            {formatMoney(horseLocation.total_retail_value, userCurrency, 2)}
          </span>
        </IndexTable.Cell>
      </>
    );
  };

  return (
    <Page
      primaryAction={
        <Button
          onClick={() => {
            const filename = `Horse Locations.csv`;
            const collection = selectedTabIdx === 0 ? UNDISCARDED : ALL;
            const queryParams = {
              search,
              collection,
            };
            downloadFile(`/horse_locations.csv`, filename, queryParams);
          }}
          removeUnderline
          size="medium"
          variant="monochromePlain"
        >
          Export CSV
        </Button>
      }
      title="Locations"
    >
      <LegacyCard>
        <Tabs onSelect={setSelectedTabIdx} selected={selectedTabIdx} tabs={tabs}>
          <LegacyCard.Section>
            <FormLayout>
              <FormLayout.Group>
                <LegacyStack>
                  <LegacyStack.Item fill>
                    <Autocomplete
                      id="horse-locations-search-autocomplete"
                      onSelect={handleDropdownOptionSelect}
                      options={filteredDropdownOptions}
                      selected={[]}
                      textField={autocompleteTxtFieldMarkup}
                    />
                  </LegacyStack.Item>
                  <LegacyStack.Item>
                    <Select
                      label="Sort by"
                      labelInline
                      onChange={setSortValue}
                      options={sortOptions}
                      value={sortValue}
                    />
                  </LegacyStack.Item>
                </LegacyStack>
              </FormLayout.Group>
            </FormLayout>
          </LegacyCard.Section>
          <IndexTable headings={columnNames} itemCount={horseLocations.length} loading={isLoading} selectable={false}>
            <IndexTable.Row id="horseLocationsIndexTotalsRow" position={0} rowType="subheader" tone="subdued">
              <IndexTable.Cell>Totals</IndexTable.Cell>
              <IndexTable.Cell />
              <IndexTable.Cell>{formatMoney(totals.cost_value, userCurrency, 2)}</IndexTable.Cell>
              <IndexTable.Cell>{formatMoney(totals.retail_value, userCurrency, 2)}</IndexTable.Cell>
            </IndexTable.Row>
            {horseLocations.map((horseLocation, index) => (
              <IndexTable.Row id={horseLocation.id.toString()} key={horseLocation.id} position={index + 1}>
                {indexTableRow(horseLocation)}
              </IndexTable.Row>
            ))}
          </IndexTable>
        </Tabs>
        <LegacyCard.Section>
          <LegacyStack alignment="center" distribution="center">
            <Pagination
              hasNext={hasNext}
              hasPrevious={hasPrev}
              nextKeys={[39]}
              onNext={nextPage}
              onPrevious={prevPage}
              previousKeys={[37]}
            />
          </LegacyStack>
        </LegacyCard.Section>
      </LegacyCard>
      <Footer pageTitle="locations" url={horseLocationHelpPageUrl} />
    </Page>
  );
}

export default HorseLocations;
