import React from "react";
import { Card, FormLayout, IndexTable, Text, Box, InlineStack, BlockStack } from "@shopify/polaris";
import { assertNumber, formatMoney, humanDateFormat, softAssertNumber } from "../../../helper_functions/utils";
import PopoverWithCheckList from "../../common/Filters/PopoverWithCheckList";
import HorseVariant from "../../common/HorseVariant/HorseVariant";
import type { HomeRecommendation, Option } from "../../../api_utils/types";
import type { NonEmptyArray } from "@shopify/polaris/build/ts/src/types";
import type { IndexTableHeading } from "@shopify/polaris/build/ts/src/components/IndexTable";
import { useUser } from "../../../api_utils/requests";

function BlocksContent({
  header,
  info_one,
  info_two,
  info_three,
  amount,
  columnNames,
  tableRows,
  url,
  imageSrc,
  horseLocations,
  shopifyChannels,
  buttonUrl,
  handleSelectLocationChange,
  handleSelectShopifyChannelChange,
  horse_location_id,
  sale_shopify_channel_ids,
  position,
  handleLocationSelectRemove,
  handleShopifyChannelSelectRemove,
  showPerDay,
  isLoading,
}: {
  readonly header: string;
  readonly info_one: string;
  readonly info_two: string;
  readonly info_three: string;
  readonly amount: number;
  readonly columnNames: NonEmptyArray<IndexTableHeading>;
  readonly url: string;
  readonly buttonUrl?: string;
  readonly imageSrc: string;
  readonly tableRows: HomeRecommendation[];
  readonly horseLocations: Option[];
  readonly shopifyChannels: Option[];
  readonly handleSelectLocationChange: (value: number[]) => void;
  readonly handleSelectShopifyChannelChange: (value: number[]) => void;
  readonly horse_location_id: number[];
  readonly sale_shopify_channel_ids: number[];
  readonly handleLocationSelectRemove: (value: number) => void;
  readonly handleShopifyChannelSelectRemove: (value: number) => void;
  readonly position: number;
  readonly showPerDay: boolean;
  readonly isLoading: boolean;
}) {
  const {
    data: {
      user: { currency: userCurrency },
    },
  } = useUser();

  let primaryFooterAction;
  if (buttonUrl) {
    primaryFooterAction = {
      content: "Create purchase order",
      url: buttonUrl,
    };
  }

  return (
    <Card
      primaryFooterAction={primaryFooterAction}
      secondaryFooterActions={[
        {
          content: "Explore trend",
          plain: true,
          url,
        },
      ]}
    >
      <Text as="h2" variant="headingSm">
        {header}
      </Text>

      <BlockStack gap="200">
        <Box paddingBlockStart="200">
          <Text as="span" tone="subdued">
            {info_one}
          </Text>
        </Box>

        <Card background="bg-surface-secondary">
          <InlineStack gap="400">
            <img alt="Illustration" src={imageSrc} style={{ maxWidth: "30px", height: "auto" }} />
            <div>
              <div>{info_two}</div>
              <Text as="h2" variant="headingMd">
                {`${formatMoney(amount, userCurrency, 2)}${showPerDay ? " / day" : ""}`}
              </Text>
              <p>{info_three}</p>
            </div>
          </InlineStack>
        </Card>

        <FormLayout>
          <FormLayout.Group condensed>
            <PopoverWithCheckList
              choices={horseLocations}
              defaultTitle="Select locations"
              onChange={(newValues: string[]) => {
                handleSelectLocationChange(newValues.map((v) => assertNumber(v)));
              }}
              onValueRemoved={(value: string) => {
                handleLocationSelectRemove(softAssertNumber(value));
              }}
              selected={horse_location_id.map((id) => id.toString())}
            />
            <PopoverWithCheckList
              choices={shopifyChannels}
              defaultTitle="Select sales channels"
              onChange={(newValues: string[]) => {
                handleSelectShopifyChannelChange(newValues.map((v) => assertNumber(v)));
              }}
              onValueRemoved={(value: string) => {
                handleShopifyChannelSelectRemove(softAssertNumber(value));
              }}
              selected={sale_shopify_channel_ids.map((id) => id.toString())}
            />
          </FormLayout.Group>
        </FormLayout>
      </BlockStack>

      <IndexTable headings={columnNames} itemCount={tableRows.length} loading={isLoading} selectable={false}>
        {tableRows.map((variant, index) => (
          <IndexTable.Row
            id={variant.horse_variant_id.toString()}
            key={`${position}${variant.horse_variant_id}`}
            position={index}
          >
            <IndexTable.Cell>
              <div className="medium_hv">
                <HorseVariant variant={variant} />
              </div>
            </IndexTable.Cell>
            <IndexTable.Cell>{variant.available || humanDateFormat(new Date(variant.oos_date))}</IndexTable.Cell>
            <IndexTable.Cell>{formatMoney(variant.amount, userCurrency, 2)}</IndexTable.Cell>
          </IndexTable.Row>
        ))}
      </IndexTable>
    </Card>
  );
}

export default BlocksContent;
