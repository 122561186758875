import type { DateRangeFilter, DecimalRangeFilter, NumberRangeFilter } from "../../../api_utils/types";
import { humanDateFormat } from "../../../helper_functions/utils";

export function moreThanLessThanLabel(value: DecimalRangeFilter | NumberRangeFilter, label: string, prefix?: string, suffix?: string): string {
  return (
    (value.more_than || value.less_than) &&
    `${label} ${[
      value.more_than ? `more than ${prefix || ""}${value.more_than}${suffix || ""}` : false,
      value.less_than ? `less than ${prefix || ""}${value.less_than}${suffix || ""}` : false,
    ]
      .filter(Boolean)
      .join(" and ")}`
  );
};

export const dateLabel = (value: DateRangeFilter, label: string): string => {
  return `${label} ${[
    value.start ? `from ${humanDateFormat(value.start)}` : false,
    value.end ? `to ${humanDateFormat(value.end)}` : false,
  ].filter(Boolean).join(' ')}`;
};
