import React, { useState, useCallback, useMemo } from "react";
import {
  LegacyCard,
  Page,
  Badge,
  TextField,
  Label,
  Button,
  LegacyStack,
  IndexTable,
  PageActions,
  FormLayout,
  Layout,
} from "@shopify/polaris";
import { ContextualSaveBar, Loading } from "@shopify/app-bridge-react";
import { ImportMinor, ExportMinor, FollowUpEmailMajor, UndoMajor } from "@shopify/polaris-icons";
import EmailPdfDialog from "./support/EmailPdfDialog";
import DeleteConfirmationDialog from "../common/DeleteConfirmationDialog";
import Summary from "./support/Summary";
import ConfirmationDialog from "../common/ConfirmationDialog";
import CustomDatePicker from "../common/CustomDatePicker/CustomDatePicker";
import {
  deletePurchaseOrder,
  updatePurchaseOrder,
  getPurchaseOrderHorseVariants,
  importPurchaseOrderLineItems,
  emailPdf,
  downloadFile,
  useUser,
} from "../../api_utils/requests";
import { getTotal } from "./support/utils";
import HorseVariant from "../common/HorseVariant/HorseVariant";
import {
  addGlowAnimation,
  assertString,
  commaDelimiter,
  datePlaceholder,
  extractMessageFromError,
  formatMoney,
  makeLineItemsMap,
} from "../../helper_functions/utils";
import { ImportFile } from "../common/ImportFile";
import { ErrorBanner } from "../common/ErrorBanner";
import { Footer } from "../common/Footer";
import type { IPurchaseOrder, IPurchaseOrderLineItem } from "../../api_utils/types";
import { PURCHASE_ORDER_HELP_PAGE_URL } from "../../constants";
import Toast from "../common/Toast";
import Scanner from "../common/Scanner";

interface PurchaseOrderLineItems {
  number: IPurchaseOrderLineItem;
}

const Index = ({
  purchaseOrder: initialPurchaseOrder,
  refetch,
  lineItems,
}: {
  readonly purchaseOrder: IPurchaseOrder;
  readonly refetch: () => void;
  readonly lineItems: IPurchaseOrderLineItem[];
}): React.JSX.Element => {
  const today = useMemo(() => datePlaceholder(new Date()), []);

  const initialPurchaseOrderWithArrivalDate = {
    ...initialPurchaseOrder,
    arrival_date: initialPurchaseOrder.arrival_date ? new Date(initialPurchaseOrder.arrival_date) : new Date(),
  };
  const [purchaseOrder, setPurchaseOrder] = useState<IPurchaseOrder>(initialPurchaseOrderWithArrivalDate);
  const [originalPurchaseOrder, setOriginalPurchaseOrder] = useState<IPurchaseOrder>(
    initialPurchaseOrderWithArrivalDate,
  );
  const [importCSVModalIsOpen, setImportCSVModalIsOpen] = useState(false);
  const [polis, setPolis] = useState<PurchaseOrderLineItems>(makeLineItemsMap<IPurchaseOrderLineItem>(lineItems));
  const [isDirty, setIsDirty] = useState(false);
  // const [importEntryNumber, setImportEntryNumber] = useState(purchaseOrder.entry_summary_id || '');
  const [loading, setLoading] = useState(false);
  const [payload, setPayload] = useState({});
  const [emailPdfDialogActive, setEmailPdfDialogActive] = useState(false);
  const [syncCostsModalActive, setSyncCostsModalActive] = useState(false);
  const [syncCostsRequested, setSyncCostsRequested] = useState(false);
  const [closePurchaseOrderModalActive, setClosePurchaseOrderModalActive] = useState(false);
  const [deletionDialogueActive, setDeletionDialogueActive] = useState(false);

  const {
    data: {
      user: { syncbackSetting },
    },
  } = useUser();

  const [errorMessage, setErrorMessage] = useState("");

  const [toastMessage, setToastMessage] = useState<string>(null);
  const [toastErrorMessage, setToastErrorMessage] = useState<string>(null);

  const automaticSyncback = syncbackSetting === "only_cost" || syncbackSetting === "all_syncback";

  const handleMarkAsReceived = async (): Promise<any> => {
    setLoading(true);
    const mPayload = {
      purchase_order: {
        purchase_order_line_items_attributes: Object.values(polis),
        state: "closed",
        sync_costs_requested: syncCostsRequested || automaticSyncback,
      },
    };
    await updatePurchaseOrder(purchaseOrder.id, mPayload)
      .then(() => {
        setToastMessage("Purchase order closed");
        refetch();
      })
      .catch((err: unknown): void => {
        Rollbar.error(err, mPayload);
        const message = extractMessageFromError(err);
        setErrorMessage(message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleRevertToDraft = (): void => {
    setLoading(true);
    const mPayload = {
      purchase_order: {
        purchase_order_line_items_attributes: Object.values(polis),
        state: "draft",
      },
    };
    updatePurchaseOrder(purchaseOrder.id, mPayload)
      .then(() => {
        setToastMessage("Purchase order reverted");
        refetch();
      })
      .catch((err: unknown): void => {
        Rollbar.error(err, mPayload);
        const message = extractMessageFromError(err);
        setErrorMessage(message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleReceiveAll = useCallback((): void => {
    const newQuantity: PurchaseOrderLineItems = Object.values(polis).reduce<PurchaseOrderLineItems>(
      (acc: PurchaseOrderLineItems, value: IPurchaseOrderLineItem): PurchaseOrderLineItems => {
        // copies the purchase quantity
        const copiedValue: number = value.quantity || 0;
        const newValue: number = copiedValue < 0 ? 0 : copiedValue;
        acc[value.id] = {
          ...value,
          quantity: newValue,
          received: newValue,
        } satisfies IPurchaseOrderLineItem;
        return acc;
      },
      {},
    );
    setPolis(newQuantity);
    setIsDirty(true);
  }, [polis, setPolis, setIsDirty]);

  const handleDiscardAction = (): void => {
    setIsDirty(false);
    setPurchaseOrder(originalPurchaseOrder);
    getPurchaseOrderHorseVariants(purchaseOrder.id)
      .then((response) => {
        setPolis(makeLineItemsMap(response || []));
      })
      .catch(() => {
        setPolis({} as { number: IPurchaseOrderLineItem });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleSaveOrdered = (): void => {
    const mPayload = {
      purchase_order: {
        ...payload,
        purchase_order_line_items_attributes: Object.values(polis).map(
          (poli: IPurchaseOrderLineItem): IPurchaseOrderLineItem =>
            ({
              ...poli,
              quantity: poli.quantity || 0,
              received: poli.received || 0,
            }) satisfies IPurchaseOrderLineItem,
        ),
      },
    };
    updatePurchaseOrder(purchaseOrder.id, mPayload)
      .then(() => {
        setToastMessage("Save successful");
        setOriginalPurchaseOrder(purchaseOrder);
        setIsDirty(false);
      })
      .catch((err: unknown): void => {
        Rollbar.error(err, mPayload);
        const message = extractMessageFromError(err);
        setErrorMessage(message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleLineItemQuantityChange = useCallback(
    (mLabel: string, id: number) =>
      (newQuantity: any): void => {
        const cleanQuantity = newQuantity?.replace(/[^\d.-]/g, "");
        const newLineItems = { ...polis, [id]: { ...polis[id], [mLabel]: cleanQuantity } };
        setPolis(newLineItems);
        setIsDirty(true);
      },
    [polis, setPolis, setIsDirty],
  );

  const handleNoteChange = useCallback(
    (note: string): void => {
      setPurchaseOrder({ ...purchaseOrder, note });
      setPayload({ ...payload, note });
      setIsDirty(true);
    },
    [purchaseOrder, setPurchaseOrder, payload, setPayload, setIsDirty],
  );

  const handleDateChange = (arrivalDate: Date): void => {
    setPurchaseOrder({ ...purchaseOrder, arrival_date: arrivalDate });
    setPayload({ ...payload, arrival_date: arrivalDate });
    setIsDirty(true);
  };

  const handleLabelChange = useCallback(
    (label: string): void => {
      setPurchaseOrder({ ...purchaseOrder, label });
      setPayload({ ...payload, label });
      setIsDirty(true);
    },
    [purchaseOrder, setPurchaseOrder, payload, setPayload, setIsDirty],
  );

  const handleEmailPdfModalClose = useCallback((): void => {
    setEmailPdfDialogActive(false);
  }, []);

  // const handleImportEntryChange = (value) => {
  //   setImportEntryNumber(value);
  //   setPayload({ ...payload, entry_summary_id: value });
  //   setIsDirty(true);
  // };

  const incrementLineItem = useCallback((id: number): void => {
    setPolis((prevLineItems: PurchaseOrderLineItems) => {
      const existingLineItem: IPurchaseOrderLineItem = prevLineItems[id];
      const existingQuantity = existingLineItem.received || 0;
      const newQuantity = existingQuantity + 1;
      const updatedLineItem: IPurchaseOrderLineItem = {
        ...existingLineItem,
        received: newQuantity,
      };

      return {
        ...prevLineItems,
        [id]: updatedLineItem,
      };
    });
    setIsDirty(true);

    addGlowAnimation(`input[name="received][id="${id}"]`);
  }, []);

  const openDeletionDialogue = useCallback(() => {
    setDeletionDialogueActive(true);
  }, [setDeletionDialogueActive]);

  const total = getTotal(polis);

  const pageMarkup = purchaseOrder && (
    <>
      {loading ? <Loading /> : null}
      <ContextualSaveBar
        discardAction={{
          onAction: handleDiscardAction,
        }}
        saveAction={{
          onAction: handleSaveOrdered,
        }}
        visible={isDirty}
      />
      <Page
        actionGroups={[
          {
            title: "More actions",
            actions: [
              {
                content: "Export PDF",
                onAction: (): void => {
                  const purchaseOrderName = purchaseOrder.label || purchaseOrder.id;
                  const filename = `Horse Purchase Order ${purchaseOrderName}.pdf`;
                  setLoading(true);
                  downloadFile(`/purchase_orders/${purchaseOrder.id}.pdf?${window.location.search}`, filename)
                    .then(() => {
                      setLoading(false);
                    })
                    .catch((err: unknown): void => {
                      Rollbar.error(err);
                      setLoading(false);
                      setErrorMessage("Failed to generate PDF");
                    });
                },
                icon: ExportMinor,
              },
              {
                content: "Email PDF",
                onAction: (): void => {
                  setEmailPdfDialogActive(true);
                },
                icon: FollowUpEmailMajor,
              },
              {
                content: "Export CSV",
                onAction: (): void => {
                  const purchaseOrderName = purchaseOrder.label || purchaseOrder.id;
                  const filename = `Horse Purchase Order ${purchaseOrderName}.csv`;
                  downloadFile(`/purchase_orders/${purchaseOrder.id}.csv${window.location.search}`, filename);
                },
                icon: ExportMinor,
              },
              {
                content: "Import CSV",
                onAction: (): void => {
                  setImportCSVModalIsOpen(true);
                },
                icon: ImportMinor,
              },
              {
                content: "Revert to draft",
                onAction: handleRevertToDraft,
                prefix: (
                  <div className="UndoMajor">
                    <UndoMajor />
                  </div>
                ),
              },
            ],
          },
        ]}
        backAction={{
          content: "Purchase orders",
          url: "/purchase_orders",
        }}
        primaryAction={{
          content: "Close purchase order",
          onAction: (): void => {
            if (automaticSyncback) {
              handleMarkAsReceived();
            } else {
              setSyncCostsModalActive(true);
            }
          },
        }}
        title="Receive purchase order"
        titleMetadata={<Badge tone="info">Ordered</Badge>}
      >
        <EmailPdfDialog
          active={emailPdfDialogActive}
          onClose={handleEmailPdfModalClose}
          onSend={emailPdf}
          purchaseOrder={purchaseOrder}
          setErrorMessage={setErrorMessage}
          setToastMessage={setToastMessage}
        />
        <ConfirmationDialog
          active={syncCostsModalActive}
          cancelText="Continue"
          content="Do you want to sync these costs to Shopify? Doing so means overwriting the costs of these variants in your Shopify store(s)."
          okText="Sync costs and continue"
          onCancel={() => {
            setSyncCostsModalActive(false);
            setSyncCostsRequested(false);
            setClosePurchaseOrderModalActive(true);
          }}
          onClose={() => {
            setSyncCostsModalActive(false);
          }}
          onOk={() => {
            setSyncCostsModalActive(false);
            setSyncCostsRequested(true);
            setClosePurchaseOrderModalActive(true);
          }}
          title="Sync costs to Shopify"
        />
        <ConfirmationDialog
          active={closePurchaseOrderModalActive}
          cancelText="No"
          content="Are you sure you want to close this purchase order? You cannot undo this."
          okText="Yes"
          onCancel={() => {
            setClosePurchaseOrderModalActive(false);
          }}
          onClose={() => {
            setClosePurchaseOrderModalActive(false);
          }}
          onOk={() => {
            setClosePurchaseOrderModalActive(false);
            handleMarkAsReceived();
          }}
          title="Close purchase order"
        />
        <Layout>
          {errorMessage ? (
            <Layout.Section>
              <ErrorBanner errorMessage={errorMessage} setErrorMessage={setErrorMessage} />
            </Layout.Section>
          ) : null}
          <Layout.Section>
            <LegacyCard sectioned>
              <ImportFile
                apiCallMethod={async (formData) => importPurchaseOrderLineItems(purchaseOrder.id, formData)}
                modalIsOpen={importCSVModalIsOpen}
                setApiResponse={(response) => {
                  setPolis(makeLineItemsMap(response));
                }}
                setErrorMessage={setErrorMessage}
                setModalIsOpen={setImportCSVModalIsOpen}
                setToastMessage={setToastMessage}
                title="Import purchase order CSV"
              />
              {purchaseOrder.supplier ? (
                <LegacyCard.Section title="Supplier">
                  <Label>{purchaseOrder.supplier.name}</Label>
                </LegacyCard.Section>
              ) : (
                <LegacyCard.Section title="Vendor">
                  <Label>{purchaseOrder.vendor}</Label>
                </LegacyCard.Section>
              )}
              <LegacyCard.Section title="Destination">
                <Label>{purchaseOrder.destination}</Label>
              </LegacyCard.Section>
              <LegacyCard.Section>
                <FormLayout>
                  <FormLayout.Group>
                    <TextField
                      autoComplete="off"
                      label="Label"
                      onChange={handleLabelChange}
                      placeholder={purchaseOrder.id.toString()}
                      value={purchaseOrder.label || ""}
                    />
                    <CustomDatePicker
                      date={purchaseOrder.arrival_date}
                      label="Arrival date"
                      placeholder={today}
                      setDate={handleDateChange}
                    />
                    {/* <TextField
                        onChange={handleImportEntryChange}
                        value={importEntryNumber}
                        label="Customs form number"
                      /> */}
                  </FormLayout.Group>
                </FormLayout>
              </LegacyCard.Section>
            </LegacyCard>
          </Layout.Section>
          <Layout.Section>
            <LegacyCard>
              <LegacyCard.Section>
                <LegacyStack distribution="trailing">
                  <Scanner
                    incrementLineItem={incrementLineItem}
                    lineItems={polis}
                    setToastErrorMessage={setToastErrorMessage}
                  />
                  <Button onClick={handleReceiveAll}>Receive all</Button>
                </LegacyStack>
              </LegacyCard.Section>
              <IndexTable
                headings={[
                  { title: "Variant" },
                  { title: "Warehouse location" },
                  // { title: 'Customs form line number' },
                  // { title: 'Description of goods' },
                  // { title: 'Classification number' },
                  // { title: 'Duty rate' },
                  { title: "Quantity ordered" },
                  { title: "Quantity received" },
                  { title: "Cost" },
                  // { title: 'Duties' },
                  { title: "Line total" },
                ]}
                itemCount={Object.values(polis).length}
                selectable={false}
              >
                <IndexTable.Row id="purchaseOrderOrderedTotalsRow" key={0} position={0} rowType="subheader">
                  <IndexTable.Cell>Totals</IndexTable.Cell>
                  {/* <IndexTable.Cell/>
                      <IndexTable.Cell/>
                      <IndexTable.Cell/>
                      <IndexTable.Cell/> */}
                  <IndexTable.Cell />
                  <IndexTable.Cell>{commaDelimiter(total.quantity)}</IndexTable.Cell>
                  <IndexTable.Cell>{commaDelimiter(total.received)}</IndexTable.Cell>
                  <IndexTable.Cell>{formatMoney(total.cost, purchaseOrder.currency)}</IndexTable.Cell>
                  {/* <IndexTable.Cell>
                        { formatMoney(total.duties, purchaseOrder.currency) }
                      </IndexTable.Cell> */}
                  <IndexTable.Cell>{formatMoney(total.totalCost, purchaseOrder.currency)}</IndexTable.Cell>
                </IndexTable.Row>
                {Object.values(polis).map((variant: IPurchaseOrderLineItem, index) => (
                  <IndexTable.Row id={variant.id.toString()} key={variant.id} position={index + 1}>
                    <IndexTable.Cell>
                      <div className="medium_hv">
                        <HorseVariant key={variant.id} variant={variant} />
                      </div>
                    </IndexTable.Cell>
                    {/* <IndexTable.Cell>
                              <div className='w120'>
                                <TextField
                                  onChange={handleLineItemChange('entry_summary_line_number', variant.id)}
                                  id={variant.id}
                                  key={variant.id}
                                  type='integer'
                                  name='entry_summary_line_number'
                                  value={assertString(variant?.entry_summary_line_number)}
                                />
                              </div>
                            </IndexTable.Cell>
                            <IndexTable.Cell>
                              <div className='w120'>
                                <TextField
                                  onChange={handleLineItemChange('description_of_goods', variant.id)}
                                  id={variant.id}
                                  key={variant.id}
                                  name='description_of_goods'
                                  value={variant?.description_of_goods}
                                />
                              </div>
                            </IndexTable.Cell>
                            <IndexTable.Cell>
                              <TextField
                                onChange={handleLineItemChange('classification_number', variant.id)}
                                id={variant.id}
                                key={variant.id}
                                name='classification_number'
                                value={assertString(variant?.classification_number)}
                              />
                            </IndexTable.Cell>
                            <IndexTable.Cell>
                              <div className='w120'>
                                <TextField
                                  onChange={handleLineItemChange('duty_rate', variant.id)}
                                  id={variant.id}
                                  key={variant.id}
                                  name='duty_rate'
                                  suffix='%'
                                  type='number'
                                  value={assertString(polis[variant.id]?.duty_rate)} />
                              </div>
                            </IndexTable.Cell> */}
                    <IndexTable.Cell>{variant.warehouse_location}</IndexTable.Cell>
                    <IndexTable.Cell>{variant.quantity}</IndexTable.Cell>
                    <IndexTable.Cell>
                      <div style={{ maxWidth: "120px" }}>
                        <TextField
                          autoComplete="off"
                          id={variant.id.toString()}
                          inputMode="numeric"
                          key={variant.id}
                          label=""
                          labelHidden
                          name="received"
                          onChange={handleLineItemQuantityChange("received", variant.id)}
                          type="integer"
                          value={assertString(variant.received)}
                        />
                      </div>
                    </IndexTable.Cell>
                    <IndexTable.Cell>{formatMoney(variant.cost, purchaseOrder.currency)}</IndexTable.Cell>
                    {/* <IndexTable.Cell>
                              {formatMoney(getDuties(variant), purchaseOrder.currency)}
                            </IndexTable.Cell> */}
                    <IndexTable.Cell>
                      {formatMoney(Number(variant.quantity || 0) * Number(variant.cost || 0), purchaseOrder.currency)}
                    </IndexTable.Cell>
                  </IndexTable.Row>
                ))}
              </IndexTable>
            </LegacyCard>
          </Layout.Section>
          <Layout.Section variant="oneHalf">
            <LegacyCard title="Note to supplier">
              <LegacyCard.Section>
                <TextField
                  autoComplete="off"
                  // eslint-disable-next-line @typescript-eslint/no-magic-numbers
                  error={purchaseOrder.note?.length >= 1000 ? "Note is too long" : ""}
                  label="Note"
                  maxLength={1000}
                  multiline={2}
                  onChange={handleNoteChange}
                  value={purchaseOrder.note || ""}
                />
              </LegacyCard.Section>
            </LegacyCard>
          </Layout.Section>
          <Layout.Section variant="oneHalf">
            <LegacyCard>
              <LegacyCard.Section>
                <Summary
                  currency={purchaseOrder.currency}
                  lineTotal={Number(total.totalCost)}
                  purchaseOrder={purchaseOrder}
                  setErrorMessage={setErrorMessage}
                />
              </LegacyCard.Section>
            </LegacyCard>
          </Layout.Section>
        </Layout>
        <PageActions
          secondaryActions={[
            {
              content: "Delete",
              destructive: true,
              onAction: openDeletionDialogue,
            },
          ]}
        />
        <DeleteConfirmationDialog
          active={deletionDialogueActive}
          content="Are you sure you want to delete this purchase order? This action cannot be reversed."
          deleteItem={deletePurchaseOrder}
          gotoPath="/purchase_orders"
          itemId={purchaseOrder.id}
          setActive={setDeletionDialogueActive}
          title="Delete purchase"
        />
        <Footer pageTitle="purchase orders" url={PURCHASE_ORDER_HELP_PAGE_URL} />
        <Toast
          setToastErrorMessage={setToastErrorMessage}
          setToastMessage={setToastMessage}
          toastErrorMessage={toastErrorMessage}
          toastMessage={toastMessage}
        />
      </Page>
    </>
  );

  return pageMarkup;
};

export default Index;
