import React, { useMemo } from "react";
import { useTransferOrder } from "../../api_utils/requests";
import { getIdFromPath } from "../../helper_functions/utils";
import { TransferOrderShowPageSkeleton } from "../common/skeletons";

import TransferOrderDraft from "./Draft";
import TransferOrderSent from "./Sent";
import TransferOrderReceived from "./Received";

function Show(): React.JSX.Element {
  const transferOrderId = useMemo(() => getIdFromPath(), []);

  const {data, refetch, isLoading: pageLoading} = useTransferOrder(transferOrderId)
  const { origin, destination, transferOrder, lineItems } = data;

  const commonProps = {
    origin,
    destination,
    transferOrder,
    refetch,
    lineItems,
  };

  const renderPageContent = (): React.JSX.Element => {
    if (!transferOrder) return null;

    const { state } = transferOrder;
    const ComponentMap = {
      draft: TransferOrderDraft,
      sent: TransferOrderSent,
      received: TransferOrderReceived,
    };

    const SelectedComponent = ComponentMap[state];

    return SelectedComponent ? <SelectedComponent {...commonProps} /> : null;
  };

  return pageLoading ? <TransferOrderShowPageSkeleton /> : renderPageContent();
}

export default Show;
