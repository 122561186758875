import {
  TextField, Icon, Popover, DatePicker,
} from '@shopify/polaris';
import React, { useState, useCallback } from 'react';
import { CalendarMajor } from '@shopify/polaris-icons';
import { formatDateStringToMMDYY, MONTHS } from './utils';

const getCurrentMonthAndYear = (date: Date): {
  month: number,
  year: number,
} => {
  const dateParts = date.toDateString().split(' ').slice(1);
  return { month: (Number(MONTHS[dateParts[0]]) - 1), year: Number(dateParts[2]) };
};

function CustomDatePicker({
  label,
  placeholder,
  date,
  setDate,
  disabled = false,
  formatDisplay,
  firstSelectableDate,
}: {
  readonly label: string | React.ReactNode,
  readonly placeholder: string,
  readonly date: Date,
  readonly setDate: (date: Date) => void,
  readonly disabled?: boolean,
  readonly formatDisplay?: (date: Date) => string,
  readonly firstSelectableDate?: Date,
}): React.JSX.Element {
  const [open, setOpen] = useState(false);
  // eslint-disable-next-line react/hook-use-state
  const [{ month, year }, setPickerDate] = useState(getCurrentMonthAndYear(date));

  const handlePickerChange = useCallback((newMonth: number, newYear: number) => {
    setPickerDate({ month: newMonth, year: newYear });
  }, []);

  const handleDateChange = useCallback(({start}): void => {
    setOpen(false);
    setDate(start);
  }, [setDate, setOpen]);

  const handleOpen = useCallback(() => { setOpen(true); }, []);
  const handleClose = useCallback(() => { setOpen(false); }, []);

  const displayDate = formatDisplay ? formatDisplay(date) : formatDateStringToMMDYY(date);

  return (<div data-polaris-layer>
    <Popover
      activator={
        <TextField
          autoComplete='off'
          disabled={disabled}
          label={label}
          onFocus={handleOpen}
          placeholder={placeholder}
          prefix={<Icon source={CalendarMajor} tone="base" />}
          type="text"
          value={displayDate}
        />
      }
      active={open}
      fluidContent={false}
      onClose={handleClose}
      sectioned
      zIndexOverride={1400}>
        {
          !disabled && (<Popover.Pane>
            <Popover.Section>
              <DatePicker
                disableDatesBefore={ firstSelectableDate }
                month={month}
                onChange={handleDateChange}
                onMonthChange={handlePickerChange}
                selected={date}
                year={year}
              />
            </Popover.Section>
        </Popover.Pane>)
        }
    </Popover>
  </div>);
}

export default CustomDatePicker;
