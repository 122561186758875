import React, { useState, useCallback, useRef } from 'react';
import { findLineItemByBarcode } from '../../helper_functions/utils';
import type { IPurchaseOrderLineItem, TransferOrderLineItem } from '../../api_utils/types';
// import BarcodeScanDeviceSelectModal from './BarcodeScanDeviceSelectModal';
import { Button } from '@shopify/polaris';

interface TransferOrderLineItems {number: TransferOrderLineItem};
interface PurchaseOrderLineItems {number: IPurchaseOrderLineItem};

export default function Scanner({
  setToastErrorMessage,
  lineItems,
  incrementLineItem
}: {
  readonly setToastErrorMessage: (message: string) => void;
  readonly lineItems: TransferOrderLineItems | PurchaseOrderLineItems;
  readonly incrementLineItem: (id: number) => void;
}): React.ReactElement {
  const [isScanningActive, setIsScanningActive] = useState(false);
  // const [barcodeScanDeviceSelectModalActive, setBarcodeScanDeviceSelectModalActive] = useState(false);

  const processBarcode = useCallback((currentBarcode: string) => {
    const lineItem = findLineItemByBarcode<TransferOrderLineItem | IPurchaseOrderLineItem>(lineItems, currentBarcode);

    if (lineItem) {
      incrementLineItem(lineItem.id);
    } else {
      setToastErrorMessage(`No variant found with barcode ${currentBarcode}`);
    }
  }, [lineItems, setToastErrorMessage, incrementLineItem]);

  const barcode = useRef<string>(undefined);
  const timeout = useRef<ReturnType<typeof setTimeout>>(null);
  const barcodeScanEventListener = useCallback((evt: KeyboardEvent) => {
    if (timeout.current) {
      clearInterval(timeout.current);
      timeout.current = null;
    };
    if (evt.code === "Enter") {
      if (barcode.current) {
        processBarcode(barcode.current);
      }
      barcode.current = undefined;
      return;
    }
    if (evt.key !== "Shift") barcode.current += evt.key;
    timeout.current = setTimeout(() => (barcode.current = undefined), 20);
  }, [processBarcode]);
  
  const barcodeScanEventListenerAdded = useRef<(evt: KeyboardEvent) => void>(null);

  const handleBarcodeScannerSelect = useCallback(() => {
    setIsScanningActive(true);
    if (barcodeScanEventListenerAdded.current) {
      document.removeEventListener("keydown", barcodeScanEventListenerAdded.current);
    }
    document.addEventListener("keydown", barcodeScanEventListener);
    barcodeScanEventListenerAdded.current = barcodeScanEventListener;
  }, [setIsScanningActive, barcodeScanEventListener]);

  const handleBarcodeScanStop = useCallback(() => {
    setIsScanningActive(false);
    document.removeEventListener("keydown", barcodeScanEventListenerAdded.current);
    barcodeScanEventListenerAdded.current = null;
  }, [setIsScanningActive]);

  // const cancelScanning = useCallback(() => {
  //   setBarcodeScanDeviceSelectModalActive(false);
  // }, [setBarcodeScanDeviceSelectModalActive]);
  // const startScanning = useCallback(() => {
  //   setBarcodeScanDeviceSelectModalActive(false);
  //   handleBarcodeScannerSelect();
  // }, [setBarcodeScanDeviceSelectModalActive, handleBarcodeScannerSelect]);

  return <>
    {/* {
      barcodeScanDeviceSelectModalActive ? <BarcodeScanDeviceSelectModal
          active={barcodeScanDeviceSelectModalActive}
          device='wireless_or_wired'
          onClose={cancelScanning}
          onOk={startScanning}
        /> : null
    } */}
    {
      isScanningActive ? (
        <Button
          onClick={handleBarcodeScanStop}
          tone='critical'
        >Stop scanning</Button>
      ) : (
        <Button
          onClick={handleBarcodeScannerSelect}
        >Scan barcode</Button>
      )
    }
  </>;
};