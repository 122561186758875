import React, { useState, useCallback, useEffect } from "react";
import * as qs from "query-string";
import type { SortButtonChoice, AppliedFilterInterface } from "@shopify/polaris";
import { Select, IndexFilters, useSetIndexFiltersMode, IndexFiltersMode, ChoiceList } from "@shopify/polaris";
import { getCurrencySymbol, isEmpty, softAssertNumber } from "../../../../helper_functions/utils";
import {
  useUser,
  useHorseVariantVendorsOptions,
  allSuppliers,
  allTypes,
  allVendors,
  allLocations,
  useSuppliersSearchOptions,
  useHorseVariantsProductTypeOptions,
  useShopifyChannelAvailabilityOptions,
  useHorseLocationsOptions,
} from "../../../../api_utils/requests";
import TagCheckList from "../../../common/Filters/TagCheckList";
import MoreLessFilter from "../../../common/Filters/MoreLessFilter";
import type { DecimalRangeFilter, HorseVariantsQueryParams, NumberRangeFilter } from "../../../../api_utils/types";
import { moreThanLessThanLabel } from "../../../common/Filters/label_tools";

const sortOptions = [
  { label: "Title", directionLabel: "A-Z", value: "title asc" },
  { label: "Title", directionLabel: "Z-A", value: "title desc" },
  { label: "Variant title", directionLabel: "A-Z", value: "variant_title asc" },
  { label: "Variant title", directionLabel: "Z-A", value: "variant_title desc" },
  { label: "SKU", directionLabel: "A-Z", value: "sku asc" },
  { label: "SKU", directionLabel: "Z-A", value: "sku desc" },
  { label: "Vendor", directionLabel: "A-Z", value: "vendor asc" },
  { label: "Vendor", directionLabel: "Z-A", value: "vendor desc" },
  { label: "Type", directionLabel: "A-Z", value: "product_type asc" },
  { label: "Type", directionLabel: "Z-A", value: "product_type desc" },
  { label: "Available", directionLabel: "Low/High", value: "currently_available_quantity asc" },
  { label: "Available", directionLabel: "High/Low", value: "currently_available_quantity desc" },
  { label: "Weight", directionLabel: "Low/High", value: "weight asc" },
  { label: "Weight", directionLabel: "High/Low", value: "weight desc" },
  { label: "Cost", directionLabel: "Low/High", value: "cost asc" },
  { label: "Cost", directionLabel: "High/Low", value: "cost desc" },
  { label: "Price", directionLabel: "Low/High", value: "price asc" },
  { label: "Price", directionLabel: "High/Low", value: "price desc" },
  { label: "Value", directionLabel: "Low/High", value: "value asc" },
  { label: "Value", directionLabel: "High/Low", value: "value desc" },
] as SortButtonChoice[];

const tabs = [
  {
    // sends collection=undiscarded param to backend
    id: "horse-variants-unarchived-tab",
    content: "Unarchived",
    panelID: "horse-variants-unarchived-tab-content",
    accessibilityLabel: "Unarchived variants",
  },
  {
    // sends collection=all param to backend
    id: "horse-variants-all-tab",
    content: "All",
    panelID: "horse-variants-all-tab-content",
    accessibilityLabel: "All variants (includes unarchived and archived variants)",
  },
];

type DisambiguateLabelArgs =
  | {
      key: "horseLocations";
      value: number;
      label?: never;
      prefix?: never;
      suffix?: never;
    }
  | {
      key: "moreThanLessThan";
      value: DecimalRangeFilter | NumberRangeFilter;
      label: string;
      prefix?: string;
      suffix?: string;
    }
  | {
      key: "productAvailability";
      value: string;
      label?: never;
      prefix?: never;
      suffix?: never;
    }
  | {
      key: "productTags";
      value: string[];
      label?: never;
      prefix?: never;
      suffix?: never;
    }
  | {
      key: "productType";
      value: string;
      label?: never;
      prefix?: never;
      suffix?: never;
    }
  | {
      key: "supplierId";
      value: number;
      label?: never;
      prefix?: never;
      suffix?: never;
    }
  | {
      key: "vendor";
      value: string;
      label?: never;
      prefix?: never;
      suffix?: never;
    };

function HorseVariantSearchFieldAndMoreFiltersComp({
  setFilters,
  selected,
  onSelect,
  initialQuantity,
  initialVendor,
  initialSupplierId,
  horseLocationId,
  setHorseLocationId,
  disableTabs = false,
}: {
  readonly setFilters: (filters: HorseVariantsQueryParams) => void;
  readonly selected?: number;
  readonly onSelect?: (selected: number) => void;
  readonly initialQuantity?: DecimalRangeFilter;
  readonly initialVendor?: string;
  readonly initialSupplierId?: number;
  readonly horseLocationId?: number;
  readonly setHorseLocationId?: (id: number) => void;
  readonly disableTabs?: boolean;
}): React.JSX.Element {
  const parsedUrlSearch = qs.parse(window.location.search);

  const {
    data: {
      user: { currency: userCurrency },
    },
  } = useUser();

  const initSortedCol = (parsedUrlSearch["by_sort[column]"] as string) || "title";
  const initSortedColDir = (parsedUrlSearch["by_sort[direction]"] as string) || "asc";
  const [sort, setSort] = useState([`${initSortedCol} ${initSortedColDir}`]);

  const init = {
    // initially selected values (coming from URL)
    cost: {
      more_than: parsedUrlSearch["by_cost[more_than]"] as string,
      less_than: parsedUrlSearch["by_cost[less_than]"] as string,
    },
    horseLocationId: horseLocationId || softAssertNumber(parsedUrlSearch.horse_location_id as string),
    price: {
      more_than: parsedUrlSearch["by_price[more_than]"] as string,
      less_than: parsedUrlSearch["by_price[less_than]"] as string,
    },
    productAvailability: parsedUrlSearch.productAvailability as string,
    productTags:
      typeof parsedUrlSearch.product_tags === "string" ? [parsedUrlSearch.product_tags] : parsedUrlSearch.product_tags,
    productType: parsedUrlSearch.product_type as string,
    productValue: {
      more_than: parsedUrlSearch["by_value[more_than]"] as string,
      less_than: parsedUrlSearch["by_value[less_than]"] as string,
    },
    quantity: initialQuantity || {
      more_than: parsedUrlSearch["by_quantity[more_than]"] as string,
      less_than: parsedUrlSearch["by_quantity[less_than]"] as string,
    },
    search: parsedUrlSearch.search as string,
    supplierId: initialSupplierId || softAssertNumber(parsedUrlSearch.supplier_id as string),
    totalVolume: {
      more_than: parsedUrlSearch["by_total_volume[more_than]"] as string,
      less_than: parsedUrlSearch["by_total_volume[less_than]"] as string,
    },
    vendor: initialVendor || (parsedUrlSearch.vendor as string),
    weight: {
      more_than: parsedUrlSearch["by_weight[more_than]"] as string,
      less_than: parsedUrlSearch["by_weight[less_than]"] as string,
    },
  };

  const [cost, setCost] = useState<DecimalRangeFilter>(init.cost);
  const [price, setPrice] = useState<DecimalRangeFilter>(init.price);
  const [productAvailability, setProductAvailability] = useState(init.productAvailability || "");
  const [productTags, setProductTags] = useState(init.productTags || []);
  const [productType, setProductType] = useState(init.productType);
  const [productValue, setProductValue] = useState<DecimalRangeFilter>(init.productValue);
  const [quantity, setQuantity] = useState<DecimalRangeFilter>(init.quantity);
  const [search, setSearch] = useState(init.search || "");
  const [supplierId, setSupplierId] = useState<number | undefined>(init.supplierId);
  const [totalVolume, setTotalVolume] = useState<DecimalRangeFilter>(init.totalVolume);
  const [vendor, setVendor] = useState<string>(init.vendor);
  const [weight, setWeight] = useState<DecimalRangeFilter>(init.weight);

  const includeDiscarded = parsedUrlSearch.collection === "all" ? "all" : "undiscarded";
  const { data: vendors } = useHorseVariantVendorsOptions({ collection: includeDiscarded });
  const { data: suppliers } = useSuppliersSearchOptions();
  const { data: productTypes } = useHorseVariantsProductTypeOptions({ collection: includeDiscarded });
  const { data: shopifyChannels } = useShopifyChannelAvailabilityOptions();
  const { data: horseLocations } = useHorseLocationsOptions();

  useEffect(() => {
    const [column, direction] = sort[0].split(" ");
    const shopifyChannelOption = shopifyChannels.find((channel) => channel.value === productAvailability);
    const published_shopify_channel_ids: number[] = [];
    const unpublished_shopify_channel_ids: number[] = [];
    if (shopifyChannelOption) {
      const [shopifyChannelIds, isPublished] = shopifyChannelOption.value.split(":");
      if (isPublished === "true") {
        published_shopify_channel_ids.push(...shopifyChannelIds.split("|").map(parseInt));
      } else if (isPublished === "false") {
        unpublished_shopify_channel_ids.push(...shopifyChannelIds.split("|").map(parseInt));
      }
    }
    const queryParams: HorseVariantsQueryParams = {
      by_cost: cost,
      horse_location_id: horseLocationId,
      by_price: price,
      published_shopify_channel_ids: published_shopify_channel_ids,
      unpublished_shopify_channel_ids: unpublished_shopify_channel_ids,
      product_tags: productTags,
      product_type: productType,
      by_value: productValue,
      by_quantity: quantity,
      search,
      supplier_id: supplierId,
      by_total_volume: totalVolume,
      vendor: vendor,
      by_weight: weight,

      by_sort: { column, direction },
    };
    setFilters(queryParams);
  }, [
    setFilters,
    shopifyChannels,
    cost,
    horseLocationId,
    price,
    productAvailability,
    productTags,
    productType,
    productValue,
    quantity,
    search,
    sort,
    supplierId,
    totalVolume,
    vendor,
    weight,
  ]);

  const handleVendorChange = useCallback((value: string) => {
    setVendor(value === allVendors ? undefined : value);
  }, []);
  const handleSupplierChange = useCallback((value: string) => {
    setSupplierId(value === allSuppliers ? undefined : softAssertNumber(value));
  }, []);
  const handleProductTypeChange = useCallback((value: string) => {
    setProductType(value === allTypes ? undefined : value);
  }, []);
  const handleHorseLocationIdChange = useCallback(
    (newHorseLocationId: string) => {
      setHorseLocationId(newHorseLocationId === allLocations ? undefined : softAssertNumber(newHorseLocationId));
    },
    [setHorseLocationId],
  );
  const handleNewTagAddition = useCallback(
    (newTag) => {
      const payload = [...productTags, newTag];
      setProductTags(payload);
    },
    [productTags],
  );

  const handleCostRemove = useCallback(() => {
    setCost({ more_than: undefined, less_than: undefined });
  }, []);
  const handleHorseLocationRemove = useCallback(() => {
    setHorseLocationId(undefined);
  }, [setHorseLocationId]);
  const handlePriceRemove = useCallback(() => {
    setPrice({ more_than: undefined, less_than: undefined });
  }, []);
  const handleProductAvailabilityRemove = useCallback(() => {
    setProductAvailability("");
  }, []);
  const handleProductTagsRemove = useCallback(() => {
    setProductTags([]);
  }, []);
  const handleProductTypeRemove = useCallback(() => {
    setProductType(undefined);
  }, []);
  const handleProductValueRemove = useCallback(() => {
    setProductValue({ more_than: undefined, less_than: undefined });
  }, []);
  const handleQuantityRemove = useCallback(() => {
    setQuantity({ more_than: undefined, less_than: undefined });
  }, []);
  const handleSearchRemove = useCallback(() => {
    setSearch("");
  }, []);
  const handleSupplierIdRemove = useCallback(() => {
    setSupplierId(undefined);
  }, []);
  const handleTotalVolumeRemove = useCallback(() => {
    setTotalVolume({ more_than: undefined, less_than: undefined });
  }, []);
  const handleVendorRemove = useCallback(() => {
    setVendor(undefined);
  }, []);
  const handleWeightRemove = useCallback(() => {
    setWeight({ more_than: undefined, less_than: undefined });
  }, []);

  const handleFiltersClearAll = useCallback(() => {
    handleCostRemove();
    handleHorseLocationRemove();
    handlePriceRemove();
    handleProductAvailabilityRemove();
    handleProductTagsRemove();
    handleProductTypeRemove();
    handleProductValueRemove();
    handleQuantityRemove();
    handleSearchRemove();
    handleSupplierIdRemove();
    handleTotalVolumeRemove();
    handleVendorRemove();
    handleWeightRemove();
  }, [
    handleCostRemove,
    handleHorseLocationRemove,
    handlePriceRemove,
    handleProductAvailabilityRemove,
    handleProductTagsRemove,
    handleProductTypeRemove,
    handleProductValueRemove,
    handleQuantityRemove,
    handleSearchRemove,
    handleSupplierIdRemove,
    handleTotalVolumeRemove,
    handleVendorRemove,
    handleWeightRemove,
  ]);

  const disambiguateLabel = useCallback(
    ({ key, value, label = "", prefix = "", suffix = "" }: DisambiguateLabelArgs): string => {
      switch (key) {
        case "vendor":
          return `Vendor is ${value}`;
        case "supplierId":
          return `Supplier is ${suppliers.find((sup) => sup.value === value.toString())?.label || value}`;
        case "productType":
          return `Type: ${value}`;
        case "productTags":
          return `Tags: ${value.join(", ")}`;
        case "productAvailability":
          return value;
        case "horseLocations":
          return `Location is ${horseLocations.find((hlo) => hlo.value === value.toString())?.label || value}`;
        case "moreThanLessThan":
          return moreThanLessThanLabel(value, label, prefix, suffix);
        default:
          throw new Error(`Unknown key: ${key}`);
      }
    },
    [suppliers, horseLocations],
  );

  // TODO: totalVolume
  const filters = [
    {
      key: "vendor",
      label: "Vendor",
      filter: (
        <Select label="" labelHidden onChange={handleVendorChange} options={vendors} value={vendor || allVendors} />
      ),
    },
    {
      key: "supplierId",
      label: "Supplier",
      filter: (
        <Select
          label=""
          labelHidden
          onChange={handleSupplierChange}
          options={suppliers}
          value={supplierId?.toString() || allSuppliers}
        />
      ),
    },
    {
      key: "productType",
      label: "Type",
      filter: (
        <Select
          label=""
          labelHidden
          onChange={handleProductTypeChange}
          options={productTypes}
          value={productType || allTypes}
        />
      ),
    },
    {
      key: "cost",
      label: "Cost",
      filter: (
        <MoreLessFilter
          align="right"
          less_than={cost.less_than}
          more_than={cost.more_than}
          prefix={getCurrencySymbol(userCurrency)}
          setStateCallback={setCost}
          targetState={cost}
        />
      ),
    },
    {
      key: "price",
      label: "Price",
      filter: (
        <MoreLessFilter
          align="right"
          less_than={price.less_than}
          more_than={price.more_than}
          prefix={getCurrencySymbol(userCurrency)}
          setStateCallback={setPrice}
          targetState={price}
        />
      ),
    },
    {
      key: "productValue",
      label: "Inventory value",
      filter: (
        <MoreLessFilter
          align="right"
          less_than={productValue.less_than}
          more_than={productValue.more_than}
          prefix={getCurrencySymbol(userCurrency)}
          setStateCallback={setProductValue}
          targetState={productValue}
        />
      ),
    },
    {
      key: "weight",
      label: "Weight",
      filter: (
        <MoreLessFilter
          less_than={weight.less_than}
          more_than={weight.more_than}
          setStateCallback={setWeight}
          suffix="g"
          targetState={weight}
        />
      ),
    },
    {
      key: "quantity",
      label: "Available quantity",
      filter: (
        <MoreLessFilter
          less_than={quantity.less_than}
          more_than={quantity.more_than}
          setStateCallback={setQuantity}
          targetState={quantity}
        />
      ),
    },
    {
      key: "productAvailability",
      label: "Publishing",
      filter: (
        <ChoiceList
          choices={shopifyChannels}
          onChange={useCallback(([value]) => {
            setProductAvailability(value);
          }, [])}
          selected={[productAvailability]}
          title=""
          titleHidden
        />
      ),
    },
    {
      key: "productTags",
      label: "Tags",
      filter: (
        <TagCheckList
          createNew={false}
          onAdd={handleNewTagAddition}
          onChange={setProductTags}
          selectedOptions={productTags}
        />
      ),
    },
    {
      key: "horseLocations",
      label: "Location",
      filter: (
        <Select
          label=""
          labelHidden
          onChange={handleHorseLocationIdChange}
          options={horseLocations}
          value={horseLocationId?.toString() || allLocations}
        />
      ),
    },
  ];

  const appliedFilters: AppliedFilterInterface[] = [];
  if (!isEmpty(vendor)) {
    appliedFilters.push({
      key: "vendor",
      label: disambiguateLabel({ key: "vendor", value: vendor }),
      onRemove: handleVendorRemove,
    });
  }
  if (!isEmpty(supplierId)) {
    appliedFilters.push({
      key: "supplierId",
      label: disambiguateLabel({ key: "supplierId", value: supplierId }),
      onRemove: handleSupplierIdRemove,
    });
  }
  if (!isEmpty(productType)) {
    appliedFilters.push({
      key: "productType",
      label: disambiguateLabel({ key: "productType", value: productType }),
      onRemove: handleProductTypeRemove,
    });
  }
  if (!isEmpty(price.more_than) || !isEmpty(price.less_than)) {
    appliedFilters.push({
      key: "price",
      label: disambiguateLabel({
        key: "moreThanLessThan",
        value: price,
        label: "Price",
        prefix: getCurrencySymbol(userCurrency),
      }),
      onRemove: handlePriceRemove,
    });
  }
  if (!isEmpty(cost.more_than) || !isEmpty(cost.less_than)) {
    appliedFilters.push({
      key: "cost",
      label: disambiguateLabel({
        key: "moreThanLessThan",
        value: cost,
        label: "Cost",
        prefix: getCurrencySymbol(userCurrency),
      }),
      onRemove: handleCostRemove,
    });
  }
  if (!isEmpty(productValue.more_than) || !isEmpty(productValue.less_than)) {
    appliedFilters.push({
      key: "productValue",
      label: disambiguateLabel({
        key: "moreThanLessThan",
        value: productValue,
        label: "Value",
        prefix: getCurrencySymbol(userCurrency),
      }),
      onRemove: handleProductValueRemove,
    });
  }
  if (!isEmpty(weight.more_than) || !isEmpty(weight.less_than)) {
    appliedFilters.push({
      key: "weight",
      label: disambiguateLabel({
        key: "moreThanLessThan",
        value: weight,
        label: "Weight",
        suffix: "g",
      }),
      onRemove: handleWeightRemove,
    });
  }
  if (!isEmpty(totalVolume.more_than) || !isEmpty(totalVolume.less_than)) {
    appliedFilters.push({
      key: "totalVolume",
      label: disambiguateLabel({
        key: "moreThanLessThan",
        value: totalVolume,
        label: "Total volume",
        suffix: "m³",
      }),
      onRemove: handleTotalVolumeRemove,
    });
  }
  if (!isEmpty(quantity.more_than) || !isEmpty(quantity.less_than)) {
    appliedFilters.push({
      key: "quantity",
      label: disambiguateLabel({
        key: "moreThanLessThan",
        value: quantity,
        label: "Quantity",
      }),
      onRemove: handleQuantityRemove,
    });
  }
  if (!isEmpty(productAvailability)) {
    const badgeLabel = shopifyChannels.find((channel) => channel.value === productAvailability).label as string;
    appliedFilters.push({
      key: "productAvailability",
      label: disambiguateLabel({ key: "productAvailability", value: badgeLabel }),
      onRemove: handleProductAvailabilityRemove,
    });
  }
  if (!isEmpty(productTags)) {
    appliedFilters.push({
      key: "productTags",
      label: disambiguateLabel({ key: "productTags", value: productTags }),
      onRemove: handleProductTagsRemove,
    });
  }
  if (!isEmpty(horseLocationId)) {
    appliedFilters.push({
      key: "horseLocations",
      label: disambiguateLabel({ key: "horseLocations", value: horseLocationId }),
      onRemove: handleHorseLocationRemove,
    });
  }
  const { mode, setMode } = useSetIndexFiltersMode(disableTabs ? IndexFiltersMode.Filtering : IndexFiltersMode.Default);

  return (
    <IndexFilters
      appliedFilters={appliedFilters}
      canCreateNewView={false}
      cancelAction={{
        onAction: () => {},
        disabled: false,
        loading: false,
      }}
      closeOnChildOverlayClick
      filters={filters}
      mode={mode}
      onClearAll={handleFiltersClearAll}
      onQueryChange={setSearch}
      onQueryClear={handleSearchRemove}
      onSelect={disableTabs ? undefined : onSelect}
      onSort={setSort}
      queryPlaceholder="Search variants"
      queryValue={search}
      selected={disableTabs ? undefined : selected}
      setMode={setMode}
      sortOptions={sortOptions}
      sortSelected={sort}
      tabs={disableTabs ? [] : tabs}
    />
  );
}

export const HorseVariantSearchFieldAndMoreFilters = React.memo(HorseVariantSearchFieldAndMoreFiltersComp);
