import React from 'react';
import { Tooltip } from '@shopify/polaris';

export const TruncatedText = ({
  text,
  maxLength,
  children,
}: {
  text: string,
  maxLength: number,
  children: React.ReactNode,
}) => {
  if (text.length > maxLength) {
    return (
      <Tooltip content={text} preferredPosition="above">
        {children}
      </Tooltip>
    );
  }
  return children;
};
