import React, { useMemo, useState } from "react";
import {
  LegacyCard,
  Page,
  Badge,
  TextField,
  Label,
  FormLayout,
  PageActions,
  IndexTable,
  Layout,
  Button,
} from "@shopify/polaris";
import { ContextualSaveBar } from "@shopify/app-bridge-react";
import CustomDatePicker from "../common/CustomDatePicker/CustomDatePicker";
import { deleteTransferOrder, downloadFile, updateTransferOrder } from "../../api_utils/requests";
import DeleteConfirmationDialog from "../common/DeleteConfirmationDialog";
import HorseVariant from "../common/HorseVariant/HorseVariant";
import { ErrorBanner } from "../common/ErrorBanner";
import { Footer } from "../common/Footer";
import { assertNumber, extractMessageFromError, humanDateFormat } from "../../helper_functions/utils";
import { TRANSFER_ORDER_HELP_PAGE_URL } from "../../constants";
import Toast from "../common/Toast";
import type { HorseLocation, TransferOrder, TransferOrderLineItem } from "../../api_utils/types";
import type { NonEmptyArray } from "@shopify/polaris/build/ts/src/types";
import type { IndexTableHeading } from "@shopify/polaris/build/ts/src/components/IndexTable";

const columnNames = [
  { title: "Variant" },
  { title: "Sent" },
  { title: "Received" },
] satisfies NonEmptyArray<IndexTableHeading>;

const Index = ({
  origin,
  destination,
  transferOrder,
  lineItems,
}: {
  origin: HorseLocation;
  destination: HorseLocation;
  transferOrder: TransferOrder;
  lineItems: TransferOrderLineItem[];
}): React.ReactElement => {
  const today = useMemo(() => new Date(), []);

  const estimatedArrivalDate = transferOrder?.arrived_date ? new Date(transferOrder?.arrived_date) : undefined;
  const [label, setLabel] = useState(transferOrder.label || "");
  const [isDirty, setIsDirty] = useState(false);
  const [deletionDialogueActive, setDeletionDialogueActive] = useState(false);

  const [errorMessage, setErrorMessage] = useState("");

  const [toastMessage, setToastMessage] = useState<string>(null);

  const handleLabelChange = (newLabel) => {
    setLabel(newLabel);
    setIsDirty(true);
  };

  const handleDiscardAction = () => {
    setIsDirty(false);
    setLabel(transferOrder.label || "");
  };

  const handleSaveAction = () => {
    const payload = { label };
    updateTransferOrder(transferOrder.id, payload)
      .then(() => {
        setToastMessage("Save successful");
      })
      .catch((err: unknown) => {
        Rollbar.error(err, payload);
        const message = extractMessageFromError(err);
        setErrorMessage(message);
      })
      .finally(() => {
        setIsDirty(false);
      });
  };

  const total = lineItems.reduce(
    (acc, item) => {
      acc.quantity_sent += assertNumber(item.quantity_sent);
      acc.quantity_received += assertNumber(item.quantity_received);
      return acc;
    },
    { quantity_received: 0, quantity_sent: 0 },
  );

  const pageMarkup = transferOrder && (
    <>
      <ContextualSaveBar
        discardAction={{
          onAction: handleDiscardAction,
        }}
        saveAction={{
          onAction: handleSaveAction,
        }}
        visible={isDirty}
      />
      <Page
        backAction={{
          content: "Closed transfer order",
          url: "/transfer_orders",
        }}
        primaryAction={
          <Button
            onClick={() => {
              const transferOrderName = transferOrder.label || transferOrder.id;
              const filename = `Horse Transfer Order ${transferOrderName}.csv`;
              downloadFile(`/transfer_orders/${transferOrder.id}.csv${window.location.search}`, filename);
            }}
            removeUnderline
            size="medium"
            variant="monochromePlain"
          >
            Export CSV
          </Button>
        }
        title="Closed transfer order"
        titleMetadata={<Badge tone="success">Received</Badge>}
      >
        <Layout>
          {errorMessage ? (
            <Layout.Section variant="fullWidth">
              <ErrorBanner errorMessage={errorMessage} setErrorMessage={setErrorMessage} />
            </Layout.Section>
          ) : null}
          <Layout.Section variant="oneThird">
            <LegacyCard sectioned>
              <LegacyCard.Section title="Origin">
                <Label>{origin.name}</Label>
              </LegacyCard.Section>
              <LegacyCard.Section title="Destination">
                <Label>{destination.name}</Label>
              </LegacyCard.Section>
              <LegacyCard.Section>
                <FormLayout>
                  <FormLayout.Group>
                    <TextField
                      autoComplete="off"
                      label="Label"
                      onChange={handleLabelChange}
                      placeholder="64568793"
                      value={label}
                    />
                  </FormLayout.Group>
                  <FormLayout.Group>
                    <CustomDatePicker
                      date={estimatedArrivalDate || today}
                      disabled
                      label="Arrival date"
                      placeholder={humanDateFormat(today)}
                      setDate={() => {}}
                    />
                  </FormLayout.Group>
                </FormLayout>
              </LegacyCard.Section>
            </LegacyCard>
          </Layout.Section>
          <Layout.Section>
            <LegacyCard>
              <IndexTable headings={columnNames} itemCount={lineItems.length} selectable={false}>
                <IndexTable.Row id="transferOrdersReceivedTotalsRow" key={0} position={0} rowType="subheader">
                  <IndexTable.Cell>Totals</IndexTable.Cell>
                  <IndexTable.Cell>
                    <div
                      key="total_quantity_sent"
                      style={{ display: "flex", alignItems: "start", justifyContent: "start" }}
                    >
                      {total.quantity_sent}
                    </div>
                  </IndexTable.Cell>
                  <IndexTable.Cell>
                    <div
                      key="total_quantity_received"
                      style={{ display: "flex", alignItems: "start", justifyContent: "start" }}
                    >
                      {total.quantity_received}
                    </div>
                  </IndexTable.Cell>
                </IndexTable.Row>
                {lineItems.map((variant, index) => (
                  <IndexTable.Row id={variant.id.toString()} key={variant.id} position={index + 1}>
                    <IndexTable.Cell>
                      <HorseVariant key={variant.id} variant={variant} />
                    </IndexTable.Cell>
                    <IndexTable.Cell>
                      <div key={variant.id}>{variant.quantity_sent}</div>
                    </IndexTable.Cell>
                    <IndexTable.Cell>
                      <div key={variant.id}>{variant.quantity_received}</div>
                    </IndexTable.Cell>
                  </IndexTable.Row>
                ))}
              </IndexTable>
            </LegacyCard>
          </Layout.Section>
        </Layout>
        <PageActions
          secondaryActions={[
            {
              content: "Delete",
              destructive: true,
              onAction: () => {
                setDeletionDialogueActive(true);
              },
            },
          ]}
        />
        <DeleteConfirmationDialog
          active={deletionDialogueActive}
          content="Are you sure you want to delete this transfer? This action cannot be reversed."
          deleteItem={deleteTransferOrder}
          gotoPath="/transfer_orders"
          itemId={transferOrder.id}
          setActive={setDeletionDialogueActive}
          title="Delete transfer"
        />
        <Footer pageTitle="transfer orders" url={TRANSFER_ORDER_HELP_PAGE_URL} />
        <Toast setToastMessage={setToastMessage} toastMessage={toastMessage} />
      </Page>
    </>
  );

  return pageMarkup;
};

export default Index;
