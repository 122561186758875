import React from 'react';
import {
  Modal,
} from '@shopify/polaris';

const ConfirmationDialog = ({
  active,
  cancelText,
  content,
  okText,
  onCancel,
  onClose,
  onOk,
  title,
}: {
  active: boolean,
  cancelText: string,
  content: string,
  okText: string,
  onCancel: () => void,
  onClose: () => void,
  onOk: () => void,
  title: string,
}): React.JSX.Element => {
  return (
      <Modal
        open={active}
        onClose={onClose}
        title={title}
        primaryAction={{
          content: okText,
          onAction: onOk,
        }}
        secondaryActions={[
          {
            content: cancelText,
            onAction: onCancel,
          },
        ]}
      >
        <Modal.Section>
          {content}
        </Modal.Section>
      </Modal>
  );
};

export default ConfirmationDialog;
