export const MONTHS = {
  Jan: '01',
  Feb: '02',
  Mar: '03',
  Apr: '04',
  May: '05',
  Jun: '06',
  Jul: '07',
  Aug: '08',
  Sep: '09',
  Oct: '10',
  Nov: '11',
  Dec: '12',
};

const SHORT_MONTHS = {
  '01': 'Jan',
  '02': 'Feb',
  '03': 'Mar',
  '04': 'Apr',
  '05': 'May',
  '06': 'Jun',
  '07': 'Jul',
  '08': 'Aug',
  '09': 'Sep',
  10: 'Oct',
  11: 'Nov',
  12: 'Dec',
};

/**
 *
 * @param {string} eg. 2021-09-24
 * @returns {string}. eg. Oct 7, 21
 */
export const formatDateStringToMMDYY = (date: Date): string => {
  if (!date) return '';
  const newDate = new Date(date);
  const year = new Intl.DateTimeFormat('en', { year: '2-digit' }).format(newDate);
  const day = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(newDate);
  const month = new Intl.DateTimeFormat('en', { month: '2-digit' }).format(newDate);
  return `${SHORT_MONTHS[month]}. ${day}, ${year}`;
};
