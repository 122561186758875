import React from 'react';
import {
  LegacyCard,
  TextField,
  FormLayout,
} from '@shopify/polaris';
import { assertString } from '../../../../helper_functions/utils';
import { IHorseVariant } from '../../../../api_utils/types';

export const Inventory = ({
  horseVariant,
  setWarehouseLocation,
}: {
  horseVariant: IHorseVariant,
  setWarehouseLocation: (value: string) => void,
}): React.JSX.Element => {
  return (
    <LegacyCard title='Inventory' sectioned>
      <FormLayout>
          <TextField
            autoComplete='off'
            disabled
            label='SKU (Stock Keeping Unit)'
            value={assertString(horseVariant.sku)}
            type="text"
            helpText="Along with a variant's Barcode, its barcode is used to link multiple variants together"
          />
          <TextField
            autoComplete='off'
            disabled
            label='Barcode'
            value={assertString(horseVariant.barcode)}
            type='text'
            helpText="Along with a variant's SKU, its barcode is used to link multiple variants together"
          />
          <TextField
            autoComplete='off'
            label='Warehouse location'
            value={assertString(horseVariant.warehouse_location)}
            type='text'
            onChange={setWarehouseLocation}
            helpText='Add a note to purchase orders and transfer orders to record the location of this variant'
          />
      </FormLayout>
    </LegacyCard>
  );
};
