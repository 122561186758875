import React, { useState, useCallback } from "react";
import {
  Page,
  LegacyCard,
  IndexTable,
  Button,
  Tooltip,
  Layout,
  EmptyState,
  Select,
  InlineStack,
  Text,
} from "@shopify/polaris";
import qs from "query-string";
import {
  downloadFile,
  forceNumber,
  useSalesTrends,
  useShopifyChannelSalesOptions,
  useTotalsAndMax,
} from "../../api_utils/requests";
import { commaDelimiter } from "../../helper_functions/utils";
import SalesTrendsSearchFieldAndMoreFilters from "./SalesTrendsSearchFieldAndMoreFilters";
import { QuantityChangeModal } from "../common/QuantityChangeModal";
import HorseVariant from "../common/HorseVariant/HorseVariant";
import { ErrorBanner } from "../common/ErrorBanner";
import PopoverWithCheckList from "../common/Filters/PopoverWithCheckList";
import { Footer } from "../common/Footer";
import { HorseLocationSelect } from "../common/Filters/HorseLocationSelect";
import { LinkText } from "../common/HorseVariant/LinkText";
import type { SalesTrendsQueryParams } from "../../api_utils/types";
import type { IndexTableHeading } from "@shopify/polaris/build/ts/src/components/IndexTable";
import type { NonEmptyArray } from "@shopify/polaris/build/ts/src/types";
import { SalesTrendsIndexPageSkeleton } from "../common/skeletons";
import NiceSalesRate from "./support/NiceSalesRate";
import StockInfo from "./support/StockInfo";

const salesRateColumNames = [
  { title: "Variant" },
  { title: "Available" },
  { title: "Ordered" },
  { title: "Days available" },
  { title: "Sales" },
  { title: "Sales rate" },
  { title: "Days remaining" },
  { title: "Stock info" },
] as NonEmptyArray<IndexTableHeading>;

const seasonalColumNames = [
  { title: "Variant" },
  { title: "Available" },
  { title: "Ordered" },
  { title: "Days available" },
  { title: "Last year's sales" },
  { title: "Days remaining" },
  { title: "Stock info" },
] as NonEmptyArray<IndexTableHeading>;

const seasonalSamplingPeriods = [
  { value: "30", label: "Next 30 days" },
  { value: "90", label: "Next 90 days" },
  { value: "180", label: "Next 180 days" },
  { value: "365", label: "Next year" },
];
const salesRateSamplingPeriods = [
  { value: "30", label: "Past 30 days" },
  { value: "90", label: "Past 90 days" },
  { value: "180", label: "Past 180 days" },
  { value: "365", label: "Past year" },
];

const TABS = {
  sales_rate: 0,
  seasonal: 1,
};

const emptyState = <EmptyState heading="No variants found" image="" />;

const salesTrendsHelpPageUrl = "https://horse-inventory.notion.site/Sales-Trends-62314ee9b55a468c8116027b8fb7239d";

export default function SalesTrendsIndex(): React.JSX.Element {
  const [errorMessage, setErrorMessage] = useState("");

  const parsedUrlSearch = qs.parse(window.location.search);
  const initialForecastingMethod: number = parsedUrlSearch.forecasting_method
    ? TABS[parsedUrlSearch.forecasting_method as keyof typeof TABS]
    : TABS.sales_rate;
  const [forecastingMethod, setForecastingMethod] = useState<number>(initialForecastingMethod);
  const initSamplingPeriod = parsedUrlSearch.sampling_period as string;
  const [samplingPeriod, setSamplingPeriod] = useState<string>(initSamplingPeriod || "90");

  const [filters, setFilters] = useState<Partial<SalesTrendsQueryParams>>({});

  const [pageNum, setPageNum] = useState(Number(parsedUrlSearch.page) || 1);

  const initShopifyChannelIds = parsedUrlSearch.sale_shopify_channel_ids;
  const [shopifyChannelIds, setShopifyChannelIds] = useState<string[]>(
    typeof initShopifyChannelIds === "string" ? [initShopifyChannelIds] : [],
  );

  const initHorseLocationId = parsedUrlSearch.horse_location_id as string;
  const [horseLocationId, setHorseLocationId] = useState<string>(initHorseLocationId);

  const { data: shopifyChannels, isPending: pageLoading } = useShopifyChannelSalesOptions();

  const queryParams: Partial<SalesTrendsQueryParams> = {
    ...filters,
    horse_location_id: forceNumber(horseLocationId),
    sampling_period: samplingPeriod,
    sale_shopify_channel_ids: shopifyChannelIds.map(forceNumber),
    page: pageNum,
  };
  const { isPending: salesTrendsIsPending, data: salesTrendsData } = useSalesTrends(queryParams);

  const { rows, hasNext, hasPrev } = salesTrendsData || {};
  const salesTrends = rows || [];

  const { data: totalsAndMax } = useTotalsAndMax(queryParams);
  // Don't ever decrease the max-range of the slider
  let maxSalesRate = 1.9;
  if (totalsAndMax.max_sales_rate > maxSalesRate) {
    maxSalesRate = totalsAndMax.max_sales_rate;
  }

  const isLoading = salesTrendsIsPending;

  const samplingPeriods = forecastingMethod === TABS.seasonal ? seasonalSamplingPeriods : salesRateSamplingPeriods;

  const onFilterChange = useCallback((newFilters: Partial<SalesTrendsQueryParams>) => {
    setPageNum(1);
    setFilters(newFilters);
  }, []);

  const exportCSV = useCallback(async () => {
    const filename = `Horse Sales Trends.csv`;
    await downloadFile(`/sales_trends.csv`, filename, queryParams);
  }, [queryParams]);

  const setPageNumAndScroll = useCallback((newPageNum: number) => {
    setPageNum(newPageNum);
    document.getElementsByClassName("Polaris-IndexTable__Table")[0]?.scrollIntoView();
  }, []);
  const nextPage = useCallback(() => {
    if (hasNext) {
      setPageNumAndScroll(pageNum + 1);
    }
  }, [hasNext, pageNum, setPageNumAndScroll]);
  const prevPage = useCallback(() => {
    if (hasPrev) {
      setPageNumAndScroll(pageNum - 1);
    }
  }, [hasPrev, pageNum, setPageNumAndScroll]);

  const pageMarkup = (
    <Page
      fullWidth
      primaryAction={
        <Button onClick={exportCSV} removeUnderline size="medium" variant="plain">
          Export CSV
        </Button>
      }
      title="Sales trends"
    >
      <Layout>
        {errorMessage ? (
          <Layout.Section>
            <ErrorBanner errorMessage={errorMessage} setErrorMessage={setErrorMessage} />
          </Layout.Section>
        ) : null}
        <Layout.Section>
          <LegacyCard>
            <LegacyCard.Section>
              <InlineStack blockAlign="start" gap="300">
                <HorseLocationSelect horseLocationId={horseLocationId} setHorseLocationId={setHorseLocationId} />
                <Select
                  label="Sampling period"
                  labelHidden
                  onChange={setSamplingPeriod}
                  options={samplingPeriods}
                  value={samplingPeriod}
                />
                <PopoverWithCheckList
                  choices={shopifyChannels}
                  defaultTitle="Sales channels"
                  onChange={setShopifyChannelIds}
                  onValueRemoved={(valueRemoved) => {
                    const newShopifyChannels = shopifyChannelIds.filter(
                      (shopifyChannelId) => shopifyChannelId !== valueRemoved,
                    );
                    setShopifyChannelIds(newShopifyChannels);
                  }}
                  selected={shopifyChannelIds}
                />
              </InlineStack>
            </LegacyCard.Section>
            <SalesTrendsSearchFieldAndMoreFilters
              forecastingMethod={forecastingMethod}
              maxSalesRate={maxSalesRate}
              samplingPeriod={samplingPeriod}
              setFilters={onFilterChange}
              setForecastingMethod={setForecastingMethod}
            />
            <IndexTable
              emptyState={emptyState}
              headings={forecastingMethod === TABS.seasonal ? seasonalColumNames : salesRateColumNames}
              itemCount={salesTrends.length}
              loading={isLoading}
              pagination={{
                hasPrevious: hasPrev,
                previousKeys: [37],
                onPrevious: prevPage,
                hasNext: hasNext,
                nextKeys: [39],
                onNext: nextPage,
              }}
              selectable={false}
            >
              <IndexTable.Row id="0:str" key={0} position={0} rowType="subheader">
                <IndexTable.Cell>Totals</IndexTable.Cell>
                <IndexTable.Cell>{totalsAndMax ? commaDelimiter(totalsAndMax.total_available) : null}</IndexTable.Cell>
                <IndexTable.Cell>{totalsAndMax ? commaDelimiter(totalsAndMax.total_ordered) : null}</IndexTable.Cell>
                <IndexTable.Cell />
                <IndexTable.Cell>{totalsAndMax ? commaDelimiter(totalsAndMax.total_sales) : null}</IndexTable.Cell>
                {forecastingMethod === TABS.sales_rate && (
                  <IndexTable.Cell>
                    {totalsAndMax && totalsAndMax.total_sales_pre_day !== null ? (
                      <NiceSalesRate row={totalsAndMax.total_sales_pre_day} />
                    ) : null}
                  </IndexTable.Cell>
                )}
                <IndexTable.Cell />
                <IndexTable.Cell />
              </IndexTable.Row>
              {salesTrends.map((variant, index) => (
                <IndexTable.Row id={`${index + 1}:str`} key={`row:${variant.horse_variant_id}`} position={index + 1}>
                  <IndexTable.Cell>
                    <HorseVariant variant={variant} />
                  </IndexTable.Cell>
                  <IndexTable.Cell>
                    <QuantityChangeModal
                      activatorText={variant.tracked ? variant.currently_available || 0 : "∞"}
                      disabled={variant.discarded}
                      horseVariantId={variant.horse_variant_id}
                      setErrorMessage={setErrorMessage}
                      tracked={variant.tracked}
                    />
                  </IndexTable.Cell>
                  <IndexTable.Cell>
                    {variant.discarded ? (
                      <Text as="p" tone="subdued">
                        -
                      </Text>
                    ) : variant.ordered > 0 ? (
                      <LinkText link={`/purchase_orders?search=${variant.variant_title || variant.product_title}`}>
                        <Text as="p" truncate>
                          {variant.ordered}
                        </Text>
                      </LinkText>
                    ) : (
                      variant.ordered
                    )}
                  </IndexTable.Cell>
                  <IndexTable.Cell>
                    <Tooltip
                      active={false}
                      content={`${
                        forecastingMethod === TABS.seasonal ? "Last year, t" : "T"
                      }he variant was available for purchase during ${
                        variant.available_days
                      } of the last ${samplingPeriod} days.`}
                    >
                      <Text as="p" tone={variant.discarded ? "subdued" : undefined}>
                        {variant.available_days}
                      </Text>
                    </Tooltip>
                  </IndexTable.Cell>
                  <IndexTable.Cell>
                    <Tooltip
                      active={false}
                      content={
                        forecastingMethod === TABS.sales_rate
                          ? `During the sampling period, the variant sold ${variant.sales_quantity} units`
                          : `During the sampling period last year, the variant sold ${variant.sales_quantity} units`
                      }
                    >
                      <Text as="p" tone={variant.discarded ? "subdued" : undefined}>
                        {variant.sales_quantity}
                      </Text>
                    </Tooltip>
                  </IndexTable.Cell>
                  {forecastingMethod === TABS.sales_rate && (
                    <IndexTable.Cell>
                      <NiceSalesRate row={variant} />
                    </IndexTable.Cell>
                  )}
                  <IndexTable.Cell>
                    <Tooltip
                      active={false}
                      content={`Given the variant's sales rate, it will take ${variant.estimated_days_of_sales_remaining} day(s) for the variant to sell out.`}
                    >
                      <Text as="p" tone={variant.discarded ? "subdued" : undefined}>
                        {variant.estimated_days_of_sales_remaining}
                      </Text>
                    </Tooltip>
                  </IndexTable.Cell>
                  <IndexTable.Cell>
                    <StockInfo sales_trends_horse_variant={variant} />
                  </IndexTable.Cell>
                </IndexTable.Row>
              ))}
            </IndexTable>
          </LegacyCard>
        </Layout.Section>
      </Layout>
      <Footer pageTitle="Sales trends" url={salesTrendsHelpPageUrl} />
    </Page>
  );

  return pageLoading ? <SalesTrendsIndexPageSkeleton /> : pageMarkup;
}
