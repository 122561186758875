import React from 'react';
import {
  Card,
  Layout,
  SkeletonBodyText,
  SkeletonPage,
} from '@shopify/polaris';

export const HorseVariantsIndexPageSkeleton = (): React.JSX.Element => (
  <SkeletonPage fullWidth primaryAction>
    <Layout>
      <Layout.Section>
        <Card>
          <SkeletonBodyText lines={5} />
        </Card>
      </Layout.Section>
    </Layout>
  </SkeletonPage>
);
