import React from 'react';
import {
  LegacyCard,
  LegacyStack,
  TextField,
  FormLayout,
  Label,
  TextContainer,
} from '@shopify/polaris';
import { assertNumber, assertString, commaDelimiter, softAssertNumber } from '../../../../helper_functions/utils';

const HUNDRED = 100.00;

const convertCMToMeters = (value) => value / HUNDRED;
const calculateVolumeInMeters = (
  height,
  width,
  length,
) => (convertCMToMeters(assertNumber(height)) * convertCMToMeters(assertNumber(width)) * convertCMToMeters(assertNumber(length)));

export const Weight = ({
  height,
  setHeight,
  width,
  setWidth,
  length,
  setLength,
  weight,
  setWeight,
  setIsDirty,
}: {
  height: number,
  weight: number,
  length: number,
  width: number,
  setHeight: (value: number) => void,
  setWidth: (value: number) => void,
  setLength: (value: number) => void,
  setWeight: (value: number) => void,
  setIsDirty: (value: boolean) => void,
}): JSX.Element => {
  const handleChange = (label) => (newValue: string) => {
    setIsDirty(true);
    switch (label) {
      case 'weight':
        return setWeight(softAssertNumber(newValue));
      case 'width':
        return setWidth(softAssertNumber(newValue));
      case 'depth':
        return setLength(softAssertNumber(newValue));
      case 'height':
        return setHeight(softAssertNumber(newValue));
      default:
        return 0;
    }
  };

  const volume = calculateVolumeInMeters(width, length, height);
  // Scale the number of decimal places to show based on the size of the number
  const decimalPlaces = Math.max(0, 6 - volume.toFixed(0).toString().length);

  return (
    <LegacyCard title="Weight and dimensions">
      <LegacyCard.Section>
        <FormLayout>
          <LegacyStack distribution="fillEvenly">
            <TextField
              autoComplete='off'
              label="Weight"
              type="number"
              value={assertString(weight)}
              suffix='g'
              onChange={handleChange('weight')}
            />
          </LegacyStack>
          <LegacyStack distribution="fillEvenly">
            <TextField
              autoComplete='off'
              label="Height"
              type="number"
              onChange={handleChange('height')}
              value={assertString(height)}
              suffix='cm'
            />
            <TextField
              autoComplete='off'
              label="Width"
              type="number"
              inputMode="numeric"
              value={assertString(width)}
              suffix='cm'
              onChange={handleChange('width')}
            />
            <TextField
              autoComplete='off'
              label="Depth"
              type="number"
              inputMode="numeric"
              value={assertString(length)}
              suffix='cm'
              onChange={handleChange('depth')}
            />
          </LegacyStack>
        </FormLayout>
      </LegacyCard.Section>
      <LegacyCard.Section subdued>
        <LegacyStack>
          <LegacyStack.Item fill>
            <Label id="horse_variant-size">Size</Label>
          </LegacyStack.Item>
          <LegacyStack.Item>
            <TextContainer>
              {commaDelimiter(volume, decimalPlaces)}&#13221;
            </TextContainer>
          </LegacyStack.Item>
        </LegacyStack>
      </LegacyCard.Section>
    </LegacyCard>
  );
};
