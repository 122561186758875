import React, { useState, useCallback } from "react";
import { Page, LegacyCard, IndexTable, Pagination, Button, EmptyState, LegacyStack } from "@shopify/polaris";
import qs from "query-string";
import { formatMoney, commaDelimiter, assertNumber } from "../../../helper_functions/utils";
import { TransferOrdersSearchFieldAndMoreFilters } from "./TransferOrdersSearchFieldAndMoreFilters";
import { useTransferOrders, useUser } from "../../../api_utils/requests";
import { Footer } from "../../common/Footer";
import { TransferOrdersIndexPageSkeleton } from "../../common/skeletons";
import type { QueryParams } from "../../../api_utils/types";
import type { NonEmptyArray } from "@shopify/polaris/build/ts/src/types";
import type { IndexTableHeading } from "@shopify/polaris/build/ts/src/components/IndexTable";
import { styledDivElement } from "../../../helper_functions/component_utils";

const statusColor = (status: string): string => {
  if (status === "Received") {
    return "rgba(174, 233, 209, 1)";
  } else if (status === "Sent") {
    return "rgba(164, 232, 242, 1)";
  } else {
    return "rgba(228, 229, 231, 1)";
  }
};

const columnNames = [
  { title: "Label" },
  { title: "Status" },
  { title: "Origin" },
  { title: "Destination" },
  { title: "Sent date" },
  { title: "Arrival date" },
  { title: "Quantity" },
  { title: "Value" },
] satisfies NonEmptyArray<IndexTableHeading>;

const TABS = {
  ALL: 0,
  DRAFT: 1,
  SENT: 2,
  CLOSED: 3,
};

const COLLECTIONS = {
  [TABS.ALL]: "all",
  [TABS.DRAFT]: "draft",
  [TABS.SENT]: "sent",
  [TABS.CLOSED]: "closed",
};

const transferOrdersHelpPageUrl =
  "https://horse-inventory.notion.site/Transfer-orders-directory-9e1dade93d764162830142886dc77ee6";
const pageTitle = "Transfer orders";

function TransferOrdersIndex(): React.ReactElement {
  const parsedUrlSearch = qs.parse(window.location.search);
  const [pageNum, setPageNum] = useState(Number(parsedUrlSearch.page) || 1);
  const [collection, setCollection] = useState<number>(
    parsedUrlSearch.by_state ? TABS[assertNumber(parsedUrlSearch.by_state as string)] : TABS.ALL,
  );
  const [filters, setFilters] = useState<QueryParams>({});

  const {
    data: {
      user: { currency: userCurrency },
    },
    isLoading: pageLoading,
  } = useUser();

  const {
    data: { rows: transferOrders, total_quantity_sent: totalQuantitySent, total_value: totalValue, hasNext, hasPrev },
    isFetching,
  } = useTransferOrders({
    ...filters,
    by_state: COLLECTIONS[collection] === COLLECTIONS[TABS.ALL] ? undefined : COLLECTIONS[collection],
    page: pageNum,
  });

  const isLoading = isFetching;

  const setPageNumAndScroll = useCallback((newPageNum: number) => {
    setPageNum(newPageNum);
    document.getElementsByClassName("Polaris-IndexTable__Table")[0]?.scrollIntoView();
  }, []);
  const nextPage = useCallback(() => {
    if (hasNext) {
      setPageNumAndScroll(pageNum + 1);
    }
  }, [hasNext, pageNum, setPageNumAndScroll]);
  const prevPage = useCallback(() => {
    if (hasPrev) {
      setPageNumAndScroll(pageNum - 1);
    }
  }, [hasPrev, pageNum, setPageNumAndScroll]);

  const emptyState = (
    <EmptyState
      action={{
        content: "Create transfer order",
        url: "/transfer_orders/new",
      }}
      heading="No transfer orders created yet"
      image=""
    />
  );

  const setFiltersAndResetPageNum = useCallback((newFilters: QueryParams) => {
    setFilters(newFilters);
    setPageNum(1);
  }, []);

  const setCollectionAndResetPageNum = useCallback((newCollection) => {
    setCollection(newCollection);
    setPageNum(1);
  }, []);

  const pageMarkup = (
    <Page
      fullWidth
      primaryAction={{
        content: "Create transfer order",
        url: "/transfer_orders/new",
      }}
      title={pageTitle}
    >
      <LegacyCard>
        <TransferOrdersSearchFieldAndMoreFilters
          onSelect={setCollectionAndResetPageNum}
          selected={collection}
          setFilters={setFiltersAndResetPageNum}
        />
        <IndexTable
          emptyState={emptyState}
          headings={columnNames}
          itemCount={transferOrders.length}
          loading={isLoading}
          selectable={false}
        >
          <IndexTable.Row id="transferOrdersIndexTotalsRow" key={0} position={0} rowType="subheader">
            <IndexTable.Cell>Totals</IndexTable.Cell>
            <IndexTable.Cell />
            <IndexTable.Cell />
            <IndexTable.Cell />
            <IndexTable.Cell />
            <IndexTable.Cell />
            <IndexTable.Cell>{commaDelimiter(totalQuantitySent)}</IndexTable.Cell>
            <IndexTable.Cell>{formatMoney(totalValue, userCurrency)}</IndexTable.Cell>
          </IndexTable.Row>
          {transferOrders.map((transferOrder, index) => (
            <IndexTable.Row id={transferOrder.id.toString()} key={transferOrder.id} position={index + 1}>
              <IndexTable.Cell>
                <Button url={`/transfer_orders/${transferOrder.id}`} variant="plain">
                  {transferOrder.label || transferOrder.id.toString()}
                </Button>
              </IndexTable.Cell>
              <IndexTable.Cell>
                {styledDivElement(statusColor(transferOrder.status), transferOrder.status)}
              </IndexTable.Cell>
              <IndexTable.Cell>{transferOrder.origin.name}</IndexTable.Cell>
              <IndexTable.Cell>{transferOrder.destination.name}</IndexTable.Cell>
              <IndexTable.Cell>{transferOrder.sent}</IndexTable.Cell>
              <IndexTable.Cell>{transferOrder.received}</IndexTable.Cell>
              <IndexTable.Cell>{commaDelimiter(transferOrder.quantity)}</IndexTable.Cell>
              <IndexTable.Cell>{formatMoney(transferOrder.value, userCurrency)}</IndexTable.Cell>
            </IndexTable.Row>
          ))}
        </IndexTable>
        <LegacyCard.Section>
          <LegacyStack alignment="center" distribution="center">
            <Pagination
              hasNext={hasNext}
              hasPrevious={hasPrev}
              nextKeys={[39]}
              onNext={nextPage}
              onPrevious={prevPage}
              previousKeys={[37]}
            />
          </LegacyStack>
        </LegacyCard.Section>
      </LegacyCard>
      <Footer pageTitle="transfer orders" url={transferOrdersHelpPageUrl} />
    </Page>
  );

  return pageLoading ? <TransferOrdersIndexPageSkeleton /> : pageMarkup;
}

export default TransferOrdersIndex;
