import React, { useState } from 'react';
import {
  Page,
  AppProvider,
  LegacyCard,
  Text,
  Button,
  LegacyStack,
  TextField,
  FormLayout,
} from '@shopify/polaris';
import enTranslations from '@shopify/polaris/locales/en.json';
import logo from 'images/horse-logo-110.png';
import textLogo from 'images/text-440.png';
import bg from 'images/email_images/bg1.svg';

// 340 X 260
function Login(): React.JSX.Element {
  const [domain, setDomain] = useState('');

  return (
    <AppProvider
      i18n={enTranslations}>
      <div className='loginCenter'>
        <div className='background' style={{
          background: `url(${bg})`,
          backgroundSize: 'cover',
        }} />
        <Page>
          <LegacyCard sectioned>
            <LegacyStack alignment='center'distribution='center'>
              <img height="70px" src={logo}/>
              <img height='35px' src={textLogo} />
            </LegacyStack>
            <form action='/login'>
              <Text visuallyHidden>
                <TextField name="top_level" value="true" />
                <TextField name="return_to" value="/home" />
              </Text>
              <Text as="h2" variant="headingMd">Login</Text>
              <Text variation="subdued">Continue to Horse</Text>
              <FormLayout>
                <FormLayout.Group>
                  <div className='input-placeholder-right'>
                    <TextField name='shop' onChange={setDomain} placeholder='.myshopify.com' value={domain} />
                    <span className='helperText'>Shopify store domain name</span>
                  </div>
                </FormLayout.Group>
                <FormLayout.Group>
                  <Button fullWidth primary submit>Next</Button>
                </FormLayout.Group>
              </FormLayout>
            </form>
          </LegacyCard>
        </Page>
      </div>
    </AppProvider>
  );
}

export default Login;
