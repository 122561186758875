import React from 'react';
import {
  LegacyStack,
  TextField,
} from '@shopify/polaris';
import type { DecimalRangeFilter} from '../../../api_utils/types';

export default function MoreLessFilter({
  prefix,
  suffix,
  more_than,
  less_than,
  targetState,
  setStateCallback,
  type,
  align,
}: {
  readonly prefix?: string;
  readonly suffix?: string;
  // Must be strings to support decimals!
  readonly more_than?: string;
  readonly less_than?: string;
  readonly targetState: DecimalRangeFilter;
  readonly setStateCallback: (state: DecimalRangeFilter) => void;
  readonly type?: 'currency' | 'integer' | 'number';
  readonly align?: 'center' | 'left' | 'right';
}): React.ReactElement {
  return <LegacyStack distribution="fill" wrap={false}>
    <LegacyStack alignment="center" distribution="fill" spacing="extraTight" wrap={false}>
      <TextField
        align={align || 'left'}
        autoComplete="off"
        inputMode="numeric"
        label="More than"
        onChange={(value: string) => { setStateCallback({ less_than: targetState.less_than, more_than: value }); }}
        prefix={prefix}
        suffix={suffix}
        type={type}
        value={more_than}
      />
    </LegacyStack>
    <LegacyStack alignment="center" distribution="fill" spacing="extraTight" wrap={false}>
      <TextField
        align={align || 'left'}
        autoComplete="off"
        inputMode="numeric"
        label="Less than"
        onChange={(value: string) => { setStateCallback({ less_than: value, more_than: targetState.more_than }); }}
        prefix={prefix}
        suffix={suffix}
        type={type}
        value={less_than}
      />
    </LegacyStack>
  </LegacyStack>;
};
