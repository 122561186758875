import React, { useState, useCallback } from "react";
import { Card, EmptyState, IndexTable, Layout, Link, Page, SkeletonBodyText } from "@shopify/polaris";
import { downloadFile, importBundles, useBundles } from "../../api_utils/requests";
import { Footer } from "../common/Footer";
import type { NonEmptyArray } from "@shopify/polaris/build/ts/src/types";
import type { IndexTableHeading } from "@shopify/polaris/build/ts/src/components/IndexTable";
import { ExportMinor, ImportMinor } from "@shopify/polaris-icons";
import Toast from "../common/Toast";
import { ImportFile } from "../common/ImportFile";
import { ErrorBanner } from "../common/ErrorBanner";

const columnNames: NonEmptyArray<IndexTableHeading> = [{ title: "Name" }];
const bundlesHelpPageUrl = "https://horse-inventory.notion.site/Bundles-88ac584381a445de890d4f68870d5920";
const layoutSkeleton = (
  <Card>
    <SkeletonBodyText lines={5} />
  </Card>
);

function BundlesIndex(): React.ReactElement {
  const urlParams = new URLSearchParams(window.location.search);
  const [pageNum, setPageNum] = useState(Number(urlParams.get("page")) || 1);

  const {
    data: { rows, hasNext, hasPrev },
    isFetching,
    refetch,
  } = useBundles(
    { page: pageNum },
    {
      refetchOnMount: "always",
    },
  );

  const setPageNumAndScroll = useCallback((newPageNum: number) => {
    setPageNum(newPageNum);
    document.getElementsByClassName("Polaris-IndexTable__Table")[0]?.scrollIntoView();
  }, []);

  const nextPage = useCallback(() => {
    if (hasNext) {
      setPageNumAndScroll(pageNum + 1);
    }
  }, [hasNext, pageNum, setPageNumAndScroll]);
  const prevPage = useCallback(() => {
    if (hasPrev) {
      setPageNumAndScroll(pageNum - 1);
    }
  }, [hasPrev, pageNum, setPageNumAndScroll]);

  const emptyState = (
    <EmptyState
      action={{
        content: "Create bundle",
        url: "/bundles/new",
      }}
      heading="No bundles created yet"
      image=""
    />
  );

  const layoutMarkup = (
    <>
      <Card padding="0">
        <IndexTable
          emptyState={emptyState}
          headings={columnNames}
          itemCount={rows.length || 0}
          pagination={{
            hasPrevious: hasPrev,
            previousKeys: [37],
            onPrevious: prevPage,
            hasNext: hasNext,
            nextKeys: [39],
            onNext: nextPage,
          }}
          resourceName={{ singular: "Bundle", plural: "Bundles" }}
          selectable={false}
        >
          {rows.map(({ id, name }, index) => (
            <IndexTable.Row id={id.toString()} key={id} position={index}>
              <IndexTable.Cell>
                <Link url={`/bundles/${id}`}>{name}</Link>
              </IndexTable.Cell>
            </IndexTable.Row>
          ))}
        </IndexTable>
      </Card>
      <Footer pageTitle="bundles" url={bundlesHelpPageUrl} />
    </>
  );

  const [errorMessage, setErrorMessage] = useState("");
  const [toastMessage, setToastMessage] = useState<string>(null);
  const [importCSVModalIsOpen, setImportCSVModalIsOpen] = useState(false);

  const openImportCSVModal = useCallback(() => {
    setImportCSVModalIsOpen(true);
  }, []);

  const actionGroups = [
    {
      title: "CSV",
      actions: [
        {
          content: "Export",
          onAction: async (): Promise<void> => {
            const filename = `Horse bundles.csv`;
            await downloadFile("/bundles.csv", filename);
          },
          icon: ExportMinor,
        },
        {
          content: "Import",
          onAction: openImportCSVModal,
          icon: ImportMinor,
        },
      ],
    },
  ];

  return (
    <Page
      actionGroups={actionGroups}
      primaryAction={{
        content: "Create Bundle",
        url: "/bundles/new",
        disabled: isFetching,
      }}
      title="Bundles"
    >
      <Toast setToastMessage={setToastMessage} toastMessage={toastMessage} />
      <ImportFile
        apiCallMethod={importBundles}
        modalIsOpen={importCSVModalIsOpen}
        setApiResponse={refetch}
        setErrorMessage={async (importErrorMessage: string): Promise<void> => {
          setErrorMessage(importErrorMessage);
          await refetch();
        }}
        setModalIsOpen={setImportCSVModalIsOpen}
        setToastMessage={setToastMessage}
        title="Import variants CSV"
      />
      <Layout>
        {errorMessage ? (
          <Layout.Section>
            <ErrorBanner errorMessage={errorMessage} setErrorMessage={setErrorMessage} />
          </Layout.Section>
        ) : null}
        <Layout.Section>{isFetching ? layoutSkeleton : layoutMarkup}</Layout.Section>
      </Layout>
    </Page>
  );
}

export default BundlesIndex;
