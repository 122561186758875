import React, { useEffect } from "react";
import enTranslations from "@shopify/polaris/locales/en.json";
import { AppProvider } from "@shopify/polaris";
import { BrowserRouter } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import HorseNavigationMenu from "./support/navigation_menu";
import { AppBridgeProvider } from "./support/AppBridgeProvider";
import AppBridgeLink from "./support/AppBridgeLink";
import Routes from "./support/Routes";
import Login from "../sessions/new/New";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 1000 * 60 * 1,
      refetchOnMount: false,
    },
  }
});

function ApplicationLayout(): React.ReactElement {
  // This method is used to scroll the table horizontally to the right
  useEffect(function handleScrollHorizontal() {
    const scrollContainers = document.querySelectorAll(".Polaris-IndexTable-ScrollContainer");
    if (scrollContainers[0]) {
      scrollContainers.forEach((scrollContainer) => { scrollContainer.scrollTo(window.innerWidth, window.innerHeight); });
    }
  }, []);

  if (window.location.pathname === "/login") {
    return <Login />
  } else {
    return (
      <AppProvider i18n={enTranslations} linkComponent={AppBridgeLink}>
        <BrowserRouter>
          <AppBridgeProvider>
            <QueryClientProvider client={queryClient}>
              <HorseNavigationMenu />
              <Routes />
            </QueryClientProvider>
          </AppBridgeProvider>
        </BrowserRouter>
      </AppProvider>
    );
  }

}

export default ApplicationLayout;
