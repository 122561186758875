import React from 'react';
import {
  LegacyCard,
  ResourceList,
  ResourceItem,
  Avatar,
  Text,
  LegacyStack,
  Link,
} from '@shopify/polaris';
import placeholderImg from 'images/placeholder.png';

export function LinkedLocations({ linkedLocations }: {
  readonly linkedLocations: {
    id: string | number,
    name: string,
    shopifyDomain: string,
  }[],
}): React.JSX.Element {
  const renderLinkedLocationsItem = (shopifyLocation) => {
    const shopLocationSettingsPath = `/settings/locations/${shopifyLocation.shopify_location_id}`;
    return (
      <ResourceItem
        external
        id={shopifyLocation.id}
        key={`${shopifyLocation.id}-linked-location`}
        media={<Avatar customer={false} size='lg' source={placeholderImg} />}
        name={shopifyLocation.name}
        url={shopLocationSettingsPath}
        verticalAlignment="center"
      >
        <LegacyStack distribution="equalSpacing">
          <LegacyStack.Item>
            <Text as="strong">
              <Link
                className='blue-link'
                monochrome
                removeUnderline
                target="_blank">
                {shopifyLocation.name}
              </Link>
            </Text>
            <div>
              {shopifyLocation.shopifyDomain}
            </div>
          </LegacyStack.Item>
        </LegacyStack>
      </ResourceItem>
    );
  };

  return (
    <LegacyCard title="Linked locations">
      {linkedLocations?.length ? (
        <ResourceList
          items={linkedLocations}
          renderItem={renderLinkedLocationsItem}
        />
      ) : (
        <LegacyCard.Section>
          <h4>
            <Text tone="subdued">
              No linked locations
            </Text>
          </h4>
        </LegacyCard.Section>
      )}
    </LegacyCard>
  );
}
