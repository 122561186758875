import React from 'react';
import {
  LegacyCard,
  Select,
} from '@shopify/polaris';

export const CurrencyChange = ({ currencies, handleCurrencyChange, currency }: {
  currencies: string[],
  handleCurrencyChange: (value: string) => void,
  currency: string,
}): React.JSX.Element => (
  <LegacyCard>
    <LegacyCard.Header title="Select currency"/>
    <LegacyCard.Section>
      <Select
        id='currencies'
        options={currencies}
        onChange={handleCurrencyChange}
        value={currency}
        helpText="The only options in this menu are the currencies from your store(s)."
        label=''
        labelHidden
      />
    </LegacyCard.Section>
  </LegacyCard>
);
