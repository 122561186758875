import React, {
  useCallback,
  useEffect,
  useState,
} from 'react';
import {
  LegacyCard,
  EmptyState,
  IndexTable,
  Link,
  Page,
  Pagination,
  LegacyStack,
} from '@shopify/polaris';
import qs from 'query-string';
import { getSuppliers } from '../../api_utils/requests';
import { Footer } from '../common/Footer';
import { SuppliersIndexPageSkeleton } from '../common/skeletons';
import { SUPPLIERS_HELP_PAGE_URL } from '../../constants';
import type { NonEmptyArray } from '@shopify/polaris/build/ts/src/types';
import type { IndexTableHeading } from '@shopify/polaris/build/ts/src/components/IndexTable';

const columnNames = [{ title: 'Name' }] satisfies NonEmptyArray<IndexTableHeading>;

function SuppliersIndex() {
  const [pageLoading, setPageLoading] = useState(true);
  const [suppliers, setSuppliers] = useState([]);
  const parsedUrlSearch = qs.parse(window.location.search);
  const [pageNum, setPageNum] = useState(Number(parsedUrlSearch.page) || 1);
  const [hasNext, setHasNext] = useState(false);
  const [hasPrev, setHasPrev] = useState(false);

  useEffect(() => {
    getSuppliers({ page: pageNum}).then((suppliers) => {
      setSuppliers(suppliers.rows);
      setHasNext(suppliers.hasNext);
      setHasPrev(suppliers.hasPrev);
      setPageLoading(false);
    })
  }, [pageNum]);

  const setPageNumAndScroll = useCallback((newPageNum: number) => {
    setPageNum(newPageNum);
    document.getElementsByClassName('Polaris-IndexTable__Table')[0]?.scrollIntoView();
  }, []);
  const nextPage = useCallback(() => {
    if (hasNext) {
      setPageNumAndScroll(pageNum + 1);
    }
  }, [hasNext, pageNum, setPageNumAndScroll]);
  const prevPage = useCallback(() => {
    if (hasPrev) {
      setPageNumAndScroll(pageNum - 1);
    }
  }, [hasPrev, pageNum, setPageNumAndScroll]);

  const emptyState = (<EmptyState
    action={{
      content: 'Create supplier',
      url: '/suppliers/new'
    }}
    heading='No suppliers created yet'
    image=''
   />);

  const pageMarkup = (
    <Page
      primaryAction={{
        content: 'Create supplier',
        url: '/suppliers/new'
      }}
      title='Suppliers'
    >
      <LegacyCard>
        <IndexTable
          emptyState={emptyState}
          headings={columnNames}
          itemCount={suppliers.length}
          resourceName={{singular: 'Supplier', plural: 'Suppliers'}}
          selectable={false}
        >
          {
            suppliers.map(
              (
                { id, name },
                index,
              ) => (
                <IndexTable.Row
                  id={id.toString()}
                  key={id}
                  position={index}
                >
                  <IndexTable.Cell>
                    <Link url={`/suppliers/${id}`}>{name}</Link>
                  </IndexTable.Cell>
                </IndexTable.Row>
              ),
            )
          }
        </IndexTable>
        <LegacyCard.Section>
          <LegacyStack alignment="center" distribution="center">
            {suppliers.length > 0 && (
              <Pagination
                hasNext={hasNext}
                hasPrevious={hasPrev}
                nextKeys={[39]}
                onNext={nextPage}
                onPrevious={prevPage}
                previousKeys={[37]}
              />
            )}
          </LegacyStack>
        </LegacyCard.Section>
      </LegacyCard>
      <Footer pageTitle="suppliers" url={SUPPLIERS_HELP_PAGE_URL} />
    </Page>
  );

  return pageLoading ? <SuppliersIndexPageSkeleton /> : pageMarkup;
}

export default SuppliersIndex;
