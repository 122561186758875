import React from 'react';
import {
  LegacyCard,
  TextField,
  FormLayout,
} from '@shopify/polaris';
import { assertString } from '../../../../helper_functions/utils';
import { IHorseVariant } from '../../../../api_utils/types';

export const Title = ({
  horseVariant
}: {
  horseVariant: IHorseVariant
}): JSX.Element => {
  return (
    <LegacyCard title='Titles' sectioned>
      <FormLayout>
          <TextField
            autoComplete='off'
            label='Product title'
            value={assertString(horseVariant.title)}
            type="text"
            disabled
          />
          <TextField
            autoComplete='off'
            label='Variant title'
            value={assertString(horseVariant.variant_title)}
            type='text'
            disabled
          />
      </FormLayout>
    </LegacyCard>
  );
};
