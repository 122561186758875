import React, { useEffect, useState } from "react";
import { LegacyCard, Link, LegacyStack } from "@shopify/polaris";
import shopifyLogoImage from "images/home/shopify_logo_black.png";
import starImage from "images/home/star.png";
import { getReadBanner, createReadBanner } from "../../../api_utils/requests";

function ReviewBlock() {
  const [alreadySeen, setAlreadySeen] = useState(true);
  useEffect(() => {
    const fetchBanner = async () => {
      getReadBanner("ReviewBlock").catch((e: unknown) => {
        if (e instanceof Error && e.message.includes("404")) {
          setAlreadySeen(false);
        }
      });
    };
    fetchBanner();
  }, []);

  const markSeen = () => {
    createReadBanner("ReviewBlock");
    setAlreadySeen(true);
  };

  const horseReviewPageUrl = "https://apps.shopify.com/horse?st_source=autocomplete#reviews";
  return (
    !alreadySeen && (
      <LegacyCard actions={[{ content: "Dismiss", onAction: markSeen }]}>
        <LegacyCard.Section>
          <LegacyStack alignment="center" vertical>
            <div>
              <img src={shopifyLogoImage} />
            </div>
            <p>Would you consider leaving us a review?</p>
            <p>Your feedback means a lot to us! Please take a minute to leave us a review.</p>

            <Link target="_blank" url={horseReviewPageUrl}>
              <img src={starImage} />
              <img src={starImage} />
              <img src={starImage} />
              <img src={starImage} />
              <img src={starImage} />
            </Link>
          </LegacyStack>
        </LegacyCard.Section>
      </LegacyCard>
    )
  );
}

export default ReviewBlock;
