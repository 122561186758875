import React from "react";
import qs from "query-string";
import { LegacyStack, Text, IndexTable } from "@shopify/polaris";
import placeholderImg from "images/placeholder.png";
import { formatMoney } from "../../../helper_functions/utils";
import { QuantityChangeModal } from "../QuantityChangeModal";
import { TruncatedText } from "./TruncatedText";
import { LinkText } from "./LinkText";
import type { IHorseVariant } from "../../../api_utils/types";
import { Link } from "react-router-dom";

export function HorseVariantRow({
  horseVariant,
  userCurrency,
  setErrorMessage,
  position,
}: {
  readonly horseVariant: IHorseVariant & { value: number };
  readonly userCurrency: string;
  readonly setErrorMessage: (errorMessage: string) => void;
  readonly position: number;
}): React.ReactElement {
  const isOnTimeMachineView = !!qs.parse(window.location.search).date;

  const {
    id,
    image_src,
    product_title,
    variant_title,
    sku,
    vendor,
    product_type,
    currently_available,
    weight,
    cost,
    value,
    discarded,
    tracked,
  } = horseVariant;

  const column_color = discarded ? "rgba(142,147,152)" : "";

  let imgSrc = placeholderImg;
  if (image_src) {
    imgSrc = `${image_src}&height=58`;
  }

  const col0Markup = (
    <div className="horse-variant" style={{ overflow: "hidden" }}>
      <LegacyStack alignment="center" distribution="leading" key={id} spacing="tight" wrap={false}>
        <LegacyStack.Item>
          <span className="Polaris-Thumbnail">
            <img alt={`${product_title} variant`} className="product-photo" loading="lazy" src={imgSrc} />
          </span>
          <div id="PolarisPortalsContainer" />
        </LegacyStack.Item>

        <LegacyStack.Item>
          <TruncatedText maxLength={25} text={product_title}>
            <LinkText link={`/horse_variants/${id}`}>
              <Text as="span" truncate>
                {product_title}
              </Text>
            </LinkText>
          </TruncatedText>
        </LegacyStack.Item>
      </LegacyStack>
    </div>
  );

  const col1Markup = (
    <Link className="Polaris-Link Polaris-Link--removeUnderline" to={`/horse_variants/${id}`}>
      {variant_title}
    </Link>
  );

  const col2Markup = (
    <Link className="Polaris-Link Polaris-Link--removeUnderline" to={`/horse_variants/${id}`}>
      {sku}
    </Link>
  );

  const col3Markup = (
    <span className="truncate" style={{ color: column_color }}>
      {vendor}
    </span>
  );

  const col4Markup = (
    <span className="truncate" style={{ color: column_color }}>
      {product_type}
    </span>
  );

  const available_display: string = tracked ? (currently_available || 0).toString() : "∞";

  const col5Markup = isOnTimeMachineView ? (
    available_display
  ) : (
    <QuantityChangeModal
      activatorText={available_display}
      disabled={discarded}
      horseVariantId={id}
      key={`qcm:${id}`}
      setErrorMessage={setErrorMessage}
      tracked={tracked}
    />
  );

  const col6Markup = <span style={{ color: column_color }}>{`${weight}g`}</span>;

  const col8Markup = <span style={{ color: column_color }}>{formatMoney(cost, userCurrency, 2)}</span>;

  const col9Markup = <span style={{ color: column_color }}>{formatMoney(value, userCurrency, 2)}</span>;

  return (
    <IndexTable.Row id={(position + 1).toString()} key={`row:${horseVariant.id}`} position={position}>
      <IndexTable.Cell key={`cell:${position}:0`}>{col0Markup}</IndexTable.Cell>
      <IndexTable.Cell key={`cell:${position}:1`}>{col1Markup}</IndexTable.Cell>
      <IndexTable.Cell key={`cell:${position}:2`}>{col2Markup}</IndexTable.Cell>
      <IndexTable.Cell key={`cell:${position}:3`}>{col3Markup}</IndexTable.Cell>
      <IndexTable.Cell key={`cell:${position}:4`}>{col4Markup}</IndexTable.Cell>
      <IndexTable.Cell key={`cell:${position}:5`}>{col5Markup}</IndexTable.Cell>
      <IndexTable.Cell key={`cell:${position}:6`}>{col6Markup}</IndexTable.Cell>
      <IndexTable.Cell key={`cell:${position}:8`}>{col8Markup}</IndexTable.Cell>
      <IndexTable.Cell key={`cell:${position}:9`}>{col9Markup}</IndexTable.Cell>
    </IndexTable.Row>
  );
}
