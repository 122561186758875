
import type {
  Range} from '@shopify/polaris';
import {
  TextField, Icon, Popover, DatePicker, LegacyStack, Tooltip
} from '@shopify/polaris';
import React, { useState, useCallback, useMemo } from 'react';
import { CalendarMajor } from '@shopify/polaris-icons';
import { formatDateStringToMMDYY, MONTHS } from './utils';
import type { DateRangeFilter } from '../../../api_utils/types';
import { datePlaceholder } from '../../../helper_functions/utils';

const getCurrentMonthAndYear = (date: Date): {
  month: number,
  year: number,
} => {
  const dateParts = date.toDateString().split(' ').slice(1);
  return { month: (Number(MONTHS[dateParts[0]]) - 1), year: Number(dateParts[2]) };
};

function CustomRangePicker({
  leftLabel,
  rightLabel,
  dateRange,
  setDateRange,
  disabled,
  formatDisplay,
  tooltipContent,
  firstSelectableDate,
  leftHelpText,
  rightHelpText,
}: {
  readonly leftLabel?: string | React.ReactNode,
  readonly rightLabel?: string | React.ReactNode,
  readonly dateRange: DateRangeFilter,
  readonly setDateRange: (dateRange: DateRangeFilter) => void,
  readonly disabled?: boolean,
  readonly formatDisplay?: (dateRange: DateRangeFilter) => string,
  readonly tooltipContent?: string,
  readonly firstSelectableDate?: Date,
  readonly leftHelpText?: string,
  readonly rightHelpText?: string,
}): React.JSX.Element {
  const [open, setOpen] = useState(false);
  const curDateMonth = useMemo(() => new Date(), []);
  const [{ month, year }, setPickerDate] = useState(getCurrentMonthAndYear(dateRange.start || curDateMonth));
  const placeholder = datePlaceholder(curDateMonth);

  const handlePickerChange = useCallback((month, year) => {
    setPickerDate({ month, year });
  }, []);

  const handleDateChange = (newDateRange: DateRangeFilter): void => {
    if (newDateRange.start && newDateRange.end && newDateRange.start < newDateRange.end) {
      setOpen(false);
    }
    setDateRange(newDateRange);
  };

  const handleOpen = useCallback(() => { setOpen(true); }, []);
  const handleClose = (): void => {
    if (dateRange.start === dateRange.end) {
      const newEnd = new Date(dateRange.start.valueOf());
      newEnd.setDate(dateRange.start.getDate() + 30);
      setDateRange({ start: dateRange.start, end: newEnd });
    }
    setOpen(false);
  };

  const displayStartDate = formatDisplay ? formatDisplay(dateRange) : formatDateStringToMMDYY(dateRange.start);
  const displayEndDate = formatDisplay ? formatDisplay(dateRange) : formatDateStringToMMDYY(dateRange.end);

  const renderRightDate = (): React.JSX.Element => {
    const rightDate = (<LegacyStack.Item>
      <TextField
        autoComplete='off'
        disabled={disabled}
        helpText={rightHelpText}
        label={rightLabel}
        onFocus={handleOpen}
        placeholder={placeholder}
        prefix={<Icon source={CalendarMajor} tone="base" />}
        type="text"
        value={displayEndDate}
      />
    </LegacyStack.Item>);

    if (tooltipContent) {
      return <Tooltip active={false} content={tooltipContent} >
        {rightDate}
      </Tooltip>;
    } else {
      return rightDate;
    }
  };

  return (<div data-polaris-layer>
    <Popover
      activator={
          <LegacyStack distribution='fill'>
            <LegacyStack.Item>
              <TextField
                autoComplete='off'
                disabled={disabled}
                helpText={leftHelpText}
                label={leftLabel}
                onFocus={handleOpen}
                placeholder={placeholder}
                prefix={<Icon source={CalendarMajor} tone="base" />}
                type="text"
                value={displayStartDate}
              />
            </LegacyStack.Item>
            {renderRightDate()}
          </LegacyStack>
      }
      active={open}
      fluidContent
      onClose={handleClose}
      sectioned
      zIndexOverride={1400}>
        {
          !disabled && (<Popover.Pane>
            <Popover.Section>
              <div style={{width: '500px'}}>
                <DatePicker
                  allowRange
                  disableDatesBefore={ firstSelectableDate }
                  month={month}
                  multiMonth
                  onChange={handleDateChange}
                  onMonthChange={handlePickerChange}
                  selected={dateRange.start ? dateRange as Range : undefined}
                  year={year}
                />
              </div>
            </Popover.Section>
        </Popover.Pane>)
        }
    </Popover>
  </div>);
}

export default CustomRangePicker;
