import { Badge, Tooltip } from "@shopify/polaris";
import type { Tone } from "@shopify/polaris/build/ts/src/components/Badge";
import React from "react";

type TooltipContent =
  "Never available" | "No sales" | "Out of stock quite soon" | "Out of stock soon" | "Out of stock" | "Overstock";

const getTooltipContent = (text: TooltipContent): string => {
  let tooltipContent: string;
  if (text === "No sales") {
    tooltipContent = "This variant has sold 0 units during the sampling period.";
  } else if (text === "Never available") {
    tooltipContent = "This variant was never available for purchase during the sampling period.";
  } else if (text === "Overstock") {
    tooltipContent = "This variant has more than 180 days of inventory available.";
  } else if (text === "Out of stock") {
    tooltipContent = "This variant is already out of stock.";
  } else if (text === "Out of stock quite soon") {
    tooltipContent = "This variant will sell out within the next 30 days.";
  } else if (text === "Out of stock soon") {
    tooltipContent = "This variant will sell out within the next 60 days.";
  }
  return tooltipContent;
};

export default function StyledDivElementWithTooltip({ tone, text }: { readonly tone: Tone; readonly text: TooltipContent }): React.JSX.Element {
  return (
    <Tooltip content={getTooltipContent(text)}>
      <Badge tone={tone}>{text}</Badge>
    </Tooltip>
  );
}