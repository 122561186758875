import React from 'react';
import {
  BlockStack,
  Card,
  Layout,
  SkeletonBodyText,
  SkeletonDisplayText,
  SkeletonPage,
} from '@shopify/polaris';

export const SupplierShowPageSkeleton = (): React.JSX.Element => (
  <SkeletonPage primaryAction>
    <Layout>
      <Layout.Section>
        <Card>
          <BlockStack gap="400">
            <SkeletonDisplayText size="small" />
            <SkeletonBodyText lines={5} />
          </BlockStack>
        </Card>
      </Layout.Section>
      <Layout.Section>
        <Card>
          <BlockStack gap="400">
            <SkeletonDisplayText size="small" />
            <SkeletonBodyText lines={10} />
          </BlockStack>
        </Card>
      </Layout.Section>
    </Layout>
  </SkeletonPage>
);