import type { IPurchaseOrderLineItem } from "../../../api_utils/types";
import { assertNumber } from "../../../helper_functions/utils";

export const getTotal = (quantities: { number: IPurchaseOrderLineItem }) =>
  Object.values(quantities).reduce(
    (acc, poli) => {
      acc.totalCost += assertNumber(poli.quantity) * assertNumber(poli.cost);
      acc.cost += assertNumber(poli.cost);
      acc.quantity += assertNumber(poli.quantity);
      acc.duties += assertNumber(getDuties(poli));
      acc.received += assertNumber(poli.received);
      return acc;
    },
    {
      quantity: 0,
      cost: 0,
      totalCost: 0,
      duties: 0,
      received: 0,
    },
  );

export const getDuties = (variant: { duty_rate?: number; cost?: number }) => {
  const dutyRate = Number(variant.duty_rate || 0) / 100;
  const cost = Number(variant.cost || 0);
  return (dutyRate * cost).toFixed(2);
};

export const KEYS = {
  FORECASTING_METHOD: 1,
  SAMPLING_PERIOD: 2,
  ARRIVAL_AND_DEPLETION_DATE: 3,
  SALES_CHANNEL_IDS: 4,
};
