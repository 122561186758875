import React, { useMemo } from "react";
import {} from "@shopify/polaris";
import { useCurrencies, usePurchaseOrder } from "../../api_utils/requests";
import { getIdFromPath } from "../../helper_functions/utils";
import { PurchaseOrderDraftPageSkeleton } from "../common/skeletons";

import PurchaseOrderDraft from "./Draft";
import PurchaseOrderOrdered from "./Ordered";
import PurchaseOrderClosed from "./Closed";

function Show(): React.JSX.Element {
  const purchaseOrderId = useMemo(() => getIdFromPath(), []);

  const { data: currencies } = useCurrencies();
  const { data: purchaseOrderData, refetch, isLoading } = usePurchaseOrder(purchaseOrderId);
  const { purchaseOrder, lineItems } = purchaseOrderData || {};

  const renderPageContent = (): React.JSX.Element => {
    if (!purchaseOrder) return null;

    const { state } = purchaseOrder;
    const ComponentMap = {
      draft: PurchaseOrderDraft,
      ordered: PurchaseOrderOrdered,
      closed: PurchaseOrderClosed,
    };

    const SelectedComponent = ComponentMap[state];

    return SelectedComponent ? (
      <SelectedComponent
        currencies={currencies}
        lineItems={lineItems}
        purchaseOrder={purchaseOrder}
        refetch={refetch}
      />
    ) : null;
  };

  return isLoading ? <PurchaseOrderDraftPageSkeleton /> : renderPageContent();
}

export default Show;
