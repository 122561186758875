import React, { useState, useEffect, useCallback } from "react";
import {
  Modal,
  Button,
  ResourceList,
  ResourceItem,
  LegacyStack,
  Icon,
  Pagination,
  Link,
  FormLayout,
  EmptySearchResult,
} from "@shopify/polaris";
import { CirclePlusMajor, CircleMinusMajor } from "@shopify/polaris-icons";
import HorseVariant from "./HorseVariant/HorseVariant";
import { HorseVariantSearchFieldAndMoreFilters } from "../horse_variants/index/support/HorseVariantSearchFieldAndMoreFilters";
import type { DecimalRangeFilter, HorseVariantsQueryParams, IHorseVariant } from "../../api_utils/types";
import { Modal as ConfirmationModal } from "@shopify/app-bridge-react";
import { useHorseVariants } from "../../api_utils/requests";
import { extractMessageFromError, softAssertNumber } from "../../helper_functions/utils";
import Toast from "./Toast";

const emptyState = <EmptySearchResult title="No variants found" withIllustration />;

const defaultInitialQuantity: DecimalRangeFilter = { more_than: undefined, less_than: undefined };
const emptyArray: number[] = [];

export default function AddVariantDialog({
  active,
  handleVariantUpdate,
  initialHorseLocationId,
  initialQuantity = defaultInitialQuantity,
  initialSupplierId,
  initialVendor,
  primary = false,
  removeAllVariants,
  selected,
  selectionKey,
  setActive,
  setErrorMessage,
  updateSelection,
  excludableHorseVariantIds = emptyArray,
}: {
  readonly active: boolean;
  readonly handleVariantUpdate: (response: any) => void;
  readonly initialHorseLocationId?: number;
  readonly initialQuantity?: DecimalRangeFilter;
  readonly initialSupplierId?: number;
  readonly initialVendor?: string;
  readonly primary?: boolean;
  readonly removeAllVariants?: () => void;
  readonly selected?: number[];
  readonly setActive: (active: boolean) => void;
  readonly setErrorMessage: (errorMessage: string) => void;
  readonly selectionKey:
    | "bundle_horse_variants_attributes"
    | "purchase_order_line_items_attributes"
    | "supplier_horse_variants_attributes"
    | "transfer_order_horse_variants_attributes";
  readonly updateSelection: (payload: any, filters: any) => Promise<any>;
  readonly excludableHorseVariantIds?: number[];
}): React.JSX.Element {
  const [filters, setFilters] = useState<HorseVariantsQueryParams>({});
  const handleSetFilters = useCallback((newFilters: HorseVariantsQueryParams) => {
    // Filters changed so not all-all selected
    setAllItemsSelected(false);
    setFilters(newFilters);
    setPageNum(1);
  }, []);

  const [horseLocationId, setHorseLocationId] = useState<number>(initialHorseLocationId);
  const handleSetHorseLocationId = useCallback((newHorseLocationId?: number) => {
    setHorseLocationId(softAssertNumber(newHorseLocationId));
  }, []);
  const [selectedIds, setSelectedIds] = useState(new Set<number>(selected));
  const [allItemsSelected, setAllItemsSelected] = useState(false); // all variants for all pages
  const [pageNum, setPageNum] = useState(1);

  const [isConfirmOpen, setIsConfirmOpen] = useState(false);

  const [toastMessage, setToastMessage] = useState<string>(null);

  const {
    data: { rows, hasNext, hasPrev },
  } = useHorseVariants({ ...filters, page: pageNum });
  const horseVariants = rows.filter((horseVariant) => {
    return !excludableHorseVariantIds.includes(horseVariant.id);
  });
  const allSelectedOnCurrentPage = horseVariants.every((horseVariant) => selectedIds.has(Number(horseVariant.id)));

  useEffect(() => {
    if (selected.length !== selectedIds.size) {
      // Not all-all selected
      setAllItemsSelected(false);
    }
  }, [setAllItemsSelected, selected, selectedIds.size]);

  useEffect(() => {
    if (active) {
      const newSelectedIds = new Set([...selected]);
      setSelectedIds(newSelectedIds);
    }
  }, [active, selected, setSelectedIds]);

  const updateSelectionAPI = useCallback(
    async (payload): Promise<void> => {
      try {
        const response = await updateSelection(payload, filters);
        handleVariantUpdate(response);
      } catch (error: unknown) {
        Rollbar.error(error, payload);
        const newErrorMessage = extractMessageFromError(error);
        setErrorMessage(newErrorMessage);
      }
    },
    [updateSelection, filters, handleVariantUpdate, setErrorMessage],
  );

  const callUpdateSelection = useCallback(
    async (newSelectedIds: Set<number>): Promise<void> => {
      await updateSelectionAPI({
        [selectionKey]: Array.from(newSelectedIds).map((id) => ({ horse_variant_id: id })),
      });
    },
    [updateSelectionAPI, selectionKey],
  );

  const setPageNumAndScroll = useCallback((newPageNum: number) => {
    setPageNum(newPageNum);
    document.getElementById("variant-header")?.scrollIntoView();
  }, []);
  const nextPage = useCallback(() => {
    if (hasNext) {
      setPageNumAndScroll(pageNum + 1);
    }
  }, [hasNext, pageNum, setPageNumAndScroll]);
  const prevPage = useCallback(() => {
    if (hasPrev) {
      setPageNumAndScroll(pageNum - 1);
    }
  }, [hasPrev, pageNum, setPageNumAndScroll]);

  const handleAddAllOnCurrentPage = useCallback(async () => {
    const newSelectedIds = new Set<number>(selectedIds);
    horseVariants.forEach((horseVariant) => {
      newSelectedIds.add(Number(horseVariant.id));
    });
    setSelectedIds(newSelectedIds);
    // If we're adding all on this page, then all-all not selected
    setAllItemsSelected(false);
    await callUpdateSelection(newSelectedIds);
    setToastMessage(`${newSelectedIds.size - selectedIds.size} variants added`);
  }, [selectedIds, setSelectedIds, horseVariants, callUpdateSelection, setToastMessage, setAllItemsSelected]);

  const [variantIdsToRemove, setVariantIdsToRemove] = useState<string[]>([]);

  const handleRemoveAllOnCurrentPage = useCallback(() => {
    setVariantIdsToRemove(horseVariants.map(({ id }) => id.toString()));
    setIsConfirmOpen(true);
  }, [setIsConfirmOpen, setVariantIdsToRemove, horseVariants]);

  const handleRemoveAllVariants = useCallback(() => {
    setVariantIdsToRemove([]);
    setIsConfirmOpen(true);
  }, [setIsConfirmOpen, setVariantIdsToRemove]);

  const confirmationModalCallback = useCallback(async () => {
    let toastSuccessMessage: string;
    let newSelectedIds: Set<number>;
    if (variantIdsToRemove.length === 0) {
      newSelectedIds = new Set<number>();
      setSelectedIds(newSelectedIds);
      removeAllVariants();
      toastSuccessMessage = "All variants removed";
    } else {
      newSelectedIds = new Set(selectedIds);
      variantIdsToRemove.forEach((id: string) => {
        newSelectedIds.delete(Number(id));
      });
      setSelectedIds(newSelectedIds);
      toastSuccessMessage = `${variantIdsToRemove.length} variants removed`;
    }
    // We're removing, so all-all not selected
    setAllItemsSelected(false);
    setIsConfirmOpen(false);
    setVariantIdsToRemove([]);
    await callUpdateSelection(newSelectedIds);
    setToastMessage(toastSuccessMessage);
  }, [
    removeAllVariants,
    selectedIds,
    variantIdsToRemove,
    setIsConfirmOpen,
    setVariantIdsToRemove,
    callUpdateSelection,
    setToastMessage,
  ]);

  const handleAllVariantClick = useCallback(async () => {
    // Only after clicking the add-all button, we can be sure that all-all is not selected
    setAllItemsSelected(true);
    await updateSelectionAPI({ select_all: true });
  }, [updateSelectionAPI, setAllItemsSelected]);

  const handleRowSelectedRow = useCallback(
    async (variant: IHorseVariant) => {
      const newSelectedIds = new Set(selectedIds);
      let toastSuccessMessage: string;
      if (selectedIds.has(variant.id)) {
        newSelectedIds.delete(variant.id);
        // If anything is removed, then all-all not selected
        setAllItemsSelected(false);
        toastSuccessMessage = "Variant removed";
      } else {
        newSelectedIds.add(variant.id);
        toastSuccessMessage = "Variant added";
      }
      setSelectedIds(newSelectedIds);
      await callUpdateSelection(newSelectedIds);
      setToastMessage(toastSuccessMessage);
    },
    [selectedIds, setSelectedIds, setAllItemsSelected, callUpdateSelection, setToastMessage],
  );

  const addItem = useCallback(
    async (id: string) => {
      const idNumber = Number(id);
      const horseVariant = horseVariants.find((variant) => variant.id === idNumber);
      await handleRowSelectedRow(horseVariant);
    },
    [handleRowSelectedRow, horseVariants],
  );

  const renderItem = useCallback(
    (horseVariant: IHorseVariant) => {
      return (
        <ResourceItem
          accessibilityLabel={`View details for ${horseVariant.id}`}
          id={horseVariant.id.toString()}
          media={
            <div style={{ transform: "translateY(170%)" }}>
              {selectedIds.has(horseVariant.id) ? (
                <Icon source={CircleMinusMajor} />
              ) : (
                <Icon source={CirclePlusMajor} />
              )}
            </div>
          }
          onClick={addItem}
        >
          <HorseVariant disableClick showAvailable variant={horseVariant} />
        </ResourceItem>
      );
    },
    [selectedIds, addItem],
  );

  const handleOpen = useCallback(() => {
    setActive(true);
  }, [setActive]);
  const handleClose = useCallback(() => {
    setActive(false);
  }, [setActive]);
  const handleCloseConfirmation = useCallback(() => {
    setIsConfirmOpen(false);
  }, []);
  return (
    <Modal
      activator={
        <Button onClick={handleOpen} variant={primary ? "primary" : "secondary"}>
          Add variants
        </Button>
      }
      limitHeight
      onClose={handleClose}
      open={active}
      primaryAction={{
        content: "Close",
        onAction: handleClose,
      }}
      sectioned
      title="Add variants"
    >
      <ConfirmationModal
        message={
          variantIdsToRemove.length > 0
            ? `Are you sure that you want to remove ${variantIdsToRemove.length} variants?`
            : "Are you sure that you want to remove all variants?"
        }
        onClose={handleCloseConfirmation}
        open={isConfirmOpen}
        primaryAction={{
          content: "Remove",
          destructive: true,
          onAction: confirmationModalCallback,
        }}
        secondaryActions={[
          {
            content: "Cancel",
            onAction: handleCloseConfirmation,
          },
        ]}
        title="Confirm bulk removal"
      />
      <Modal.Section>
        <FormLayout>
          <FormLayout.Group>
            <HorseVariantSearchFieldAndMoreFilters
              disableTabs
              horseLocationId={horseLocationId}
              initialQuantity={initialQuantity}
              initialSupplierId={initialSupplierId}
              initialVendor={initialVendor}
              setFilters={handleSetFilters}
              setHorseLocationId={handleSetHorseLocationId}
            />
          </FormLayout.Group>
          <FormLayout.Group>
            <LegacyStack>
              <LegacyStack.Item>
                <div id="variant-header" style={{ marginLeft: "12px", cursor: "pointer" }}>
                  {allSelectedOnCurrentPage || allItemsSelected ? (
                    <div onClick={handleRemoveAllOnCurrentPage}>
                      <Icon source={CircleMinusMajor} />
                    </div>
                  ) : (
                    <div onClick={handleAddAllOnCurrentPage}>
                      <Icon source={CirclePlusMajor} />
                    </div>
                  )}
                </div>
              </LegacyStack.Item>
              <LegacyStack.Item>
                <div style={{ marginBottom: "15px" }}>
                  {allSelectedOnCurrentPage || allItemsSelected ? (
                    <Link onClick={allItemsSelected ? handleRemoveAllVariants : handleAllVariantClick} url="#">
                      {allItemsSelected ? "Remove all variants" : "Add all variants that match this search"}
                    </Link>
                  ) : (
                    `${selectedIds.size} added`
                  )}
                </div>
              </LegacyStack.Item>
            </LegacyStack>
          </FormLayout.Group>
        </FormLayout>
        <ResourceList
          emptyState={emptyState}
          items={horseVariants}
          renderItem={renderItem}
          resourceName={{
            singular: "variant",
            plural: "variants",
          }}
        />
      </Modal.Section>
      <Modal.Section>
        <LegacyStack alignment="center" distribution="center">
          <Pagination
            hasNext={hasNext}
            hasPrevious={hasPrev}
            nextKeys={[39]}
            onNext={nextPage}
            onPrevious={prevPage}
            previousKeys={[37]}
          />
        </LegacyStack>
      </Modal.Section>
      <Toast setToastMessage={setToastMessage} toastMessage={toastMessage} />
    </Modal>
  );
}
