import type { ErrorResponse } from "../api_utils/requests";
import type { DecimalRangeFilter, NumberRangeFilter } from "../api_utils/types";

// source: https://stackoverflow.com/a/40283265/7974948
export const cloneArrayOfObjects = <T>(arr: T[]): T[] => arr.map((obj) => ({ ...obj }));

export const datePlaceholder = (date: Date): string => {
  const year = date.getFullYear();
  const month = `0${date.getMonth()}`.slice(-2);
  const day = `0${date.getDate()}`.slice(-2);
  return `${year}-${month}-${day}`;
};

export const humanDateFormat = (date: Date, shortYear = false): string => {
  let year = date.getFullYear().toString();
  if (shortYear) {
    year = year.substr(-2);
  }
  const month = date.toLocaleString("default", { month: "short" });
  const day = `0${date.getDate()}`.slice(-2);
  const humanDate = `${month}. ${day}, ${year}`;
  return humanDate;
};

// Source: https://stackoverflow.com/a/1726662/7974948
// (returns string)
export const commaDelimiter = (num: number | string, decimals = 0): string => {
  return new Intl.NumberFormat("en-US", {
    minimumFractionDigits: decimals,
    maximumFractionDigits: decimals,
  }).format(assertNumber(num));
};

export const formatMoney = (num: number | string, userCurrency?: string, maximumFractionDigits = 4): string => {
  return new Intl.NumberFormat("en-US", {
    style: userCurrency ? "currency" : undefined,
    currency: userCurrency,
    minimumFractionDigits: 2,
    maximumFractionDigits,
  }).format(assertNumber(num));
};

export const roundToTwoDecimals = (num: number | string): number => {
  return Math.round((assertNumber(num) + Number.EPSILON) * 100) / 100;
};

export const getCurrencySymbol = (currency: string): string => {
  return (0)
    .toLocaleString(undefined, { style: "currency", currency, minimumFractionDigits: 0, maximumFractionDigits: 0 })
    .replace(/\d/g, "")
    .trim();
};

export const debounce = (func: () => any, wait: number): (() => void) => {
  let timeout;
  return (...args) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => func.apply(this, args), wait);
  };
};

export const findLineItemByBarcode = <T extends { barcode: string }>(
  lineItems: { number: T },
  barcode: string,
): T | undefined => Object.values(lineItems).find((item) => item.barcode === barcode);

export const addGlowAnimation = (selector: string): void => {
  const inputField = document.querySelector(selector);
  if (inputField) {
    inputField.classList.add("glow-animation");
    setTimeout(() => {
      inputField.classList.remove("glow-animation");
    }, 500);
  } else {
    console.warn(`No element found with the selector: ${selector}`);
  }
};

export const isEmpty = (value: string[] | number | object | string | null | undefined) => {
  if (Array.isArray(value)) {
    return value.length === 0;
  } else if (typeof value === "object") {
    return Object.keys(value).length === 0;
  } else {
    return value === "" || value == undefined || value == null;
  }
};

// https://stackoverflow.com/a/6713782
export const isEqual = (
  value: string[] | number | object | string | null | undefined,
  other: string[] | number | object | string | null | undefined,
): boolean => {
  if (value === other) return true;
  // if both value and other are null or undefined and exactly the same

  if (!(value instanceof Object) || !(other instanceof Object)) return false;
  // if they are not strictly equal, they both need to be Objects

  if (value.constructor !== other.constructor) return false;
  // they must have the exact same prototype chain, the closest we can do is
  // test there constructor.

  for (const p in value) {
    if (!value.hasOwnProperty(p)) continue;
    // other properties were tested using value.constructor === other.constructor

    if (!other.hasOwnProperty(p)) return false;
    // allows to compare value[ p ] and other[ p ] when set to undefined

    if (value[p] === other[p]) continue;
    // if they have the same strict value or identity then they are equal

    if (typeof value[p] !== "object") return false;
    // Numbers, Strings, Functions, Booleans must be strictly equal

    if (!isEqual(value[p], other[p])) return false;
    // Objects and Arrays must be tested recursively
  }

  for (const p in other) if (other.hasOwnProperty(p) && !value.hasOwnProperty(p)) return false;
  // allows value[ p ] to be set to undefined

  return true;
};

/**
 * Make the sales rate rate more friendly
 */
export const formattedSalesRate = ({ sales_rate }) => {
  let period;
  if (sales_rate > 1) {
    period = "day";
  } else if (sales_rate * 7 > 1) {
    sales_rate *= 7;
    period = "week";
  } else if (sales_rate * (365 / 12) > 1) {
    sales_rate *= 30.42;
    period = "month";
  } else if (sales_rate * (365 / 4) > 1) {
    sales_rate *= 91.25;
    period = "quarter";
  } else {
    sales_rate *= 365;
    period = "year";
  }
  if (String(sales_rate) === "0") {
    return "0 / year";
  }
  const rounded_rate = Math.round(sales_rate || 0);
  return `${rounded_rate} / ${period}`;
};

export const softAssertNumber = (value?: number | string): number => {
  if (value === undefined || value === null || value === "") {
    return undefined;
  } else {
    return assertNumber(value);
  }
};

export const assertNumber = (value: number | string): number => {
  if (typeof value === "string") {
    // If it has any letters then filter them out
    // but do allow decimals and negative signs
    return Number(value.replace(/[^\d.-]/g, ""));
  } else {
    return Number(value || 0);
  }
};

export const makeLineItemsMap = function <T extends { id: number }>(lineItems: T[]): { number: T } {
  if (!lineItems) {
    return {} as { number: T };
  } else if (!Array.isArray(lineItems)) {
    Rollbar.error("lineItems is not an array", lineItems);
    return {} as { number: T };
  }

  return lineItems.reduce<{ number: T }>((acc, lineItem, index) => {
    acc[lineItem.id] = { ...lineItem, sortIndex: index };
    return acc;
  }, {});
};

export const assertString = (value: number | string): string =>
  value !== undefined && value !== null ? String(value) : "";

export const getIdFromPath = (): number => {
  const path = window.location.pathname;
  const pathParts = path.split("/");
  return Number(pathParts[pathParts.length - 1]);
};

const secondToMillisecond = 1000;

export const urlParamToDate = (param: string[] | string | null): Date | undefined => {
  if (param) {
    return new Date(parseInt(param as string, 10) * secondToMillisecond);
  }
  return undefined;
};

// Takes a setter that expects a NumberRangeFilter (numbers) and returns a setter that expects a DecimalRangeFilter (strings)
export const softAssertNumberRange = function (
  setter: (args: NumberRangeFilter) => void,
): (args: DecimalRangeFilter) => void {
  return function ({ less_than, more_than }: DecimalRangeFilter): void {
    setter({
      less_than: softAssertNumber(less_than),
      more_than: softAssertNumber(more_than),
    });
  };
};

export const extractMessageFromError = function (err: unknown): string {
  let message: string;
  if (err instanceof Error) {
    ({ message } = err);
  } else if (typeof err === "object" && err !== null && "errors" in err) {
    message = (err as ErrorResponse).errors.join("\n");
  } else if (err instanceof Response) {
    message = err.statusText || err.status.toString();
  } else if (typeof err === "string") {
    message = err;
  } else {
    message = "An unknown error occurred.";
  }
  return message;
};

export const omit = <T extends object, K extends keyof T>(obj: T, keys: K[]): Omit<T, K> => {
  const newObj = { ...obj };
  keys.forEach((key) => delete newObj[key]);
  return newObj;
};
