import React from "react";

export const humanDateFormatWithColor = (date: Date, textColor: string): React.ReactElement => {
  const year = date.getFullYear();
  const month = date.toLocaleString("default", { month: "short" });
  const day = `0${date.getDate()}`.slice(-2);
  const humanDate = `${month}. ${day}, ${year}`;
  return <span style={{ color: textColor }}>{humanDate}</span>;
};

export const styledDivElement = (backgroundColor: string, text: string): React.ReactElement => {
  return (
    <div>
      <span style={{ padding: "2px 8px", background: backgroundColor, borderRadius: "10px" }}>{text}</span>
    </div>
  );
};
