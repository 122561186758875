import React from "react";
import { Card, EmptyState, InlineStack, Text } from "@shopify/polaris";

function EmptyStateComponent({
  title,
  header,
  infoMessage,
  imageSrc,
  seeMoreUrl,
}: {
  readonly title: string;
  readonly header: string;
  readonly infoMessage: string;
  readonly imageSrc: string;
  readonly seeMoreUrl?: string;
}): React.JSX.Element {
  return (
    <Card
      secondaryFooterActions={
        seeMoreUrl
          ? [
              {
                content: "Explore trend",
                plain: true,
                url: seeMoreUrl,
              },
            ]
          : undefined
      }
      title={title}
    >
      <Text as="h2" variant="headingSm">
        {title}
      </Text>
      <InlineStack align="center">
        <img height={187} src={imageSrc} />
      </InlineStack>
      <EmptyState heading={header} image="">
        {infoMessage}
      </EmptyState>
    </Card>
  );
}

export default EmptyStateComponent;
