import React, { useState, useCallback } from "react";
import { TextField, LegacyCard, Page, Layout } from "@shopify/polaris";
import { ContextualSaveBar, Loading } from "@shopify/app-bridge-react";
import { createSupplier } from "../../api_utils/requests";
import { ErrorBanner } from "../common/ErrorBanner";
import { Footer } from "../common/Footer";
import Toast from "../common/Toast";
import { useNavigate } from "react-router-dom";
import { extractMessageFromError } from "../../helper_functions/utils";

function CreateSupplier(): React.ReactElement {
  const navigate = useNavigate();

  const [newSupplier, setNewSupplier] = useState({});
  const [loading, setLoading] = useState(false);
  const [isDirty, setIsDirty] = useState(false);

  const [errorMessage, setErrorMessage] = useState("");

  const [toastMessage, setToastMessage] = useState<string>(null);

  const handleChange = useCallback(
    (value, id) => {
      setNewSupplier({ ...newSupplier, [id]: value });
      setIsDirty(true);
    },
    [newSupplier],
  );

  const handleSaveSupplier = () => {
    setLoading(true);
    createSupplier({ supplier: newSupplier })
      .then((res) => {
        setToastMessage("Supplier created");
        setIsDirty(false);
        navigate(`/suppliers/${res.id}`);
      })
      .catch((err: unknown) => {
        Rollbar.error(err, newSupplier);
        const message = extractMessageFromError(err);
        setErrorMessage(message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleDiscardAction = (): void => {
    setNewSupplier({ name: "" });
    setIsDirty(false);
  };

  const suppliersHelpPageUrl = "https://horse-inventory.notion.site/Suppliers-d0942bec2f8c457797a42fb805b4a8cd";
  return (
    <>
      {loading ? <Loading /> : null}
      <ContextualSaveBar
        discardAction={{ onAction: handleDiscardAction }}
        saveAction={{ onAction: handleSaveSupplier }}
        visible={isDirty}
      />

      <Page
        backAction={{
          content: "Suppliers",
          url: "/suppliers",
        }}
        narrowWidth
        title="Create supplier"
      >
        <Layout>
          <Layout.Section>
            <ErrorBanner errorMessage={errorMessage} setErrorMessage={setErrorMessage} />
          </Layout.Section>
          <Layout.Section>
            <LegacyCard>
              <LegacyCard.Section>
                <TextField
                  autoComplete="on"
                  helpText="Choose a name for your supplier."
                  id="name"
                  label="Supplier name"
                  onChange={handleChange}
                  placeholder=""
                  value={newSupplier.name}
                />
              </LegacyCard.Section>
            </LegacyCard>
          </Layout.Section>
        </Layout>
        <Footer pageTitle="suppliers" url={suppliersHelpPageUrl} />
        <Toast setToastMessage={setToastMessage} toastMessage={toastMessage} />
      </Page>
    </>
  );
}

export default CreateSupplier;
