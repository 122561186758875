import React, { useState, useCallback } from "react";
import {
  Modal,
  ResourceList,
  ResourceItem,
  InlineStack,
  Pagination,
  FormLayout,
  EmptySearchResult,
} from "@shopify/polaris";
import HorseVariant from "./HorseVariant/HorseVariant";
import { HorseVariantSearchFieldAndMoreFilters } from "../horse_variants/index/support/HorseVariantSearchFieldAndMoreFilters";
import type { DecimalRangeFilter, HorseVariantsQueryParams, IHorseVariant, QueryParams } from "../../api_utils/types";
import { useHorseVariants } from "../../api_utils/requests";

const emptyState = <EmptySearchResult title="No variants found" withIllustration />;

interface HorseVariants {
  number: IHorseVariant;
}

export function SelectVariantDialog({
  active,
  initialHorseLocationId,
  initialQuantity,
  initialSupplierId,
  initialVendor,
  selected,
  setActive,
  updateSelection,
  handleDiscard,
  customQueryParam,
}: {
  readonly active: boolean;
  readonly initialHorseLocationId?: number;
  readonly initialQuantity?: DecimalRangeFilter;
  readonly initialSupplierId?: number;
  readonly initialVendor?: string;
  readonly setActive: (active: boolean) => void;
  readonly selected?: IHorseVariant;
  readonly updateSelection: (horseVariant: IHorseVariant) => void;
  readonly handleDiscard: () => void;
  readonly customQueryParam?: QueryParams;
}): React.JSX.Element {
  const [filters, setFilters] = useState<HorseVariantsQueryParams>({});
  const [pageNum, setPageNum] = useState(1);
  const handleSetFilters = useCallback((newFilters: HorseVariantsQueryParams) => {
    setFilters(newFilters);
    setPageNum(1);
  }, []);

  const [horseLocationId, setHorseLocationId] = useState<number | undefined>(initialHorseLocationId);
  const [selectedHorseVariant, setSelectedHorseVariant] = useState<IHorseVariant | undefined>(selected);

  const {
    data: { rows, hasNext, hasPrev },
  } = useHorseVariants({ ...filters, ...(customQueryParam || {}), page: pageNum });

  const horseVariants = rows.reduce<HorseVariants>((map, variant: IHorseVariant) => {
    map[variant.id] = variant;
    return map;
  }, {});

  const setPageNumAndScroll = useCallback((newPageNum: number) => {
    setPageNum(newPageNum);
    document.getElementById("variant-header")?.scrollIntoView();
  }, []);
  const nextPage = useCallback(() => {
    if (hasNext) {
      setPageNumAndScroll(pageNum + 1);
    }
  }, [hasNext, pageNum, setPageNumAndScroll]);
  const prevPage = useCallback(() => {
    if (hasPrev) {
      setPageNumAndScroll(pageNum - 1);
    }
  }, [hasPrev, pageNum, setPageNumAndScroll]);

  const handleClose = useCallback(() => {
    setActive(false);
    handleDiscard();
  }, []);

  const handleSave = useCallback(() => {
    updateSelection(selectedHorseVariant);
    setActive(false);
  }, [selectedHorseVariant]);

  return (
    <Modal
      limitHeight
      onClose={handleClose}
      open={active}
      primaryAction={{
        content: "Select",
        onAction: handleSave,
        disabled: !selectedHorseVariant,
      }}
      sectioned
      title="Select variant"
    >
      <Modal.Section>
        <FormLayout>
          <FormLayout.Group>
            <HorseVariantSearchFieldAndMoreFilters
              disableTabs
              horseLocationId={horseLocationId}
              initialQuantity={initialQuantity}
              initialSupplierId={initialSupplierId}
              initialVendor={initialVendor}
              setFilters={handleSetFilters}
              setHorseLocationId={setHorseLocationId}
            />
          </FormLayout.Group>
        </FormLayout>
        <ResourceList
          emptyState={emptyState}
          items={Object.values(horseVariants)}
          renderItem={(horseVariant: IHorseVariant): React.ReactElement => (
            <div
              style={
                horseVariant.id === selectedHorseVariant?.id
                  ? { backgroundColor: "rgb(241 248 255)", borderRadius: "8px" }
                  : {}
              }
            >
              <ResourceItem
                id={horseVariant.id.toString()}
                onClick={() => {
                  setSelectedHorseVariant(horseVariant);
                }}
              >
                <HorseVariant disableClick showAvailable variant={horseVariant} />
              </ResourceItem>
            </div>
          )}
          resourceName={{
            singular: "variant",
            plural: "variants",
          }}
        />
      </Modal.Section>
      <Modal.Section>
        <InlineStack align="center">
          <Pagination
            hasNext={hasNext}
            hasPrevious={hasPrev}
            nextKeys={[39]}
            onNext={nextPage}
            onPrevious={prevPage}
            previousKeys={[37]}
          />
        </InlineStack>
      </Modal.Section>
    </Modal>
  );
}
