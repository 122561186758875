import React from 'react';
import {
  BlockStack,
  Card,
  Layout,
  SkeletonBodyText,
  SkeletonDisplayText,
  SkeletonPage,
} from '@shopify/polaris';

export const HomeIndexPageSkeleton = (): React.JSX.Element => (
  <SkeletonPage narrowWidth>
    <Layout>
      <Layout.Section variant="fullWidth">
        <Card>
          <BlockStack gap="400">
            <SkeletonDisplayText size="small" />
            <SkeletonBodyText lines={10} />
          </BlockStack>
        </Card>
      </Layout.Section>
      <Layout.Section variant="fullWidth">
        <Card>
          <BlockStack gap="400">
            <SkeletonDisplayText size="small" />
            <SkeletonBodyText lines={10} />
          </BlockStack>
        </Card>
      </Layout.Section>
      <Layout.Section variant="fullWidth">
        <Card>
          <BlockStack gap="400">
            <SkeletonDisplayText size="small" />
            <SkeletonBodyText lines={10} />
          </BlockStack>
        </Card>
      </Layout.Section>
    </Layout>
  </SkeletonPage>
);
