import React from 'react';
import {
  LegacyCard,
  ResourceList,
  ResourceItem,
  Avatar,
  Text,
  LegacyStack,
  Link,
} from '@shopify/polaris';
import placeholderImg from 'images/placeholder.png';

export function LinkedVariants({ shopifyVariants }: {
    readonly shopifyVariants: {
      id: number,
      title: string,
      image_src: string,
      shop_domain: string,
      variant_url: string,
      price: number,
    }[],
  }): JSX.Element {
  const renderLinkedVariantsItem = (hv) => {
    let src = placeholderImg;
    if (hv.image_src) {
      src = `${hv.image_src}&&height=40`;
    }
    return (
      <ResourceItem
        accessibilityLabel={`View details for ${hv.title}`}
        external
        id={hv.id.toString()}
        key={`${hv.id}-linked-variant`}
        media={<Avatar customer={false} size='lg' source={src} />}
        name={hv.title}
        url={hv.variant_url}
        verticalAlignment="center"
      >
        <LegacyStack distribution="equalSpacing">
          <LegacyStack.Item>
            <Text as="h4">
              <Link
                monochrome
                removeUnderline
                target="_blank"
              >
                {hv.title}
              </Link>
            </Text>
            <Text as="span" tone="subdued">
              {hv.shop_domain}
            </Text>
          </LegacyStack.Item>
        </LegacyStack>
      </ResourceItem>
    );
  };

  return (
    <LegacyCard title="Linked variants">
      <LegacyCard.Section>
        <h4>
          To change the linking of variants, just change the SKUs or barcodes of those variants in your Shopify shop.
        </h4>
        {shopifyVariants.length ? (
          <ResourceList
            items={shopifyVariants}
            renderItem={renderLinkedVariantsItem}
          />
        ) : (
          <LegacyCard.Section>
            <Text as="h4" tone="subdued">
              No linked variants available
            </Text>
          </LegacyCard.Section>
        )}
      </LegacyCard.Section>
    </LegacyCard>
  );
}
