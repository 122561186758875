import React from "react";
import { Button, Thumbnail, DropZone, EmptyState, Text, Box } from "@shopify/polaris";

function UploadBrandLogo({
  brandFile,
  setBrandFile,
  brandLogo,
  setIsDirty,
}: {
  readonly brandFile: File | null;
  readonly setBrandFile: (file: File) => void;
  readonly brandLogo: string | null;
  readonly setIsDirty: (dirty: boolean) => void;
}): React.ReactElement {
  const handleDropZoneDrop = (files: File[]): void => {
    setBrandFile(files[0]);
    setIsDirty(true);
  };

  const url: string = brandFile ? URL.createObjectURL(brandFile) : brandLogo ?? "";

  const fileUpload = (
    <Box padding="400">
      <Thumbnail alt="" size="small" source={url} />
      <Button>Change Image</Button>
    </Box>
  );

  return (
    <DropZone
      accept="image/jpeg,image/png"
      allowMultiple={false}
      label="Drag and drop an image or click to upload"
      onDrop={handleDropZoneDrop}
    >
      {url != "" ? (
        fileUpload
      ) : (
        <Text alignment="center" as="p" variant="bodySm">
          <EmptyState
            action={{
              content: "Upload Image",
            }}
          />
          <p>or drag and drop an image here</p>
        </Text>
      )}
    </DropZone>
  );
}

export default UploadBrandLogo;
