import React, { useCallback, useState } from "react";
import {
  LegacyCard,
  Page,
  Badge,
  TextField,
  Label,
  FormLayout,
  IndexTable,
  PageActions,
  Layout,
} from "@shopify/polaris";
import { ContextualSaveBar, Loading } from "@shopify/app-bridge-react";
import { ExportMinor, FollowUpEmailMajor } from "@shopify/polaris-icons";
import EmailPdfDialog from "./support/EmailPdfDialog";
import DeleteConfirmationDialog from "../common/DeleteConfirmationDialog";
import Summary from "./support/Summary";
import CustomDatePicker from "../common/CustomDatePicker/CustomDatePicker";
import { deletePurchaseOrder, updatePurchaseOrder, emailPdf, downloadFile, useUser } from "../../api_utils/requests";
import { getTotal } from "./support/utils";
import HorseVariant from "../common/HorseVariant/HorseVariant";
import { commaDelimiter, extractMessageFromError, formatMoney, makeLineItemsMap } from "../../helper_functions/utils";
import { ErrorBanner } from "../common/ErrorBanner";
import { Footer } from "../common/Footer";
import type { IPurchaseOrder, IPurchaseOrderLineItem } from "../../api_utils/types";
import { PURCHASE_ORDER_HELP_PAGE_URL } from "../../constants";
import Toast from "../common/Toast";

const Index = ({
  purchaseOrder: initialPurchaseOrder,
  lineItems: initialLineItems,
}: {
  purchaseOrder: IPurchaseOrder;
  lineItems: IPurchaseOrderLineItem[];
}): React.JSX.Element => {
  const [deletionDialogueActive, setDeletionDialogueActive] = useState(false);

  const [isDirty, setIsDirty] = useState(false);
  const [loading, setLoading] = useState(false);

  const [payload, setPayload] = useState({});
  const initialPurchaseOrderWithArrivalDate = {
    ...initialPurchaseOrder,
    arrival_date: initialPurchaseOrder.arrival_date ? new Date(initialPurchaseOrder.arrival_date) : new Date(),
  };
  const [originalPurchaseOrder, setOriginalPurchaseOrder] = useState<IPurchaseOrder>(
    initialPurchaseOrderWithArrivalDate,
  );
  const [purchaseOrder, setPurchaseOrder] = useState<IPurchaseOrder>(initialPurchaseOrderWithArrivalDate);
  const [lineItemsMap] = useState<{ number: IPurchaseOrderLineItem }>(makeLineItemsMap(initialLineItems));
  const [emailPdfDialogActive, setEmailPdfDialogActive] = useState(false);

  const resetInitial = (newPurchaseOrder: IPurchaseOrder): void => {
    setPayload({});
    setPurchaseOrder(newPurchaseOrder);
    setIsDirty(false);
  };

  const [errorMessage, setErrorMessage] = useState("");

  const [toastMessage, setToastMessage] = useState<string>(null);

  const handleArrivalDateChange = (newArrivalDate: Date): void => {
    setPurchaseOrder({ ...purchaseOrder, arrival_date: newArrivalDate });
    setPayload({ ...payload, arrival_date: newArrivalDate });
    setIsDirty(true);
  };

  const handleLabelChange = (label: string): void => {
    setPurchaseOrder({ ...purchaseOrder, label });
    setPayload({ ...payload, label });
    setIsDirty(true);
  };

  const handleSaveOrdered = (): void => {
    const mPayload = {
      purchase_order: {
        ...payload,
        purchase_order_line_items_attributes: Object.values(lineItemsMap),
      },
    };
    updatePurchaseOrder(purchaseOrder.id, mPayload)
      .then((): void => {
        resetInitial(purchaseOrder);
        setOriginalPurchaseOrder(purchaseOrder);
        setToastMessage("Save successful");
      })
      .catch((err: unknown): void => {
        Rollbar.error(err, mPayload);
        const message = extractMessageFromError(err);
        setErrorMessage(message);
      })
      .finally((): void => {
        setLoading(false);
      });
  };

  const handleNoteChange = useCallback(
    (note: string) => {
      setPurchaseOrder({ ...purchaseOrder, note });
      setPayload({ ...payload, note });
      setIsDirty(true);
    },
    [purchaseOrder, payload],
  );

  const handleEmailPdfModalClose = (): void => {
    setEmailPdfDialogActive(false);
  };

  const total = getTotal(lineItemsMap);
  const lineItemsArray = Object.values(lineItemsMap || {});

  const {
    data: {
      user: { currency: userCurrency },
    },
  } = useUser();

  const currency = purchaseOrder.currency || userCurrency;

  const pageMarkup = purchaseOrder && (
    <>
      {loading ? <Loading /> : null}
      <ContextualSaveBar
        discardAction={{
          onAction: () => {
            resetInitial(originalPurchaseOrder);
          },
        }}
        saveAction={{
          onAction: handleSaveOrdered,
        }}
        visible={isDirty}
      />
      <Page
        actionGroups={[
          {
            title: "More actions",
            actions: [
              {
                content: "Export PDF",
                onAction: (): void => {
                  const purchaseOrderName = purchaseOrder.label || purchaseOrder.id;
                  const filename = `Horse Purchase Order ${purchaseOrderName}.pdf`;
                  setLoading(true);
                  downloadFile(`/purchase_orders/${purchaseOrder.id}.pdf${window.location.search}`, filename)
                    .then(() => {
                      setLoading(false);
                    })
                    .catch((err: unknown): void => {
                      Rollbar.error(err, filename);
                      setLoading(false);
                      setErrorMessage("Failed to generate PDF");
                    });
                },
                icon: ExportMinor,
              },
              {
                content: "Email PDF",
                onAction: (): void => {
                  setEmailPdfDialogActive(true);
                },
                icon: FollowUpEmailMajor,
              },
              {
                content: "Export CSV",
                onAction: (): void => {
                  const purchaseOrderName = purchaseOrder.label || purchaseOrder.id;
                  const filename = `Horse Purchase Order ${purchaseOrderName}.csv`;
                  downloadFile(`/purchase_orders/${purchaseOrder.id}.csv${window.location.search}`, filename);
                },
                icon: ExportMinor,
              },
            ],
          },
        ]}
        backAction={{
          content: "Purchase orders",
          url: "/purchase_orders",
        }}
        title="Closed purchase order"
        titleMetadata={<Badge tone="success">Received</Badge>}
      >
        <EmailPdfDialog
          active={emailPdfDialogActive}
          onClose={handleEmailPdfModalClose}
          onSend={emailPdf}
          purchaseOrder={purchaseOrder}
          setErrorMessage={setErrorMessage}
          setToastMessage={setToastMessage}
        />
        <Layout>
          {errorMessage ? (
            <Layout.Section>
              <ErrorBanner errorMessage={errorMessage} setErrorMessage={setErrorMessage} />
            </Layout.Section>
          ) : null}
          <Layout.Section>
            <LegacyCard sectioned>
              {purchaseOrder.supplier ? (
                <LegacyCard.Section title="Supplier">
                  <Label id="purchaseOrderSupplier">{purchaseOrder.supplier.name}</Label>
                </LegacyCard.Section>
              ) : (
                <LegacyCard.Section title="Vendor">
                  <Label id="purchaseOrderVendor">{purchaseOrder.vendor}</Label>
                </LegacyCard.Section>
              )}
              <LegacyCard.Section title="Destination">
                <Label id="purchaseOrderDestinationLabel">{purchaseOrder.destination}</Label>
              </LegacyCard.Section>
              <LegacyCard.Section>
                <FormLayout>
                  <FormLayout.Group condensed>
                    <TextField
                      autoComplete="on"
                      label="Label"
                      onChange={handleLabelChange}
                      placeholder={purchaseOrder.id.toString()}
                      value={purchaseOrder.label || ""}
                    />
                    <CustomDatePicker
                      date={purchaseOrder.arrival_date}
                      label="Arrival date"
                      placeholder="2021-07-01"
                      setDate={handleArrivalDateChange}
                    />
                  </FormLayout.Group>
                </FormLayout>
              </LegacyCard.Section>
            </LegacyCard>
          </Layout.Section>
          <Layout.Section>
            <LegacyCard>
              <IndexTable
                headings={[
                  { title: "Variant" },
                  // { title: 'Customs form line number' },
                  // { title: 'Description of goods' },
                  // { title: 'Classification number' },
                  // { title: 'Duty rate' },
                  { title: "Quantity ordered" },
                  { title: "Quantity received" },
                  { title: "Cost" },
                  // { title: 'Duties' },
                  { title: "Line total" },
                ]}
                itemCount={lineItemsArray.length}
                selectable={false}
              >
                <IndexTable.Row id="closedPOTotalsRow" key={0} position={0} rowType="subheader">
                  <IndexTable.Cell>Totals</IndexTable.Cell>
                  {/* <IndexTable.Cell/>
                    <IndexTable.Cell/>
                    <IndexTable.Cell/>
                    <IndexTable.Cell/> */}
                  <IndexTable.Cell>{commaDelimiter(total.quantity)}</IndexTable.Cell>
                  <IndexTable.Cell>{commaDelimiter(total.received)}</IndexTable.Cell>
                  <IndexTable.Cell>{formatMoney(total.cost, currency)}</IndexTable.Cell>
                  {/* <IndexTable.Cell>
                      { formatMoney(total.duties, currency) }
                    </IndexTable.Cell> */}
                  <IndexTable.Cell>{formatMoney(total.totalCost, currency)}</IndexTable.Cell>
                </IndexTable.Row>
                {lineItemsArray.map((variant, index) => (
                  <IndexTable.Row id={variant.id.toString()} key={variant.id} position={index + 1}>
                    <IndexTable.Cell>
                      <HorseVariant key={variant.id} variant={variant} />
                    </IndexTable.Cell>
                    {/* <IndexTable.Cell>
                        <div className='w120'>
                          <TextField
                            onChange={handleLineItemChange('entry_summary_line_number', variant.id)}
                            id={variant.id}
                            key={variant.id}
                            type='integer'
                            name='entry_summary_line_number'
                            value={assertString(variant?.entry_summary_line_number)}
                          />
                        </div>
                      </IndexTable.Cell>
                      <IndexTable.Cell>
                        <div className='w120'>
                          <TextField
                            onChange={handleLineItemChange('description_of_goods', variant.id)}
                            id={variant.id}
                            key={variant.id}
                            name='description_of_goods'
                            value={variant.description_of_goods} />
                        </div>
                      </IndexTable.Cell>
                      <IndexTable.Cell>
                        <TextField
                          onChange={handleLineItemChange('classification_number', variant.id)}
                          id={variant.id}
                          key={variant.id}
                          name='classification_number'
                          value={assertString(variant?.classification_number)} />
                      </IndexTable.Cell>
                      <IndexTable.Cell>
                        <div className='w120'>
                          <TextField
                            onChange={handleLineItemChange('duty_rate', variant.id)}
                            id={variant.id}
                            key={variant.id}
                            name='duty_rate'
                            suffix='%'
                            type='number'
                            value={assertString(variant.duty_rate)} />
                        </div>
                      </IndexTable.Cell> */}
                    <IndexTable.Cell>{variant.quantity}</IndexTable.Cell>
                    <IndexTable.Cell>{variant.received}</IndexTable.Cell>
                    <IndexTable.Cell>{formatMoney(variant.cost, currency)}</IndexTable.Cell>
                    {/* <IndexTable.Cell>
                        {formatMoney(getDuties(variant), currency)}
                      </IndexTable.Cell> */}
                    <IndexTable.Cell>
                      {formatMoney(Number(variant.quantity || 0) * Number(variant.cost || 0), currency)}
                    </IndexTable.Cell>
                  </IndexTable.Row>
                ))}
              </IndexTable>
            </LegacyCard>
          </Layout.Section>
          <Layout.Section variant="oneHalf">
            <LegacyCard title="Note to supplier">
              <LegacyCard.Section>
                <TextField
                  autoComplete="off"
                  // eslint-disable-next-line @typescript-eslint/no-magic-numbers
                  error={purchaseOrder.note?.length >= 1000 ? "Note is too long" : ""}
                  label="Note"
                  maxLength={1000}
                  multiline={2}
                  onChange={handleNoteChange}
                  value={purchaseOrder.note || ""}
                />
              </LegacyCard.Section>
            </LegacyCard>
          </Layout.Section>
          <Layout.Section variant="oneHalf">
            <LegacyCard>
              <LegacyCard.Section>
                <Summary
                  currency={currency}
                  lineTotal={Number(total.totalCost)}
                  purchaseOrder={purchaseOrder}
                  setErrorMessage={setErrorMessage}
                />
              </LegacyCard.Section>
            </LegacyCard>
          </Layout.Section>
        </Layout>
        <PageActions
          secondaryActions={[
            {
              content: "Delete",
              destructive: true,
              onAction: () => {
                setDeletionDialogueActive(true);
              },
            },
          ]}
        />
        <DeleteConfirmationDialog
          active={deletionDialogueActive}
          content="Are you sure you want to delete this purchase order? This action cannot be reversed."
          deleteItem={deletePurchaseOrder}
          gotoPath="/purchase_orders"
          itemId={purchaseOrder.id}
          setActive={setDeletionDialogueActive}
          title="Delete purchase"
        />
        <Toast setToastMessage={setToastMessage} toastMessage={toastMessage} />
        <Footer pageTitle="purchase orders" url={PURCHASE_ORDER_HELP_PAGE_URL} />
      </Page>
    </>
  );

  return pageMarkup;
};

export default Index;
